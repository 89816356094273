@charset "utf-8";

/*================================================================================
about 一覧ページ
================================================================================ */

article {
    &#error {
        section {
            &.errorLayout {
                display: block;
                .topKeyword {
                    h1 {
                        img {
                            @include mq(md) {
                                width: 45.5729%;
                            }
                        }
                    }
                }
                .errorColumn {
                    background-color: #fff;
                    width: 100%;
                    .errorBlock {
                        display: block;
                        width: 100%;
                        max-width: 1020px;
                        padding: 75px 20px 200px;
                        margin: 0 auto;
                        text-align: center;
                        @include mq(md) {
                            padding: 8.6% 15px 100px;
                        }
                        h2 {
                            font-size: 3rem;
                            font-weight: bold;
                            padding-bottom: 40px;
                            @include mq(md) {
                                font-size: 2.4rem;
                            }
                        }
                        p {
                            font-size: 1.8rem;
                            line-height: 1.7;
                            padding-bottom: 40px;
                            @include mq(md) {
                                font-size: 1.6rem;
                                text-align: left;
                            }
                        }
                        .backBox {
                            display: block;
                            max-width: 480px;
                            margin: 0 auto;
                            border: 2px solid #fff;
                            transition: .5s;
                            a {
                                padding: 20px 0;
                                display: block;
                                position: relative;

                                img {
                                    margin: 0 auto;
                                    max-width: 128px;
                                    @include mq(md) {
                                        max-width: none;
                                        width: 47.0588%;
                                    }
                                }
                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: -12em;
                                    right: 0;
                                    bottom: 0;
                                    top: 0;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 11.5px 11px 11.5px 0;
                                    border-color: transparent #d90012 transparent transparent;
                                    margin: auto;
                                    @include mq(md) {
                                        left: -65%;
                                        border-width: 7.5px 7px 7.5px 0;
                                    }
                                }
                            }
                            &.uaHover {
                                &:hover {
                                    border: 2px solid #d90011;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}