@charset "utf-8";

/* ================================================================================
ヘッダー
================================================================================ */

header {
    &#header {
        display: block;
        width: 100%;
        background-color: #000;
        color: #fff;
        padding: 0 25px;
        z-index: 999;
        position: fixed;
        top: 0;

        .headerColumn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 90px;
            @include mq(md) {
                height: 55px;
            }
            a {
                display: block;
            }
            .logo {
                position: absolute;
                left: 25px;
                top: 0;
                padding: 24px 20px;
                height: 90px;
                max-width: 154px;
                background-color: #fff;
                z-index: 5;

                @include mq(md) {
                    width: 120px;
                    padding: 11px 10px;
                    height: 55px;
                    left: 12px;
                }

                img {
                    width: 154px;
                    @include mq(md) {
                        width: 95px;
                        margin: 0 auto;
                    }
                }
            }
            .telBox {
                display: flex;
                align-items: flex-start;
                margin-right: 160px;
                @include mq(md) {
                    display: none;
                }
                .timeBox {
                    display: block;
                    padding-left: 10px;
                    .txt {
                        font-size: 1.2rem;
                        letter-spacing: 0.6px;
                        width: 105px;
                        background-color: #fff;
                        line-height: 1;
                        padding: 2px 10px;
                        color: #000;
                        text-align: center;
                        margin: 0 auto;
                        margin-bottom: 5px;
                    }
                    p.time {
                        font-size: 1.2rem;
                        letter-spacing: 0.6px;
                    }
                }
            }
            .spNavBtn {
                display: flex;
                color: #fff;
                position: absolute;
                right: 15px;
                width: 137px;
                height: 48px;
                top: 0;
                bottom: 0;
                margin: auto;
                border: 1px solid #fff;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                @include mq(md) {
                    border: 0;
                    width: 60px;
                }

                p {
                    font-family: $Helvetica;
                    font-size: 1.8rem;
                    letter-spacing: 3.6px;
                    color: #ffffff;
                    line-height: 1;
                    padding-right: 16px;
                    font-weight: 500;

                    @include mq(md) {
                        display: none;
                    }
                }
                .menu-trigger {
                    display: inline-block;
                    transition: all 0.4s;
                    box-sizing: border-box;
                    position: relative;
                    width: 25px;
                    height: 19px;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    @include mq(md) {
                        width: 32px;
                        height: 24px;
                    }
                    span {
                        display: inline-block;
                        transition: 0.4s;
                        box-sizing: border-box;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background-color: #fff;
                        // border-radius: 4px;
                        @include mq(md) {
                            height: 2px;
                        }
                        &:nth-of-type(1) {
                            top: 0;
                            transform-origin: top right;
                        }
                        &:nth-of-type(2) {
                            top: calc(50% - 1px);
                            // top: 11px;
                        }
                        &:nth-of-type(3) {
                            bottom: 0;
                            transform-origin: bottom right;
                        }
                    }
                }
                .menu-trigger.active {
                    span {
                        &:nth-of-type(1) {
                            transform: rotate(-45deg);
                        }
                        &:nth-of-type(2) {
                            opacity: 0;
                            transform: translateX(80%);
                        }
                        &:nth-of-type(3) {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        #globalNav {
            display: none;
            position: absolute;
            top: 89px;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: #000;

            @include mq(md) {
                top: 55px;
            }

            ul {
                width: 100%;
                height: 100%;
                max-height: 760px;
                padding: 35px 0 100px;
                overflow-y: auto;
                -ms-overflow-style: none;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                @include mq(spmin) {
                    padding: 24px 0 90px;
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                li {
                    text-align: center;
                    position: relative;
                    // margin-bottom: 3.8em;
                    &.current {
                        &::before {
                            content: "";
                            position: absolute;
                            width: 52.734375%;
                            max-width: 240px;
                            padding-top: 5.46875%;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto;
                            background-image: url("/images/common/menu_bg_current.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            z-index: 0;

                            @include mq(md) {
                                max-width: none;
                                width: 52.734375%;
                            }
                        }
                    }
                    a {
                        position: relative;
                        z-index: 2;
                        display: inline-block;
                        color: #fff;
                        font-size: 2.4rem;
                        font-weight: bold;
                        line-height: 1;
                        min-height: 40px;
                        padding: 0.6em 1em;
                    }
                }
            }
        }
    }
}
