@charset "utf-8";

/* ================================================================================

印刷時の定義

================================================================================ */

＠media print {
	section {
		display: block;
		page-break-before: always;
	}

	pre,
	code {
		page-break-after: always;
	}

	@page {
		margin-top: .4cm;
		margin: .5cm;
	}

	p,
	a,
	code,
	pre {
		word-wrap: break-word;
	}

	pre a[href]:after {
		content: "";
	}

	pre abbr[title]:after {
		content: "";
	}

	pre .ir a:after,
	pre a[href^='javascript:']:after,
	pre a[href^='#']:after {
		content: '';
	}

	.noPrint {
		display: none !important;
	}

	body {
		width: 1280px;
		font-size: 12pt;
		transform: scale(0.8);
		transform-origin: 0 0;
		-webkit-print-color-adjust: exact;

		.continer {
			padding-top: 0;
		}
	}
}