@charset "utf-8";

/*================================================================================
トレーナー
================================================================================ */

#trainer {
    section {
        &.trainerInfoLayout {
            .trainerInfoColumn {
                background-color: #fff;
                width: 100%;
                .trainerNav {
                    padding: 32px 20px 40px;
                    background: #f2f0e8;
                    @include mq(md) {
                        padding: 8.6% 15px;
                    }
                    .trainerNavItems {
                        display: flex;
                        flex-wrap: wrap;
                        max-width: 1020px;
                        margin: 0 auto;
                        > li {
                            flex: 0 1 25%;
                            max-width: 25%;
                            margin-bottom: 8px;
                            padding-right: 8px;
                            @include mq(md) {
                                flex: 0 1 50%;
                                max-width: 50%;
                            }
                            @include mq(spmin) {
                                flex: 0 1 100%;
                                max-width: 100%;
                            }
                        }
                    }
                    .trainerNavThumb {
                        width: 40px;
                        height: 40px;
                        overflow: hidden;
                        margin-right: 16px;
                    }
                    .trainerNavItem {
                        display: flex;
                        align-items: center;
                        font-size: 1.5rem;
                        background: #ffffff;
                        padding: 4px;
                        border-radius: 3px;
                        position: relative;
                        &::before {
                            content: "";
                            display: inline-block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 0 8px 8px;
                            border-color: transparent transparent lighten(#d90011, 50%) transparent;
                            position: absolute;
                            bottom: 4px;
                            right: 4px;
                        }
                        &:hover,
                        &:focus {
                            &::before {
                                border-color: transparent transparent #d90011 transparent;
                            }
                        }
                    }
                }
                .trainerListLayout {
                    display: block;
                    width: 100%;
                    max-width: 1020px;
                    padding: 75px 20px 200px;
                    margin: 0 auto;
                    @include mq(md) {
                        padding: 8.6% 15px 100px;
                    }
                    .postFirstlayout {
                        display: block;
                        width: 100%;
                        padding-bottom: 120px;
                        @include mq(md) {
                            padding: 0 0 60px;
                        }
                        .postTopColumn {
                            display: block;
                            width: 100%;
                            .thumbnail {
                                background-color: #000;
                                margin-bottom: 65px;
                                @include mq(md) {
                                    margin-bottom: 8.8075%;
                                }
                                img {
                                    width: 44.3877%;
                                    margin: 0 auto;
                                    @include mq(md) {
                                        width: 60%;
                                    }
                                }
                            }
                            .postTxtColumn {
                                display: block;
                                max-width: 900px;
                                width: 100%;
                                padding: 0 20px;
                                margin: 0 auto;
                                @include mq(md) {
                                    padding: 0 15px;
                                }
                                .postName {
                                    display: block;
                                    padding-bottom: 30px;
                                    font-family: $YuMin;
                                    @include mq(md) {
                                        padding-bottom: 6.775%;
                                    }
                                    h3 {
                                        font-size: 4.2rem;
                                        font-weight: bold;
                                        line-height: 1.62;
                                        letter-spacing: 1.1px;
                                        display: inline-block;
                                        padding-right: 20px;
                                        @include mq(md) {
                                            font-size: 2.4rem;
                                            padding-right: 12px;
                                        }
                                    }
                                    span {
                                        display: inline-block;
                                        font-size: 1.8rem;
                                        font-weight: bold;
                                        color: #d90011;
                                        letter-spacing: 0.5px;
                                        transform: rotate(-5deg);
                                        position: relative;
                                        top: -7px;
                                        @include mq(md) {
                                            font-size: 1.5rem;
                                        }
                                    }
                                }
                                .postEditor {
                                    font-size: 1.8rem;
                                    line-height: 1.5;
                                    padding-bottom: 40px;
                                    @include mq(md) {
                                        font-size: 1.5rem;
                                    }
                                    strong {
                                        font-weight: bold;
                                    }
                                }
                                .postQualification {
                                    display: block;
                                    width: 100%;
                                    border: solid 1px #999999;
                                    padding-bottom: 20px;
                                    @include mq(md) {
                                        max-width: 604px;
                                        margin: 0 auto;
                                        padding-bottom: 12px;
                                    }
                                    h4 {
                                        font-size: 1.8rem;
                                        font-weight: bold;
                                        line-height: 1;
                                        letter-spacing: 1.8px;
                                        display: inline-block;
                                        position: relative;
                                        padding: 0 1em;
                                        top: -0.8em;
                                        background-color: #fff;
                                        left: 1em;
                                        @include mq(md) {
                                            font-size: 1.6rem;
                                            left: 50%;
                                            transform: translateX(-50%);
                                        }
                                    }
                                    p {
                                        padding-left: 2.2em;
                                        padding-right: 1em;
                                        font-size: 1.6rem;
                                        line-height: 1.75;
                                        letter-spacing: 1.6px;
                                        @include mq(md) {
                                            padding: 0 10px;
                                            font-size: 1.3rem;
                                            letter-spacing: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .postTrainerList {
                        display: block;
                        width: 100%;
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            @include mq(md) {
                                padding-bottom: 6.3559%;
                            }
                            li {
                                width: 50%;
                                margin-bottom: 100px;
                                @include mq(spmax) {
                                    width: 100%;
                                    margin-bottom: 60px;
                                }
                                &:nth-child(odd) {
                                    .postColumn {
                                        padding-right: 10.4166%;
                                        @include mq(spmax) {
                                            padding: 0;
                                        }
                                    }
                                }
                                &:nth-child(even) {
                                    .postColumn {
                                        padding-left: 10.4166%;
                                        @include mq(spmax) {
                                            padding: 0;
                                        }
                                    }
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                &:nth-last-child(2) {
                                    margin-bottom: 0;
                                    @include mq(spmax) {
                                        margin-bottom: 11.0677%;
                                    }
                                }
                                .postColumn {
                                    max-width: 480px;
                                    @include mq(md) {
                                        max-width: none;
                                        margin: 0 auto;
                                        padding: 0 15px;
                                    }
                                    .thumbnail {
                                        max-width: 280px;
                                        margin: 0 auto;
                                        padding-bottom: 40px;
                                        @include mq(md) {
                                            max-width: 560px;
                                            padding-bottom: 24px;
                                            width: 60%;
                                            img {
                                                width: 100%;
                                            }
                                        }
                                    }
                                    .postTxtColumn {
                                        @include mq(md) {
                                            padding: 0 15px;
                                        }
                                    }
                                    .postName {
                                        font-family: $YuMin;
                                        padding-bottom: 25px;
                                        @include mq(md) {
                                            padding-bottom: 8.4745%;
                                        }
                                        h3 {
                                            font-size: 42px;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            letter-spacing: 1.1px;
                                            display: inline-block;
                                            padding-right: 20px;
                                            @include mq(md) {
                                                font-size: 2.4rem;
                                                padding-right: 12px;
                                            }
                                        }
                                        span {
                                            display: inline-block;
                                            font-size: 1.8rem;
                                            font-weight: bold;
                                            color: #d90011;
                                            letter-spacing: 0.5px;
                                            transform: rotate(-5deg);
                                            position: relative;
                                            top: -7px;
                                            padding-top: 5px;
                                            @include mq(md) {
                                                font-size: 1.5rem;
                                            }
                                        }
                                    }
                                    .postEditor {
                                        font-size: 1.8rem;
                                        line-height: 1.5;
                                        padding-bottom: 40px;
                                        @include mq(md) {
                                            font-size: 1.5rem;
                                            padding-bottom: 24px;
                                        }
                                        strong {
                                            font-weight: bold;
                                        }
                                    }
                                    .postQualification {
                                        display: block;
                                        width: 100%;
                                        border: solid 1px #999999;
                                        padding-bottom: 20px;
                                        h4 {
                                            font-size: 1.8rem;
                                            font-weight: bold;
                                            line-height: 1;
                                            letter-spacing: 1.8px;
                                            display: inline-block;
                                            position: relative;
                                            padding: 0 1em;
                                            top: -0.8em;
                                            background-color: #fff;
                                            left: 1em;
                                            @include mq(md) {
                                                font-size: 1.6rem;
                                                left: 50%;
                                                transform: translateX(-50%);
                                            }
                                        }
                                        p {
                                            padding-left: 2.2em;
                                            padding-right: 1em;
                                            font-size: 1.6rem;
                                            line-height: 1.75;
                                            letter-spacing: 1.6px;
                                            @include mq(md) {
                                                padding: 0 10px;
                                                font-size: 1.3rem;
                                                letter-spacing: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
