@charset "utf-8";

/* ================================================================================
トップページ
================================================================================ */

article {
    &#idx {
        section {
            &.keyVisualLayout {
                position: relative;
                z-index: 0;
                margin: 0 auto;
                padding-top: 62.65625%;
                @include mq(md) {
                    padding-top: 142.4479%;
                }
                &::before {
                    content: "";
                    position: absolute;
                    background-image: url("/images/index/key_visual.jpg");
                    z-index: 2;
                    background-size: cover;
                    background-position: center top;
                    left: 0;
                    padding-top: 62.65625%;
                    width: 100%;
                    top: 0;
                    background-repeat: no-repeat;
                    @include mq(md) {
                        background-image: url("/images/index/sp_key_visual.jpg");
                        padding-top: 142.4479%;
                    }
                }
                .hTag {
                    position: absolute;
                    width: 100%;
                    margin: 0 auto;
                    padding-left: 10.9375%;
                    top: 3.4375%;
                    @include mq(md) {
                        padding-left: 0;
                    }
                    h1 {
                        position: relative;
                        z-index: 3;
                        left: 0;
                        img {
                            width: 69.2982%;
                            max-width: 810px;
                            @include mq(md) {
                                width: 77.4739%;
                            }
                        }
                    }
                }
                .bottomColumn {
                    position: absolute;
                    width: 100%;
                    z-index: 3;
                    margin: auto;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    max-width: 1280px;

                    h2 {
                        overflow: hidden;
                        position: relative;
                        img {
                            position: relative;
                            left: 3%;
                            margin: 0 auto;
                            width: 100%;
                            @include mq(md) {
                                left: 0;
                            }
                        }
                    }
                    .btnColumn {
                        position: absolute;
                        bottom: -3%;
                        width: 100%;
                        @include mq(md) {
                            display: none;
                        }
                        .btnLayout {
                            display: flex;
                            width: 100%;
                            .btnBox {
                                width: 50%;
                                position: relative;
                                cursor: pointer;
                                .r {
                                    display: block;
                                    max-width: 487px;
                                    width: 76.09375%;
                                    margin-left: auto;
                                    position: relative;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                                        left: 0;
                                        background-image: linear-gradient( to bottom,
                                        #f21d1e,
                                        #ca0010 51%,
                                        #ba000f 52%,
                                        #bb000f 70%,
                                        #a7000d 99%);
                                        z-index: 2;
                                        transition: 0.5s;
                                        backface-visibility: hidden;
                                    }
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                                        left: 0;
                                        background-image: linear-gradient( to bottom,
                                        #f82a2b,
                                        #e00218 51%,
                                        #d30016 52%,
                                        #d40016 70%,
                                        #c50013 99%);
                                        transition: 0.5s;
                                        z-index: 1;
                                        backface-visibility: hidden;
                                    }
                                }
                                .b {
                                    display: block;
                                    width: 76.09375%;
                                    max-width: 487px;
                                    margin-right: auto;
                                    position: relative;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                                        left: 0;
                                        background-image: linear-gradient( to bottom,
                                        #005197,
                                        #054f8e 51%,
                                        #004784 52%,
                                        #004178 76%,
                                        #003868 99%);
                                        z-index: 2;
                                        transition: 0.5s;
                                        backface-visibility: hidden;
                                    }
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                                        left: 0;
                                        background-image: linear-gradient( to bottom,
                                        #0064cb,
                                        #0561c7 51%,
                                        #0055c2 52%,
                                        #004cb0 76%,
                                        #00418e 99%);
                                        transition: 0.5s;
                                        z-index: 1;
                                        backface-visibility: hidden;
                                    }
                                }
                                a {
                                    width: 100%;
                                    position: relative;
                                    display: block;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 0 0 12px 13px;
                                        border-color: transparent transparent #fff;
                                        right: 5px;
                                        bottom: 5px;
                                        z-index: 10;
                                    }
                                    img {
                                        position: relative;
                                        z-index: 3;
                                        margin: 0 auto;
                                        backface-visibility: hidden;
                                    }
                                }
                                &.uaHover {
                                    &:hover {
                                        .r,
                                        .b {
                                            &::before {
                                                opacity: 0;
                                                transition: 0.5s;
                                                backface-visibility: hidden;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .spBtnLayout {
                    display: none;
                    @include mq(md) {
                        display: flex;
                        width: 100%;
                        position: absolute;
                        padding-top: 17.1875%;
                    }

                    a {
                        width: 50%;
                        position: absolute;
                        top: 0;
                        &:first-child {
                            left: 0;
                        }
                        &:last-child {
                            right: 0;
                        }
                    }
                }
            }
            &.topReadLayout {
                padding-bottom: 100px;
                margin-top: -45px;

                @include mq(md) {
                    padding-bottom: 20.4427%;
                    padding-top: 17.1875%;
                    margin-top: 0;
                }

                h2.top {
                    display: block;
                    padding-bottom: 60px;

                    img {
                        margin: 0 auto;
                        @include mq(md) {
                            width: 91.796875%;
                            max-width: 705px;
                        }
                    }
                }
                .readColumnLayout {
                    display: block;
                    background-image: url("/images/index/readColumnLayout.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    @include mq(md) {
                        background-image: url("/images/index/sp_readColumnLayout.jpg");
                    }
                    .detailColumnBox {
                        display: table;
                        width: 100%;
                        min-height: 400px;
                        margin: 0 auto;
                        position: relative;
                        align-items: center;
                        justify-content: center;
                        max-width: 1280px;
                        margin: 0 auto;
                        @include mq(md) {
                            display: block;
                            min-height: inherit;
                        }
                        .detailColumnRow {
                            display: table-row;
                            @include mq(md) {
                                display: flex;
                                width: 100%;
                                flex-wrap: wrap;
                            }
                        }
                        .txtBoxWrap {
                            width: 50%;
                            padding-left: 7.8125%;
                            padding-right: 3.125%;
                            padding-bottom: 5.8125%;
                            display: table-cell;
                            vertical-align: middle;

                            @include mq(md) {
                                display: block;
                                width: 100%;
                                padding: 0;
                                order: 2;
                            }

                            .txtBox {
                                max-width: 500px;
                                margin-left: auto;
                                @include mq(md) {
                                    max-width: none;
                                    padding: 8.4635% 0 13.671875%;
                                }
                                h3 {
                                    font-family: $YuMin;
                                    font-size: 4.2rem;
                                    font-weight: bold;
                                    line-height: 1.62;
                                    letter-spacing: -0.05em;
                                    padding-bottom: 10px;
                                    @include mq(md) {
                                        text-align: center;
                                        font-size: 2.4rem;
                                    }
                                    span {
                                        color: #d90011;
                                    }
                                }
                                p {
                                    font-size: 1.8rem;
                                    letter-spacing: 1px;
                                    margin-left: 1em;
                                    line-height: 2.33;
                                    @include mq(tab) {
                                        padding: 0 30px;
                                    }
                                    @include mq(md) {
                                        margin: 0;
                                        padding: 0 32px;
                                        font-size: 1.5rem;
                                        line-height: 1.8;
                                    }
                                }
                            }
                        }
                        .detailImg {
                            width: 50%;
                            display: table-cell;
                            position: relative;
                            overflow: hidden;
                            @include mq(md) {
                                display: block;
                                width: 100%;
                                padding-top: 68.4895%;
                                order: 1;
                            }
                            @media all and (-ms-high-contrast: none) {
                                background-image: url("/images/index/img_f.jpg");
                                background-position: center center;
                            }
                            img {
                                position: absolute;
                                display: block;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                @media all and (-ms-high-contrast: none) {
                                    opacity: 0;
                                }
                            }
                        }
                        &.last {
                            flex-direction: row-reverse;
                            .txtBoxWrap {
                                padding: 25px 0 0 3.125%;
                                @include mq(md) {
                                    padding: 0;
                                }
                                .txtBox {
                                    margin: 0 auto 0 0;
                                    max-width: none;
                                    @include mq(md) {
                                        margin: 0;
                                        padding-bottom: 0;
                                    }
                                    h3 {
                                        letter-spacing: -0.12em;
                                        @include mq(md) {
                                            letter-spacing: -0.15em;
                                            font-size: 2.4rem;
                                        }
                                    }
                                    p {
                                        padding-right: 26.6666%;
                                        @include mq(tab) {
                                            padding: 0 30px;
                                        }
                                        @include mq(md) {
                                            padding: 0 32px;
                                        }
                                    }
                                }
                            }
                            .detailImg {
                                @media all and (-ms-high-contrast: none) {
                                    background-image: url("/images/index/img_l.jpg");
                                }
                                img {
                                    @media all and (-ms-high-contrast: none) {
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                    .concept {
                        width: 100%;
                        max-width: 1020px;
                        padding: 100px 20px 0;
                        margin: 0 auto;
                        @include mq(md) {
                            padding: 15.625% 0 0;
                        }

                        h2 {
                            font-family: $YuMin;
                            font-size: 4.2rem;
                            font-weight: bold;
                            line-height: 1;
                            letter-spacing: -0.05em;
                            text-align: center;
                            padding-bottom: 40px;
                            @include mq(md) {
                                font-size: 2.9rem;
                                padding-bottom: 24px;
                                line-height: 1.3;
                            }
                            span {
                                color: #d90011;
                            }
                        }
                        .conceptPhoto {
                            padding-bottom: 50px;
                            @include mq(md) {
                                padding: 0 0 24px;
                            }
                        }
                        p.conceptTxt {
                            font-size: 1.8rem;
                            line-height: 2.33;
                            letter-spacing: 0.5px;
                            @include mq(tab) {
                                padding: 0 30px;
                            }
                            @include mq(md) {
                                padding: 0 16px;
                                font-size: 1.6rem;
                                line-height: 1.8;
                            }
                        }
                        .detailBox {
                            display: block;
                            font-family: $YuMin;
                            font-weight: bold;
                            padding-top: 80px;
                            @include mq(tab) {
                                padding: 16.0156% 30px 0;
                            }
                            @include mq(md) {
                                padding: 16.0156% 15px 0;
                            }
                            img {
                                margin: 0 auto;
                                width: 60px;
                                @include mq(md) {
                                    width: 30px;
                                }
                            }
                            h4 {
                                text-align: center;
                                color: #d90011;
                                font-size: 2.4rem;
                                @include mq(md) {
                                    font-feature-settings: "palt";
                                    font-size: 1.5rem;
                                    line-height: 1.3;
                                    margin-bottom: 0.5em;
                                }

                                span {
                                    font-size: 3.6rem;
                                    @include mq(md) {
                                        display: block;
                                    }

                                    &:last-child {
                                        font-size: 2.4rem;
                                        color: #000;
                                        display: inline-block;
                                        @include mq(md) {
                                            font-size: 1.9rem;
                                        }
                                    }
                                }
                            }
                            p.topTxt {
                                text-align: center;
                                font-size: 2.4rem;
                                padding-bottom: 55px;
                                @include mq(md) {
                                    padding-bottom: 24px;
                                    font-size: 1.9rem;
                                }
                            }
                            p.addTxt {
                                font-size: 1.8rem;
                                line-height: 1.5;
                                letter-spacing: 1px;
                                max-width: 950px;

                                @include mq(md) {
                                    font-size: 1.5rem;
                                    line-height: 1.8;
                                }
                            }
                        }
                    }
                }
            }
            &.fullSupportLayout {
                background-image: url("/images/index/support_bg.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                padding: 0 20px 150px;
                @include mq(tab) {
                    background-size: contain;
                    background-color: #f2f0e8;
                    padding: 0 30px 16.927%;
                }
                @include mq(md) {
                    background-size: contain;
                    background-color: #f2f0e8;
                    padding-bottom: 16.927%;
                }
                h2,
                h3 {
                    img {
                        margin: 0 auto;
                    }
                }
                h3 {
                    margin-top: -4.5em;
                    padding-bottom: 60px;
                    @include mq(md) {
                        padding-bottom: 10.8401%;
                    }
                    img {
                        max-width: 388px;
                        @include mq(md) {
                            max-width: none;
                            width: 84.887%;
                        }
                    }
                }
                .checkListColumn {
                    display: block;
                    background-image: url("/images/index/check_list_repeat.gif");
                    background-repeat: repeat-y;
                    max-width: 980px;
                    margin: 0 auto;
                    padding: 20px;
                    position: relative;
                    @include mq(md) {
                        padding: 16px;
                    }
                    @include mq(spmin) {
                        padding: 8px;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 160px;
                        height: 35px;
                        background-image: url("/images/index/check_list_bottom.gif");
                        bottom: -35px;
                        margin: auto;
                        right: 0;
                        left: 0;
                        @include mq(md) {
                            background-size: contain;
                            width: 130px;
                            background-repeat: no-repeat;
                        }
                    }
                }
                ul.checkList {
                    width: 100%;
                    background-color: #fff;
                    padding: 5.102% 0;
                    overflow: hidden;
                    position: relative;
                    z-index: 1;
                    @include mq(md) {
                        padding: 24px 10px;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-image: url("/images/index/check_list_splash.png");
                        background-repeat: no-repeat;
                        background-position: center bottom -80px;
                        right: -120px;
                        bottom: 0;
                        @include mq(md) {
                            right: -30%;
                        }
                    }
                    li {
                        max-width: 675px;
                        margin: 0 auto 30px;
                        font-size: 2.4rem;
                        font-weight: bold;
                        padding-left: 45px;
                        position: relative;
                        font-feature-settings: "halt";
                        @include mq(md) {
                            font-size: 1.8rem;
                            padding-left: 25px;
                            margin: 0 auto 10px;
                        }
                        @include mq(spmin) {
                            font-size: 1.5rem;
                        }
                        &:first-child {}
                        &:last-child {
                            margin: 0 auto;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            width: 34px;
                            height: 28px;
                            background-image: url("/images/index/check_icon.png");
                            left: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            @include mq(md) {
                                width: 20;
                                height: 13px;
                                background-size: contain;
                                background-repeat: no-repeat;
                                top: 7px;
                                bottom: inherit;
                            }
                        }
                    }
                }
            }
            &.athkitFeatureLayout {
                position: relative;
                overflow: hidden;
                padding: 40px 20px 80px;
                @include mq(tab) {
                    padding: 11.71875% 30px 18.8802%;
                }
                @include mq(md) {
                    padding: 11.71875% 15px 18.8802%;
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 534px;
                    height: 463px;
                    top: 88px;
                    left: -225px;
                    z-index: 0;
                    background-image: url("/images/index/feature_bg.png");
                    @include mq(md) {
                        background-size: contain;
                        width: 58.802%;
                        top: 13.177%;
                        left: -21%;
                        height: auto;
                        padding-top: 60.2864%;
                        background-repeat: no-repeat;
                    }
                }
                h2 {
                    padding-bottom: 40px;
                    @include mq(md) {
                        padding-bottom: 4.607%;
                    }
                    img {
                        margin: 0 auto;
                        max-width: 238px;
                        @include mq(md) {
                            max-width: none;
                            width: 55.0847%;
                        }
                    }
                }
                ul.featureList {
                    position: relative;
                    display: flex;
                    max-width: 980px;
                    margin: 0 auto;
                    justify-content: center;
                    align-items: center;
                    @include mq(md) {
                        flex-wrap: wrap;
                    }
                    li {
                        width: 20%;
                        @include mq(md) {
                            width: 33.3333%;
                            padding-bottom: 15px;
                        }
                        img {
                            max-width: 170px;
                            margin: 0 auto;
                            width: 86.7346%;
                            @include mq(md) {
                                max-width: 206px;
                                width: 84.0816%;
                            }
                        }
                    }
                }
                p.attention {
                    padding-top: 20px;
                    padding-right: 20px;
                    text-align: right;
                    max-width: 1040px;
                    width: 100%;
                    margin: 0 auto;
                    padding-bottom: 100px;
                    @include mq(md) {
                        padding: 5px 0 24.6612%;
                        color: #767676;
                    }
                }
                .requiredToolBox {
                    display: block;
                    background-image: url("/images/index/tools_bg.jpg");
                    max-width: 980px;
                    margin: 0 auto;
                    background-repeat: no-repeat;
                    position: relative;
                    @include mq(md) {
                        background-size: cover;
                    }
                }
                h3 {
                    display: block;
                    position: relative;
                    top: -85px;
                    @include mq(md) {
                        top: -60px;
                    }
                    img {
                        margin: 0 auto;
                        @include mq(tab) {
                            width: 64.7696%;
                            max-width: 475px;
                        }
                        @include mq(md) {
                            width: 80%;
                        }
                    }
                }
                .iconBox {
                    position: relative;
                    display: flex;
                    max-width: 345px;
                    margin: -85px auto 0;
                    padding: 35px 0 50px 0;
                    justify-content: space-between;
                    align-items: flex-end;
                    @include mq(md) {
                        margin: -60px auto 0;
                        max-width: none;
                        padding: 3.9295% 0 4.7425%;
                    }
                    .icon {
                        text-align: center;
                        @include mq(md) {
                            width: 50%;
                            img {
                                margin: 0 auto;
                                width: 48.7804%;
                            }
                        }
                        p {
                            padding-top: 15px;
                            @include mq(md) {
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
                p.toolAttention {
                    padding-top: 15px;
                    font-size: 1.2rem;
                    line-height: 2;
                    letter-spacing: 1.2px;
                    max-width: 980px;
                    margin: 0 auto;
                    text-align: right;
                    @include mq(md) {
                        padding-top: 6.775%;
                        line-height: 1.5;
                        color: #767676;
                    }
                }
            }
            &.courseFeature {
                background-color: #f2f0e8;
                padding-top: 40px;
                overflow: hidden;
                @include mq(md) {
                    padding-top: 7.8125%;
                }
                h2 {
                    @include mq(md) {
                        padding-bottom: 3.90625%;
                    }
                    img {
                        margin: 0 auto;
                        max-width: 235px;
                        @include mq(md) {
                            max-width: none;
                            width: 42.8385%;
                        }
                    }
                }
                .courseFeatureColumn {
                    margin-top: -50px;
                    background-image: url("/images/index/course_feature_bg.png");
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    padding: 100px 20px 100px;
                    @include mq(tab) {
                        margin-top: 0;
                        padding: 0 30px 11.71875%;
                        background-image: none;
                    }
                    @include mq(md) {
                        margin-top: 0;
                        padding: 0 15px 11.71875%;
                        background-image: none;
                    }
                    .courseHeader {
                        max-width: 1060px;
                        margin: 0 auto;
                        img {
                            margin: 0 auto;
                            @include mq(md) {
                                // width: 95.3929%;
                            }
                        }
                    }
                    .courseLayout {
                        display: block;
                        width: 100%;
                        padding: 30px 0 0;
                        margin: 0 auto;
                        position: relative;
                        @include mq(md) {
                            padding: 24px 0 0;
                        }
                        .courseRepeatColumn {
                            max-width: 1060px;
                            width: 100%;
                            margin: 0 auto;
                            background-repeat: repeat-y;
                            position: relative;
                            margin-bottom: 104px;
                            display: flex;
                            flex-wrap: wrap;
                            background-size: 100%;
                            background-image: url("/images/index/course_repeat_bg.png");
                            @include mq(md) {
                                background-image: url("/images/index/sp_course_repeat_bg.gif");
                                padding: 0 15px;
                                background-size:contain;
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                width: 154px;
                                height: 34px;
                                background-image: url("/images/index/course_repeat_bg_bottom.png");
                                left: 0;
                                right: 0;
                                margin: auto;
                                bottom: -34px;
                                @include mq(md) {
                                    width: 77px;
                                    height: 17px;
                                    background-size: contain;
                                    bottom: -17px;
                                }
                            }
                            .courseTit {
                                display: block;
                                width: 100%;
                                position: relative;
                                padding-bottom: 55px;
                                @include mq(md) {
                                    padding-bottom: 32px;
                                    order: 1;
                                }
                                img {
                                    margin: 0 auto;
                                    position: relative;
                                    z-index: 3;
                                }
                            }
                            .courseDetailTxt {
                                width: 50%;
                                position: relative;
                                @include mq(md) {
                                    width: 100%;
                                    order: 3;
                                }
                                dl {
                                    //padding-left: 11.7647%;
                                    //padding-right: 3.9215%;
                                    //max-width: 450px;
                                    padding-left: 15.7647%;
                                    padding-right: 3.9215%;
                                    max-width: 490px;
                                    @include mq(md) {
                                        max-width: none;
                                        padding-left: 0;
                                        padding-right: 0;
                                    }
                                    dt {
                                        font-size: 1.8rem;
                                        font-weight: bold;
                                        @include mq(md) {
                                            margin-bottom: 0.3em;
                                        }
                                    }
                                    dd {
                                        font-size: 1.6rem;
                                        padding-bottom: 35px;
                                        @include mq(md) {
                                            padding-bottom: 24px;
                                            font-size: 1.5rem;
                                        }
                                    }
                                }
                            }
                            .courseImg {
                                width: 50%;
                                position: relative;
                                @include mq(md) {
                                    width: 100%;
                                    padding-bottom: 9.1807%;
                                    order: 2;
                                }
                                img {
                                    position: relative;
                                }
                            }
                            p.annotation {
                                width: 100%;
                                display: block;
                                font-size: 1.2rem;
                                line-height: 2;
                                letter-spacing: 1.2px;
                                padding-left: 8%;
                                padding-right: 70px;
                                position: relative;
                                @include mq(md) {
                                    order: 4;
                                    padding-left: 0;
                                    padding-right: 0;
                                    line-height: 1.5;
                                    color: rgba(#000000, 0.6);
                                }
                            }
                            &.course1 {
                                margin: 0 auto 80px;
                                padding-top: 60px;
                                padding-bottom: 65px;
                                @include mq(md) {
                                    padding-top: 32px;
                                    padding-bottom: 6.775%;
                                    margin-bottom: 14.2276%;
                                }
                                .courseTit {
                                    img {
                                        width: 35.3773%;
                                        @include mq(md) {
                                            width: auto;
                                        }
                                    }
                                }
                                &::before {
                                    content: "";
                                    position: absolute;
                                    background-image: url("/images/index/course1_splash01.png");
                                    width: 30.5882%;
                                    padding-top: 23.6274%;
                                    top: -25px;
                                    z-index: 0;
                                    left: 19.1176%;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    @include mq(md) {
                                        left: -18%;
                                        width: 60.7046%;
                                        padding-top: 62.4661%;
                                        top: -35px;
                                    }
                                }
                                .courseTit {
                                    @include mq(md) {
                                        img {
                                            //width: 84.1463%;
                                        }
                                    }
                                }
                                .courseDetailTxt {
                                    @include mq(md) {
                                        order: 3;
                                    }
                                }
                                .courseImg {
                                    @include mq(md) {
                                        order: 2;
                                    }
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        background-image: url("/images/index/course1_splash02.png");
                                        width: 425px;
                                        padding-top: 246px;
                                        top: -120px;
                                        z-index: 0;
                                        right: 22.3529%;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        @include mq(md) {
                                            width: 80.1977%;
                                            padding-top: 62.2881%;
                                            top: -80px;
                                        }
                                    }
                                }
                            }
                            &.course2 {
                                margin: 0 auto 104px;
                                padding-top: 60px;
                                padding-bottom: 65px;
                                @include mq(md) {
                                    padding-top: 17.6151%;
                                    margin-bottom: 14.2276%;
                                    padding-bottom: 6.775%;
                                }
                                .courseTit {
                                    img {
                                        width: 24.6226%;
                                        @include mq(md) {
                                            width: 75.5649%;
                                        }
                                    }
                                }
                                .courseDetailTxt {
                                    padding-right: 6.8823%;
                                    @include mq(md) {
                                        padding: 0;
                                    }
                                    p {
                                        width: 91.3137%;
                                        margin-left: auto;
                                        line-height: 1.75;
                                        letter-spacing: 1.6px;
                                        @include mq(md) {
                                            width: 100%;
                                        }
                                    }
                                }
                                &::before {
                                    content: "";
                                    position: absolute;
                                    background-image: url("/images/index/course2_splash01.png");
                                    width: 40.5882%;
                                    padding-top: 29.8039%;
                                    top: -53px;
                                    z-index: 0;
                                    left: 27.2941%;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    @include mq(md) {
                                        width: 100%;
                                        padding-top: 73.3875%;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        top: 0;
                                    }
                                }
                                .course2List {
                                    display: block;
                                    width: 100%;
                                    padding-top: 40px;
                                    @include mq(md) {
                                        order: 5;
                                        padding-top: 5.6497%;
                                    }
                                    .listColumnLayout {
                                        display: block;
                                        max-width: 860px;
                                        background-color: rgba(#fff, 0.5);
                                        width: 81.132%;
                                        margin: 0 auto;
                                        padding: 40px 0 50px;
                                        margin-bottom: 40px;
                                        @include mq(md) {
                                            width: 100%;
                                            padding: 11.2994% 0 8.4745%;
                                            margin-bottom: 24px;
                                        }
                                        h3 {
                                            font-family: $YuMin;
                                            font-size: 3.6rem;
                                            font-weight: bold;
                                            line-height: 1.17;
                                            letter-spacing: 1.8px;
                                            text-align: center;
                                            color: #d90011;
                                            padding-bottom: 30px;
                                            @include mq(md) {
                                                padding-bottom: 8.4745%;
                                                font-size: 2.4rem;
                                            }
                                            span {
                                                font-size: 2.4rem;
                                                color: #000;
                                                @include mq(md) {
                                                    font-size: 1.8rem;
                                                }
                                            }
                                        }
                                        dl {
                                            display: flex;
                                            flex-wrap: wrap;
                                            max-width: 415px;
                                            margin: 0 auto;
                                            @include mq(md) {
                                                max-width: none;
                                                font-feature-settings: "halt";
                                            }
                                            dt,
                                            dd {
                                                width: 50%;
                                                font-family: $YuMin;
                                                margin-bottom: 1em;
                                                @include mq(md) {
                                                    width: 100%;
                                                    text-align: left;
                                                    font-size: 2rem;
                                                    padding-left: 3.5em;
                                                }
                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                    @include mq(md) {
                                                        margin-bottom: 10px;
                                                    }
                                                }
                                            }
                                            dt {
                                                font-size: 2.2rem;
                                                font-weight: bold;
                                                line-height: 1;
                                                letter-spacing: 0.01em;
                                                position: relative;
                                                padding-left: 0.3em;
                                                @include mq(md) {
                                                    padding: 0;
                                                    padding-left: 3.5em;
                                                    margin-bottom: 10px;
                                                }
                                                p {
                                                    display: inline-block;
                                                    position: relative;
                                                    @include mq(md) {
                                                        &::before {
                                                            content: "";
                                                            position: absolute;
                                                            top: -4px;
                                                            bottom: 0;
                                                            left: -1.5em;
                                                            margin: auto;
                                                            width: 19px;
                                                            height: 1px;
                                                            background-color: #d90011;
                                                        }
                                                    }
                                                }
                                                &::before {
                                                    content: "";
                                                    position: absolute;
                                                    top: -4px;
                                                    bottom: 0;
                                                    left: -30px;
                                                    margin: auto;
                                                    width: 19px;
                                                    height: 1px;
                                                    background-color: #d90011;
                                                    @include mq(md) {
                                                        display: none;
                                                    }
                                                }
                                            }
                                            dd {
                                                font-size: 2.2rem;
                                                font-weight: bold;
                                                line-height: 1;
                                                letter-spacing: 1.1px;
                                                color: #d90011;
                                                letter-spacing: -0.09em;
                                                position: relative;
                                                @include mq(md) {
                                                    margin-bottom: 17px;
                                                    padding-bottom: 17px;
                                                }
                                                &::after {
                                                    @include mq(md) {
                                                        content: "";
                                                        position: absolute;
                                                        width: 50%;
                                                        height: 1px;
                                                        background-color: #e5e1d1;
                                                        bottom: 0;
                                                        left: 0;
                                                        right: 0;
                                                        margin: auto;
                                                    }
                                                }
                                                &:last-of-type {
                                                    @include mq(md) {
                                                        padding: 0;
                                                        padding-left: 3.5em;
                                                        margin: 0;
                                                        &::after {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .course2Txt {
                                    width: 100%;
                                    text-align: center;
                                    @include mq(md) {
                                        order: 6;
                                    }
                                    p {
                                        font-family: $YuMin;
                                        font-size: 2rem;
                                        font-weight: bold;
                                        line-height: 1.5;
                                        @include mq(md) {
                                            font-size: 1.7rem;
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                            &.custommenu {
                                margin: 0 auto 104px;
                                padding-top: 60px;
                                padding-bottom: 65px;
                                @include mq(md) {
                                    padding-top: 10.8401%;
                                    margin-bottom: 14.2276%;
                                    padding-bottom: 6.775%;
                                }
                                .courseTit {
                                    img {
                                        width: 20.4716%;
                                        @include mq(md) {
                                            width: 62.9943%;
                                        }
                                    }
                                }
                                .customColumnLayout {
                                    display: flex;
                                    width: 100%;
                                    padding: 0 40px;
                                    margin-bottom: 50px;
                                    position: relative;
                                    @include mq(md) {
                                        order: 2;
                                        padding: 0;
                                        margin-bottom: 0;
                                        flex-wrap: wrap;
                                    }
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        width: 1px;
                                        height: 100%;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        background-color: #999999;
                                        margin: auto;
                                        @include mq(md) {
                                            display: none;
                                        }
                                    }
                                    .trainingBox,
                                    .mealBox {
                                        display: block;
                                        width: 50%;
                                        text-align: center;
                                        @include mq(md) {
                                            width: 100%;
                                        }
                                        h4 {
                                            padding-bottom: 15px;
                                            position: relative;
                                            height: 60px;
                                            @include mq(md) {
                                                height: auto;
                                            }
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                z-index: 0;
                                                left: 0;
                                                right: 0;
                                                top: 0;
                                                margin: auto;
                                            }
                                            img {
                                                margin: 0 auto;
                                                position: relative;
                                                z-index: 2;
                                            }
                                        }
                                        .thumbnail {
                                            width: 100%;
                                            margin-bottom: 40px;
                                            position: relative;
                                            @include mq(md) {
                                                margin-bottom: 5.6497%;
                                                img {
                                                    margin: 0 auto;
                                                }
                                            }
                                        }
                                        ul {
                                            text-align: left;
                                            li {
                                                line-height: 1.75;
                                                position: relative;
                                                padding-left: 1.3em;
                                                &::before {
                                                    position: absolute;
                                                    left: 0;
                                                }
                                                &:first-child {
                                                    &::before {
                                                        content: "①";
                                                    }
                                                }
                                                &:nth-child(2) {
                                                    &::before {
                                                        content: "②";
                                                    }
                                                }
                                                &:last-child {
                                                    &::before {
                                                        content: "②";
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .trainingBox {
                                        padding-left: 6.1224%;
                                        padding-right: 4.0816%;
                                        @include mq(md) {
                                            padding: 0;
                                            padding-bottom: 28.2485%;
                                        }
                                        h4 {
                                            &::before {
                                                width: 64.8717%;
                                                padding-top: 50.7692%;
                                                background-image: url("/images/index/custom_menu_tit_splash01.png");
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                top: -60px;
                                            }
                                            img {
                                                width: 35.8974%;
                                                @include mq(md) {
                                                    width: 41.3841%;
                                                }
                                            }
                                        }
                                    }
                                    .mealBox {
                                        padding-left: 4.0816%;
                                        padding-right: 6.1224%;
                                        @include mq(md) {
                                            padding: 0;
                                        }
                                        h4 {
                                            &::before {
                                                width: 125.3846%;
                                                padding-top: 72.5641%;
                                                background-image: url("/images/index/custom_menu_tit_splash02.png");
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                top: -110px;
                                                left: -70px;
                                                @include mq(md) {
                                                    width: 100%;
                                                    padding-top: 92.5641%;
                                                    top: -50px;
                                                    left: -30px;
                                                }
                                            }
                                        }
                                        ul {
                                            @include mq(md) {
                                                margin-bottom: 21.1864%;
                                            }
                                        }
                                    }
                                }
                                .customTxt {
                                    width: 81.132%;
                                    text-align: center;
                                    max-width: 860px;
                                    margin: 0 auto;
                                    border: 1px solid #999999;
                                    padding: 0 0 30px;
                                    @include mq(md) {
                                        order: 3;
                                        width: 100%;
                                        text-align: left;
                                        padding-bottom: 18px;
                                    }
                                    h5 {
                                        font-family: $YuMin;
                                        font-weight: bold;
                                        line-height: 1;
                                        letter-spacing: 0.7px;
                                        font-size: 2.4rem;
                                        margin-bottom: 10px;
                                        display: inline-block;
                                        background: #e5e1d1;
                                        position: relative;
                                        top: -0.6em;
                                        padding: 0 15px;
                                        @include mq(md) {
                                            margin-bottom: 0;
                                            font-size: 1.9rem;
                                            line-height: 1.3;
                                            text-align: center;
                                            padding: 0 8px;
                                            margin: 0 8px;
                                            top: -1em;
                                        }
                                        span {
                                            font-size: 2.8rem;
                                            color: #d90011;
                                            @include mq(md) {
                                                font-size: 2.2rem;
                                            }
                                        }
                                        &:after {
                                            content: ".";
                                            width: 0;
                                            font-size: 0;
                                            color: transparent;
                                            margin-right: 1px;
                                        }
                                    }
                                    p {
                                        line-height: 1.88;
                                        @include mq(md) {
                                            padding: 0 20px;
                                        }
                                    }
                                }
                            }
                            &.intermediate {
                                margin: 0 auto 104px;
                                padding-top: 60px;
                                padding-bottom: 45px;
                                @include mq(md) {
                                    padding-top: 10.8401%;
                                    margin-bottom: 14.2276%;
                                    padding-bottom: 6.775%;
                                }
                                .courseTit {
                                    padding-bottom: 30px;
                                    img {
                                        width: 12.0754%;
                                        @include mq(md) {
                                            width: 38.1355%;
                                        }
                                    }
                                }
                                p {
                                    width: 100%;
                                    text-align: center;
                                    line-height: 1.75;
                                    letter-spacing: 1.6px;
                                    @include mq(md) {
                                        order: 2;
                                        text-align: left;
                                    }
                                }
                            }
                            &.dataResult {
                                margin: 0 auto;
                                padding-top: 60px;
                                @include mq(md) {
                                    padding: 16.2601% 0 0;
                                }
                                &::after {
                                    display: none;
                                }
                                .courseTit {
                                    padding-bottom: 30px;
                                    img {
                                        width: 30.1886%;
                                        @include mq(md) {
                                            width: 91.9491%;
                                        }
                                    }
                                    &::before {
                                        position: absolute;
                                        background-image: url("/images/index/dataresult_splash.png");
                                        content: "";
                                        top: -140px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        width: 439px;
                                        height: 372px;
                                        @include mq(md) {
                                            left: -50px;
                                        }
                                    }
                                }
                                p.topRead {
                                    width: 100%;
                                    text-align: center;
                                    line-height: 1.75;
                                    letter-spacing: 1.6px;
                                    position: relative;
                                    z-index: 3;
                                    padding-bottom: 40px;
                                    @include mq(md) {
                                        order: 2;
                                        padding: 0 15px 20px;
                                        text-align: left;
                                    }
                                    span {
                                        font-size: 1.8rem;
                                        color: #ad000b;
                                        letter-spacing: 1.8px;
                                    }
                                }
                                .resultLayout {
                                    width: 92.4528%;
                                    display: block;
                                    background-image: url("/images/index/result_bg.png");
                                    max-width: 980px;
                                    margin: 0 auto;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    position: relative;
                                    max-height: 407px;
                                    height: 100%;
                                    padding-top: 5.3773%;
                                    @include mq(md) {
                                        order: 3;
                                        width: 100%;
                                        min-height: inherit;
                                        padding-top: 202.981%;
                                        background-image: url("/images/index/sp_result_bg.jpg");
                                    }
                                    .resultTxtBox {
                                        width: 50%;
                                        margin-left: auto;
                                        @include mq(md) {
                                            width: 100%;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            height: 100%;
                                        }
                                        h5 {
                                            padding-bottom: 14px;
                                            @include mq(md) {
                                                position: absolute;
                                                top: 6.4471%;
                                                width: 100%;
                                                padding-bottom: 0;
                                            }
                                            img {
                                                width: 75.8691%;
                                                margin: 0 auto;
                                                @include mq(md) {
                                                    width: 88.8888%;
                                                }
                                            }
                                        }
                                        p.resultTxtP {
                                            text-align: center;
                                            font-size: 2rem;
                                            line-height: 1.5;
                                            letter-spacing: 1.6px;
                                            font-weight: bold;
                                            padding-bottom: 10px;
                                            @include mq(md) {
                                                position: absolute;
                                                width: 100%;
                                                top: 65%;
                                            }
                                            span {
                                                font-weight: normal;
                                                font-size: 1.6rem;
                                                display: block;
                                            }
                                        }
                                        h6 {
                                            padding-bottom: 6.1349%;
                                            @include mq(md) {
                                                position: absolute;
                                                bottom: 4%;
                                                width: 100%;
                                                padding-bottom: 0;
                                            }
                                            img {
                                                width: 61.5541%;
                                                margin: 0 auto;
                                                @include mq(md) {
                                                    width: 81.5718%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.trainerIntroductionLayout {
                background-image: url("/images/index/trainer_layout_bg.jpg");
                background-position: center top 462px;
                padding-top: 128px;
                background-repeat: no-repeat; //background-size: 100%;
                padding-bottom: 130px;
                @include mq(md) {
                    padding-top: 14.3229%;
                    padding-bottom: 16.276%;
                }
                h2 {
                    padding-bottom: 60px;
                    @include mq(md) {
                        padding-bottom: 6.5104%;
                    }
                    img {
                        margin: 0 auto;
                        max-width: 275px;
                        @include mq(md) {
                            max-width: none;
                            width: 44.921875%;
                        }
                    }
                }
                .postFirstlayout {
                    display: block;
                    width: 100%;
                    padding: 0 20px 120px;
                    max-width: 1020px;
                    margin: 0 auto;
                    @include mq(tab) {
                        padding: 0 30px 11.0677%;
                    }
                    @include mq(md) {
                        padding: 0 15px 11.0677%;
                    }
                    .postTopColumn {
                        display: block;
                        width: 100%;
                        .thumbnail {
                            background-color: #000;
                            margin-bottom: 65px;
                            @include mq(md) {
                                margin-bottom: 8.8075%;
                            }
                            img {
                                width: 44.3877%;
                                margin: 0 auto;
                                @include mq(md) {
                                    width: 100%;
                                }
                            }
                        }
                        .postTxtColumn {
                            display: block;
                            max-width: 900px;
                            width: 100%;
                            padding: 0 20px;
                            margin: 0 auto;
                            @include mq(md) {
                                padding: 0 15px;
                            }
                            .postName {
                                display: block;
                                padding-bottom: 30px;
                                font-family: $YuMin;
                                @include mq(md) {
                                    padding-bottom: 6.775%;
                                }
                                h3 {
                                    font-size: 4.2rem;
                                    font-weight: bold;
                                    line-height: 1.62;
                                    letter-spacing: 1.1px;
                                    display: inline-block;
                                    padding-right: 20px;
                                    @include mq(md) {
                                        font-size: 2.4rem;
                                        padding-right: 12px;
                                    }
                                }
                                span {
                                    display: inline-block;
                                    font-size: 1.8rem;
                                    font-weight: bold;
                                    color: #d90011;
                                    letter-spacing: 0.5px;
                                    transform: rotate(-5deg);
                                    position: relative;
                                    top: -7px;
                                    @include mq(md) {
                                        font-size: 1.5rem;
                                    }
                                }
                            }
                            .postEditor {
                                font-size: 1.8rem;
                                line-height: 1.5;
                                padding-bottom: 40px;
                                @include mq(md) {
                                    font-size: 1.5rem;
                                }
                                strong {
                                    font-weight: bold;
                                }
                            }
                            .postQualification {
                                display: block;
                                width: 100%;
                                border: solid 1px #999999;
                                padding-bottom: 20px;
                                @include mq(md) {
                                    max-width: 604px;
                                    margin: 0 auto;
                                    padding-bottom: 12px;
                                }
                                h4 {
                                    font-size: 1.8rem;
                                    font-weight: bold;
                                    line-height: 1;
                                    letter-spacing: 1.8px;
                                    display: inline-block;
                                    position: relative;
                                    padding: 0 1em;
                                    top: -0.8em;
                                    background-color: #fff;
                                    left: 1em;
                                    @include mq(md) {
                                        font-size: 1.6rem;
                                        left: 50%;
                                        transform: translateX(-50%);
                                    }
                                }
                                p {
                                    padding-left: 2.2em;
                                    padding-right: 10px;
                                    font-size: 1.6rem;
                                    line-height: 1.75;
                                    letter-spacing: 1.6px;
                                    @include mq(md) {
                                        padding: 0 10px;
                                        font-size: 1.3rem;
                                        letter-spacing: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .postTrainerList {
                    display: block;
                    width: 100%;
                    max-width: 1000px;
                    padding: 0 20px;
                    margin: 0 auto;
                    @include mq(tab) {
                        padding: 0 30px;
                    }
                    @include mq(md) {
                        padding: 0 15px;
                    }
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        @include mq(md) {
                            display: none; //padding-bottom: 6.3559%;
                        }
                        li {
                            width: 50%;
                            margin-bottom: 100px;
                            @include mq(md) {
                                width: 100%;
                                display: block;
                                margin-bottom: 11.0677%;
                            }
                            &:nth-child(n + 5) {
                                @include mq(md) {
                                    display: none;
                                }
                            }
                            &:nth-child(odd) {
                                .postColumn {
                                    padding-right: 10.4166%;
                                    @include mq(md) {
                                        padding: 0 15px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                .postColumn {
                                    padding-left: 10.4166%;
                                    @include mq(md) {
                                        padding: 0 15px;
                                    }
                                }
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:nth-last-child(2) {
                                margin-bottom: 0;
                                @include mq(md) {
                                    margin-bottom: 11.0677%;
                                }
                            }
                            .postColumn {
                                max-width: 480px;
                                @include mq(md) {
                                    max-width: none;
                                    margin: 0 auto;
                                    padding: 0 15px;
                                }
                                .thumbnail {
                                    max-width: 280px;
                                    margin: 0 auto;
                                    padding-bottom: 40px;
                                    @include mq(md) {
                                        max-width: 560px;
                                        padding-bottom: 15.5367%;
                                        img {
                                            width: 100%;
                                        }
                                    }
                                }
                                .postName {
                                    font-family: $YuMin;
                                    padding-bottom: 25px;
                                    @include mq(md) {
                                        padding-bottom: 8.4745%;
                                    }
                                    h3 {
                                        font-size: 42px;
                                        font-weight: bold;
                                        line-height: 1.3;
                                        letter-spacing: 1.1px;
                                        display: inline-block;
                                        padding-right: 20px;
                                        @include mq(md) {
                                            font-size: 3rem;
                                            padding-right: 10px;
                                        }
                                    }
                                    span {
                                        display: inline-block;
                                        font-size: 1.8rem;
                                        font-weight: bold;
                                        color: #d90011;
                                        letter-spacing: 0.5px;
                                        transform: rotate(-5deg);
                                        position: relative;
                                        top: -7px;
                                        padding-top: 5px;
                                        @include mq(md) {
                                            font-size: 1.6rem;
                                        }
                                    }
                                }
                                .postEditor {
                                    font-size: 1.8rem;
                                    line-height: 1.5;
                                    padding-bottom: 40px;
                                    strong {
                                        font-weight: bold;
                                    }
                                }
                                .postQualification {
                                    display: block;
                                    width: 100%;
                                    border: solid 1px #999999;
                                    padding-bottom: 20px;
                                    h4 {
                                        font-size: 1.8rem;
                                        font-weight: bold;
                                        line-height: 1;
                                        letter-spacing: 1.8px;
                                        display: inline-block;
                                        position: relative;
                                        padding: 0 1em;
                                        top: -0.8em;
                                        background-color: #fff;
                                        left: 1em;
                                    }
                                    p {
                                        padding-left: 2.2em;
                                        padding-right: 10px;
                                        font-size: 1.6rem;
                                        line-height: 1.75;
                                        letter-spacing: 1.6px;
                                        @include mq(md) {
                                            padding: 0 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .btnBoxLayout {
                        padding-top: 85px;
                        position: relative;
                        @include mq(md) {
                            padding-top: 20px;
                        }
                        &.spMode {
                            display: none;
                            @include mq(md) {
                                display: block;
                            }
                        }
                        .colorBox {
                            max-width: 487px;
                            margin: 0 auto;
                            @include mq(md) {
                                max-width: 704px;
                                width: 99.435%;
                            }
                            a {
                                padding: 26px 0;
                                img {
                                    max-width: 106px;
                                    @include mq(md) {
                                        max-width: none;
                                        width: 29.8022%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.courseIntroduction {
                width: 100%;
                background-color: #f2f0e8;
                background-image: url("/images/index/course_introduction_bg.png");
                background-position: center top 112px;
                background-repeat: no-repeat;
                padding: 85px 0 100px 0;
                @include mq(tab) {
                    padding: 7.03125% 30px 0;
                }
                @include mq(md) {
                    padding: 7.03125% 15px 0;
                }
                .courselayout {
                    width: 100%;
                    max-width: 1020px;
                    padding: 0 20px;
                    margin: 0 auto;
                    @include mq(md) {
                        padding: 0;
                    }
                    h2 {
                        padding-bottom: 60px;
                        @include mq(md) {
                            padding-bottom: 7.317%;
                        }
                        img {
                            margin: 0 auto;
                            max-width: 193px;
                            @include mq(md) {
                                max-width: none;
                                width: 41.9491%;
                            }
                        }
                    }
                    h3.self {
                        display: block;
                        width: 100%;
                        background-image: url("/images/index/self_training_bg.png");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        position: relative;
                        height: 140px;
                        font-family: $YuMin;
                        font-size: 3.2rem;
                        font-weight: bold;
                        line-height: 1;
                        letter-spacing: 1.6px;
                        text-align: center;
                        color: #fff;
                        padding-top: 40px;
                        margin-bottom: 35px;
                        @include mq(md) {
                            font-size: 2.2rem;
                            height: 80px;
                            padding-top: 1em;
                            margin-bottom: 5.42%;
                        }
                    }
                    .selfKeyVisuallayout {
                        display: block;
                        padding-bottom: 60px;
                        @include mq(md) {
                            padding-bottom: 10.1864%;
                        }
                        .courseVisual {
                            padding-bottom: 3.57142%;
                        }
                        .detailBox {
                            margin: auto;
                            position: relative;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 3px;
                                height: 108px;
                                background-image: linear-gradient(to top, #ba000f, #ed4300 80%);
                                top: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                @include mq(md) {
                                    display: none;
                                }
                            }
                            .txtBox {
                                position: relative;
                                z-index: 1;
                                display: flex;
                                @include mq(md) {
                                    display: block;
                                }
                                .price {
                                    width: 50%;
                                    padding-top: 15px;
                                    font-family: $YuMin;
                                    font-size: 3rem;
                                    font-weight: bold;
                                    line-height: 1;
                                    letter-spacing: 0.9px;
                                    color: #b4000e;
                                    padding: 0 0 0 15.3061%;
                                    position: relative;
                                    @include mq(md) {
                                        width: 100%;
                                        padding: 0 0 0.5em;
                                        margin-bottom: 0.5em;
                                        &:before {
                                            content: "";
                                            position: absolute;
                                            width: 100%;
                                            height: 2px;
                                            background-image: linear-gradient(to top, #ba000f, #ed4300 80%);
                                            bottom: 0;
                                        }
                                    }
                                    h4 {
                                        font-size: 3.6rem;
                                        font-weight: bold;
                                        line-height: 1.17;
                                        letter-spacing: 0.9px;
                                        color: #000000;
                                        width: 100%;
                                        text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px,
                                        white -1px -1px 10px;
                                        position: relative;
                                        z-index: 1;
                                        @include mq(md) {
                                            font-size: 2.8rem;
                                        }
                                        &::before {
                                            position: absolute;
                                            content: "";
                                            padding-top: 91.4705%;
                                            width: 100%;
                                            top: -100px;
                                            left: -90px;
                                            background-image: url("/images/index/ttl_splash_gold.png");
                                            background-repeat: no-repeat;
                                            z-index: -1;
                                            @include mq(tab) {
                                                width: 43.9265%;
                                                padding-top: 34.4632%;
                                                left: -50px;
                                                top: -120px;
                                                background-size: cover;
                                            }
                                            @include mq(md) {
                                                width: 43.9265%;
                                                padding-top: 34.4632%;
                                                left: -40px;
                                                top: -50px;
                                                background-size: cover;
                                            }
                                        }
                                    }
                                    h5 {
                                        font-size: 3rem;
                                        font-weight: bold;
                                        line-height: 2.27;
                                        letter-spacing: 0.8px;
                                        color: #b4000e;
                                        display: inline-block;
                                        text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px,
                                        white -1px -1px 10px;
                                        position: relative;
                                        z-index: 2;
                                        span {
                                            font-size: 1.8rem;
                                            letter-spacing: 0.5px;
                                        }
                                        @include mq(md) {
                                            font-size: 2.6rem;
                                            line-height: 1.7;
                                            span {
                                                font-size: 1.6rem;
                                            }
                                        }
                                    }
                                    p {
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                        span {
                                            font-family: $YuMin;
                                            text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px,
                                            white -1px -1px 10px;
                                            font-size: 1.4rem;
                                            font-weight: bold;
                                            letter-spacing: 0.4px;
                                            color: #b4000e;
                                            @include mq(md) {
                                                font-size: 1.2rem;
                                            }
                                        }
                                    }
                                }
                                .txt {
                                    width: 50%;
                                    font-size: 1.6rem;
                                    font-weight: bold;
                                    line-height: 1.75;
                                    letter-spacing: 0.8px;
                                    text-align: left;
                                    color: #000000;
                                    padding: 0 0 0 3.5714%;
                                    @include mq(md) {
                                        width: 100%;
                                        padding: 0;
                                    }
                                    .detailTxt {
                                        display: block;
                                        font-weight: normal;
                                        font-size: 1.4rem;
                                        line-height: 1.75;
                                        letter-spacing: .7px;
                                        color: #000;
                                        padding-top: 1em;
                                    }
                                }
                            }
                        }
                    }
                    .planRepeatLayout {
                        display: block;
                        width: 100%;
                        padding-bottom: 70px;
                        @include mq(md) {
                            padding-bottom: 0;
                        }
                        &.last {
                            padding-bottom: 95px;
                            @include mq(md) {
                                padding-bottom: 0;
                            }
                            h3 {
                                @include mq(md) {
                                    font-size: 1.7rem;
                                }
                            }
                        }
                        h3 {
                            font-family: $YuMin;
                            font-size: 3.2rem;
                            font-weight: bold;
                            line-height: 1;
                            letter-spacing: 1.6px;
                            text-align: center;
                            color: #0f1523;
                            background-image: url("/images/index/course_plan_bg.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            position: relative;
                            height: 140px;
                            padding-top: 40px;
                            margin-bottom: 50px;
                            @include mq(md) {
                                font-size: 1.7rem;
                                padding-top: 0.8em;
                                height: 56px;
                                letter-spacing: 1px;
                                margin-bottom: 9.485%;
                            }
                        }
                        .twoColumnLayout {
                            display: flex;
                            width: 100%;
                            @include mq(md) {
                                flex-wrap: wrap;
                                padding-bottom: 13.5501%;
                            }
                            .courseBox {
                                width: 50%;
                                @include mq(md) {
                                    width: 100%;
                                    margin-bottom: 25px;
                                }
                                &:first-of-type {
                                    padding-right: 5.6122%;
                                    @include mq(md) {
                                        padding-right: 0;
                                    }
                                }
                                &:last-of-type {
                                    padding-left: 5.6122%;
                                    @include mq(md) {
                                        padding-left: 0;
                                        margin-bottom: 0;
                                    }
                                }
                                h4 {
                                    font-family: $YuMin;
                                    font-size: 3rem;
                                    font-weight: bold;
                                    line-height: 1.4;
                                    letter-spacing: 0.8px;
                                    text-align: center;
                                    color: #000;
                                    text-shadow: 0px 0px 6px #ffffff;
                                    padding-bottom: 20px;
                                    @include mq(md) {
                                        font-size: 2.3rem;
                                    }
                                    &.adjustment {
                                        padding-top: 1em;
                                    }
                                }
                                .courseTxt {
                                    background-color: #e5e1d1;
                                    text-align: center;
                                    padding: 15px;
                                    font-size: 1.8rem;
                                    font-weight: bold;
                                    line-height: 1.67;
                                    @include mq(md) {
                                        font-size: 1.5rem;
                                        padding: 10px;
                                    }
                                }
                                p.minTxt {
                                    padding-top: 20px;
                                    line-height: 1.75;
                                    letter-spacing: 1.6px;
                                }
                            }
                            &.last {
                                flex-wrap: wrap;

                                .courseBox {
                                    &:first-of-type {
                                        padding-right: 5.6122%;
                                        padding-bottom: 50px;
                                        @include mq(md) {
                                            padding: 0;
                                            padding-bottom: 6.0975%;
                                            margin-bottom: 0;
                                        }
                                    }
                                    &:nth-last-child(2) {
                                        padding-left: 5.6122%;
                                        @include mq(md) {
                                            padding: 0;
                                            padding-bottom: 6.0975%;
                                            margin-bottom: 0;
                                        }
                                    }
                                    &:last-of-type {
                                        padding-right: 5.6122%;
                                        padding-left: 0;
                                        @include mq(md) {
                                            padding: 0;
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .singleColumnLayout {
                            text-align: center;
                            @include mq(md) {
                                padding-bottom: 13.5501%;
                            }
                            h4 {
                                font-family: $YuMin;
                                font-size: 3rem;
                                font-weight: bold;
                                line-height: 1.4;
                                letter-spacing: 0.8px;
                                text-align: center;
                                color: #000;
                                text-shadow: 0px 0px 6px #ffffff;
                                padding-bottom: 20px;
                                @include mq(md) {
                                    font-size: 2.3rem;
                                }
                            }
                            .txtBox {
                                background-color: #e5e1d1;
                                font-size: 1.8rem;
                                font-weight: bold;
                                line-height: 1;
                                letter-spacing: 1.8px;
                                text-align: center;
                                display: inline-block;
                                padding: 15px;
                                margin-bottom: 20px;
                                @include mq(md) {
                                    font-size: 1.5rem;
                                }
                            }
                            p.mintxt {
                                line-height: 1.75;
                                letter-spacing: 1.6px;
                                @include mq(md) {
                                    text-align: left;
                                }
                            }
                        }
                    }
                    .personalTrainingLayout {
                        display: block;
                        padding-top: 55px;
                        @include mq(md) {
                            padding-top: 25px;
                        }
                        h3 {
                            display: block;
                            width: 100%;
                            background-image: url("/images/index/self_training_bg.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            position: relative;
                            height: 140px;
                            font-family: $YuMin;
                            font-size: 3.2rem;
                            font-weight: bold;
                            line-height: 1;
                            letter-spacing: 1.6px;
                            text-align: center;
                            color: #fff;
                            padding-top: 40px;
                            margin-bottom: 40px;
                            @include mq(md) {
                                font-size: 2rem;
                                margin-bottom: 8.8075%;
                                height: 70px;
                                padding-top: 0.3em;
                            }
                        }
                        .personalColumnLayout {
                            display: flex;
                            position: relative;
                            flex-wrap: wrap;
                            .courseLayout {
                                width: 100%;
                                .repeatCourseColumn {
                                    display: flex;
                                    width: 100%;
                                    justify-content: space-between;
                                    padding-bottom: 10.204%;
                                    @include mq(md) {
                                        display: block;
                                        padding-bottom: 8.204%;
                                    }
                                    &:nth-child(odd) {
                                        flex-direction: row;
                                    }
                                    &:nth-child(even) {
                                        flex-direction: row-reverse;
                                    }
                                    &:last-of-type {
                                        padding-bottom: 0;
                                        @include mq(md) {
                                            padding-bottom: 14.6341%;
                                        }
                                    }
                                    .thumbnail {
                                        display: block;
                                        width: 57.653%;
                                        @include mq(md) {
                                            width: 100%;
                                            padding-bottom: 3.57142%;
                                        }
                                    }
                                    .courseTextColumn {
                                        display: block;
                                        width: 39.7959%;
                                        @include mq(md) {
                                            width: 100%;
                                        }
                                        h4 {
                                            font-family: $YuMin;
                                            font-size: 2.8rem;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            letter-spacing: 0.7px;
                                            color: #000000;
                                            padding-bottom: 0.3em;
                                            text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px,
                                            white -1px -1px 10px;
                                            @include mq(md) {
                                                font-size: 2.8rem;
                                                padding-bottom: 0.5em;
                                            }
                                        }
                                        .price {
                                            display: block;
                                            width: 100%;
                                            font-family: $YuMin;
                                            display: inline-block;
                                            color: #b4000e;
                                            padding-bottom: 1.2em;
                                            @include mq(md) {
                                                padding-bottom: 0;
                                            }
                                            h5 {
                                                font-size: 2.4rem;
                                                font-weight: bold;
                                                letter-spacing: 0.6px;
                                                display: inline-block;
                                                @include mq(md) {
                                                    font-size: 2.6rem;
                                                    line-height: 1;
                                                }
                                                span {
                                                    font-size: 18px;
                                                    letter-spacing: 0.5px;
                                                    @include mq(md) {
                                                        font-size: 1.6rem;
                                                    }
                                                }
                                            }
                                            span {
                                                font-size: 1.4rem;
                                                font-weight: bold;
                                                letter-spacing: 0.4px;
                                                @include mq(md) {
                                                    font-size: 1.2rem;
                                                }
                                            }
                                        }
                                        hr {
                                            width: 100%;
                                            display: block;
                                            height: 3px;
                                            background-image: linear-gradient(to right, #ba000f, #ed4300 80%);
                                            margin: 0 auto;
                                            @include mq(md) {
                                                width: 100%;
                                                height: 2px;
                                                margin: 1.1em 0;
                                            }
                                        }
                                        .courseList {
                                            display: block;
                                            width: 100%;
                                            padding: 2.1em 0 1.3em;
                                            font-size: 1.6rem;
                                            line-height: 1.75;
                                            letter-spacing: 0.8px;
                                            color: #000000;
                                            font-weight: bold;
                                            @include mq(md) {
                                                padding: 0 0 5%;
                                            }
                                        }
                                        .txt {
                                            font-size: 1.4rem;
                                            line-height: 1.75;
                                            letter-spacing: 0.7px;
                                            color: #000000;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.resultAchievementLayout {
                background-color: #fff;
                background-image: url("/images/index/result_achievement_bg.jpg");
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center top;
                padding-top: 155px;
                padding-bottom: 195px;
                @include mq(md) {
                    padding-top: 16.927%;
                    padding-bottom: 0;
                    background-image: url("/images/index/sp_result_achievement_bg.gif");
                }
                h2 {
                    padding-bottom: 60px;
                    img {
                        margin: 0 auto;
                        max-width: 403px;
                        @include mq(md) {
                            max-width: none;
                            width: 80.46875%;
                        }
                    }
                }
                .resultAchievementColumn {
                    max-width: 1065px;
                    width: 100%;
                    padding: 0 20px;
                    margin: 0 auto;
                    @include mq(md) {
                        padding: 0 0 14.7395%;
                    }
                    .voiceRepeatLayout {
                        display: flex;
                        flex-wrap: wrap;
                        &:first-of-type {
                            margin-bottom: 20px;
                        }
                        .topThumb {
                            width: 100%;
                            display: block;
                            position: relative;
                            margin-bottom: 1px;
                            img {
                                position: relative;
                            }
                        }
                        .resultVoiceTxt {
                            width: 100%;
                            display: block;
                            padding-left: 4.3902%;
                            background-image: url("/images/index/voice_bg.gif");
                            background-size: contain;
                            @include mq(md) {
                                padding: 0 3.9902% 0 4.2902%;
                                background-image: none;
                            }
                            .voiceTxtColumn {
                                display: flex;
                                padding: 30px 30px 35px 0;
                                align-items: center;
                                @include mq(md) {
                                    background-image: url("/images/index/voice_bg.gif");
                                    background-position: center center;
                                    flex-wrap: wrap;
                                    padding: 9.1145% 15px 6.5104%;
                                }
                                .baImg {
                                    width: 40.8163%;
                                    @include mq(md) {
                                        width: 100%;
                                        padding-bottom: 7.8125%;
                                    }
                                    img {
                                        margin-left: auto;
                                        @include mq(md) {
                                            margin: 0 auto;
                                        }
                                        &.voice01 {
                                            width: 71.81%;
                                        }
                                        &.voice02 {
                                            width: 81.0089%;
                                        }
                                    }
                                }
                                .txt {
                                    width: 59.1837%;
                                    @include mq(md) {
                                        width: 100%;
                                    }
                                    .colBox {
                                        margin-left: auto;
                                        max-width: 490px;
                                        width: 87.1886%;
                                        background-color: #bcbcbc;
                                        padding: 30px 25px;
                                        @include mq(md) {
                                            max-width: none;
                                            width: 100%;
                                            padding: 15px;
                                        }
                                        p {
                                            color: #000;
                                            line-height: 1.75;
                                            letter-spacing: 1.6px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .voiceRepeatoriginal {
                        display: block;
                        padding-top: 40px;
                        margin-bottom: 80px;
                        @include mq(md) {
                            padding-right: 3.9902%;
                            margin-bottom: 15.4166%;
                        }
                        .originalColumn {
                            width: 100%;
                            display: flex;
                            background-image: url("/images/index/voice_bg.gif");
                            background-size: contain;
                            align-items: center;
                            padding-bottom: 30px;
                            @include mq(md) {
                                flex-wrap: wrap;
                            }
                            .topThumb {
                                width: 45.6585%;
                                margin-top: -33px;
                                @include mq(md) {
                                    width: 100%;
                                }
                                img {
                                    @include mq(md) {
                                        margin: 0 auto;
                                    }
                                }
                            }
                            .txtBox {
                                width: 54.3415%;
                                padding-left: 2.9268%;
                                @include mq(md) {
                                    width: 100%;
                                    padding-left: 4.2902%;
                                }
                                img {
                                    margin-bottom: 30px;
                                    @include mq(md) {
                                        margin: 10% auto;
                                        max-width: 80%;
                                    }
                                }
                                .colBox {
                                    margin-right: auto;
                                    max-width: 490px;
                                    width: 92.9791%;
                                    background-color: #bcbcbc;
                                    padding: 30px 25px;
                                    @include mq(md) {
                                        width: 87.7604%;
                                        max-width: none;
                                        margin: 0 auto;
                                    }
                                    p {
                                        color: #000;
                                        line-height: 1.75;
                                        letter-spacing: 1.6px;
                                    }
                                }
                            }
                        }
                    }
                    .voiceTxtOnlyLayout {
                        display: flex;
                        padding-left: 4.3902%;
                        margin-bottom: 50px;
                        @include mq(tab) {
                            flex-wrap: wrap;
                            padding: 0 30px;
                            margin-bottom: 10.4166%;
                        }
                        @include mq(md) {
                            flex-wrap: wrap;
                            padding: 0 15px;
                            margin-bottom: 15.4166%;
                        }
                        &.replacement {
                            flex-direction: row-reverse;
                            justify-content: space-between;
                        }
                        &:last-of-type {
                            margin-bottom: 80px;
                            @include mq(md) {
                                margin-bottom: 15.4166%;
                            }
                        }
                        .voiceInfoBlock {
                            padding-top: 25px;
                            @include mq(md) {
                                padding-top: 0;
                                padding-bottom: 50px;
                            }
                            &.top {
                                width: 32.9591%;
                                @include mq(md) {
                                    width: 100%;
                                }
                            }
                            &.center {
                                @include mq(md) {
                                    width: 100%;
                                    text-align: center;
                                }
                            }
                            text-align: center;
                            color: #0f1523;
                            h3 {
                                font-size: 2.2rem;
                                font-weight: bold;
                                line-height: 1.27;
                                letter-spacing: 2.2px;
                                padding-bottom: 10px;
                            }
                            p.mintxt {
                                line-height: 1.75;
                                letter-spacing: 1.6px;
                                padding-bottom: 10px;
                            }
                            img {
                                @include mq(md) {
                                    margin: 0 auto;
                                    width: 42.5141%;
                                    min-width: 210px;
                                }
                            }
                        }
                        .voiceInfoTxt {
                            width: 67.0409%;
                            @include mq(md) {
                                width: 100%;
                            }
                            &.center {
                                .txtBox {
                                    margin: 0 auto 0 0;
                                    &::before {
                                        top: 0;
                                        bottom: 0;
                                        right: -35px;
                                        left: inherit;
                                        margin: auto;
                                        transform: rotateY(180deg);
                                        @include mq(md) {
                                            top: -29px;
                                            bottom: inherit;
                                            left: 50px;
                                            transform: rotate(90deg);
                                            right: inherit;
                                        }
                                    }
                                }
                            }
                            .txtBox {
                                background-image: url("/images/index/voice_result_bg.jpg");
                                border-radius: 12px;
                                max-width: 622px;
                                width: 94.6727%;
                                margin-left: auto;
                                padding: 15px 20px;
                                position: relative;
                                @include mq(md) {
                                    max-width: none;
                                    width: 100%;
                                }
                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 35px;
                                    height: 23px;
                                    background-image: url("/images/index/voice_result_bg_arrow.gif");
                                    left: -35px;
                                    bottom: 75px;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    @include mq(md) {
                                        left: 50px;
                                        top: -29px;
                                        bottom: 0;
                                        transform: rotate(90deg);
                                    }
                                }
                                &.last {
                                    &::before {
                                        bottom: 200px;
                                    }
                                }
                                h4 {
                                    font-size: 2.4rem;
                                    font-weight: bold;
                                    line-height: 1.5;
                                    padding-bottom: 5px;
                                    @include mq(md) {
                                        font-size: 2rem;
                                    }
                                    span {
                                        font-weight: normal;
                                    }
                                }
                                p {
                                    line-height: 1.75;
                                    letter-spacing: 1.6px;
                                    @include mq(md) {
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }
                    ul.voiceList {
                        display: flex;
                        width: 100%;
                        padding-left: 4.3902%;
                        @include mq(tab) {
                            flex-wrap: nowrap;
                            padding-left: 0;
                        }
                        @include mq(md) {
                            flex-wrap: wrap;
                            padding-left: 0;
                        }
                        li {
                            width: 33.33333%;
                            text-align: center;
                            color: #0f1523;
                            position: relative;
                            @include mq(tab) {
                                padding: 0 30px;
                                margin-bottom: 4.5572%;
                            }
                            @include mq(md) {
                                width: 100%;
                                padding: 0 15px;
                                margin-bottom: 15.5572%;
                            }
                            &:nth-child(2) {
                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 1px;
                                    height: 50%;
                                    top: 0;
                                    bottom: 0;
                                    left: -15px;
                                    margin: auto;
                                    background-color: #999999;
                                    @include mq(tab) {
                                        display: block;
                                    }
                                    @include mq(md) {
                                        display: none;
                                    }
                                }
                                &::after {
                                    content: "";
                                    position: absolute;
                                    width: 1px;
                                    height: 50%;
                                    top: 0;
                                    bottom: 0;
                                    right: 15px;
                                    margin: auto;
                                    background-color: #999999;
                                    @include mq(tab) {
                                        display: block;
                                    }
                                    @include mq(md) {
                                        display: none;
                                    }
                                }
                            }
                            &:last-child {
                                @include mq(md) {
                                    margin-bottom: 0;
                                }
                            }
                            h3 {
                                font-size: 2.2rem;
                                font-weight: bold;
                                line-height: 1.27;
                                letter-spacing: 2.2px;
                                padding-bottom: 10px;
                                @include mq(tab) {
                                    font-size: 2rem;
                                }
                            }
                            p.mintxt {
                                line-height: 1.75;
                                letter-spacing: 1.6px;
                                padding-bottom: 10px;
                            }
                            img {
                                margin: 0 auto;
                                width: 92.3312%;
                                @include mq(tab) {
                                    width: 92.3312%;
                                    min-width: inherit;
                                }
                                @include mq(md) {
                                    width: 42.5141%;
                                    min-width: 210px;
                                }
                            }
                        }
                    }
                }
            }
            &.qaLayout {
                background-color: #f2f0e8;
                padding-top: 120px;
                background-image: url("/images/index/qa_Layout_bg.png");
                background-repeat: no-repeat;
                padding-bottom: 95px;
                @include mq(md) {
                    padding-top: 14.7395%;
                    padding-bottom: 12.3697%;
                }
                h2 {
                    padding-bottom: 60px;
                    @include mq(md) {
                        padding-bottom: 6.5104%;
                    }
                    img {
                        margin: 0 auto;
                        max-width: 227px;
                        @include mq(md) {
                            max-width: none;
                            width: 48.177%;
                        }
                    }
                }
                .qaColumnLayout {
                    display: block;
                    max-width: 1020px;
                    width: 100%;
                    padding: 0 20px;
                    margin: 0 auto;
                    @include mq(tab) {
                        padding: 0 30px;
                    }
                    @include mq(md) {
                        padding: 0 15px;
                    }
                    h3 {
                        display: block;
                        width: 100%;
                        background-image: url("/images/index/self_training_bg.png");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        position: relative;
                        height: 140px;
                        font-family: $YuMin;
                        font-size: 3.2rem;
                        font-weight: bold;
                        line-height: 1;
                        letter-spacing: 1.6px;
                        text-align: center;
                        color: #fff;
                        padding-top: 40px;
                        @include mq(md) {
                            font-size: 2rem;
                            padding-top: 0.5em;
                            height: 50px;
                        }
                    }
                    .qaBlock {
                        &.f {
                            padding-top: 80px;
                            padding-bottom: 40px;
                            @include mq(md) {
                                padding-top: 16px;
                            }
                        }
                        &.s {
                            padding-top: 24px;
                            padding-bottom: 40px;
                        }
                        &.l {
                            padding-top: 24px;
                        }
                    }
                    dl {
                        background-color: #e5e1d1;
                        padding: 25px 15px 15px;
                        margin-bottom: 8px;
                        @include mq(md) {
                            padding: 20px 10px 10px;
                        }
                        dt {
                            background-image: url("/images/index/qa_icon_q.svg");
                            background-repeat: no-repeat;
                            font-size: 2.2rem;
                            font-weight: bold;
                            line-height: 1.27;
                            letter-spacing: 2.2px;
                            background-size: 32px 47px;
                            padding: 0.5em 0 0.5em 3em;
                            margin-bottom: 15px;
                            background-position: center left 0.6em;
                            @include mq(md) {
                                font-size: 1.7rem;
                                background-size: 24px 22px;
                                padding: 0 0 0 36px;
                                background-position: top left 0.2em;
                            }
                        }
                        dd {
                            background-color: #f4f2eb;
                            background-image: url("/images/index/qa_icon_a.svg");
                            background-repeat: no-repeat;
                            background-position: left 0.9em top 1.1em;
                            background-size: 32px 37px;
                            padding: 30px 40px 20px 4.2em;
                            line-height: 1.75;
                            letter-spacing: 1.6px;
                            @include mq(md) {
                                font-size: 2rem;
                                background-size: 15px 19px;
                                padding: 1.4em 0.5em 1em 36px;
                                background-position: left 0.6em top 1.3em;
                                font-size: 1.4rem;
                            }
                            .point {
                                font-size: 2.2rem;
                                line-height: 1.27;
                                letter-spacing: 2.2px;
                                padding-bottom: 0.5em;
                                @include mq(md) {
                                    font-size: 1.8rem;
                                }
                            }
                        }
                    }
                }
            }
            &.athkitBlogLayout {
                width: 100%;
                background-repeat: no-repeat;
                background-image: url("/images/index/blog_bg.jpg");
                background-size: cover;
                background-position: top right;
                padding: 88px 0 0 0;
                @include mq(md) {
                    padding: 11.4583% 0 10.4166% 0;
                }
                h2 {
                    padding-bottom: 70px;
                    @include mq(md) {
                        padding-bottom: 9.1145%;
                    }
                    img {
                        margin: 0 auto;
                    }
                }
                .postListLayout {
                    display: block;
                    width: 100%;
                    max-width: 1020px;
                    padding: 0 20px 95px;
                    margin: 0 auto;
                    @include mq(md) {
                        padding: 0 15px;
                    }
                    ul {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        padding-bottom: 80px;
                        @include mq(tab) {
                            flex-wrap: nowrap;
                            padding-bottom: 5.8401%;
                        }
                        @include mq(md) {
                            flex-wrap: wrap;
                            padding-bottom: 5.8401%;
                        }
                        li {
                            width: 29.5918%;
                            @include mq(tab) {
                                width: 29.5918%;
                            }
                            @include mq(md) {
                                width: 100%;
                                margin-bottom: 10%;
                            }
                            &:last-of-type {
                                @include mq(md) {
                                    margin-bottom: 0%;
                                }
                            }
                            a {
                                display: block;
                                width: 100%;
                                color: #fff;
                                @include mq(md) {
                                    width: 80%;
                                    margin: 0 auto;
                                }
                                .postThumb {
                                    display: block;
                                    max-width: 295px;
                                    margin-bottom: 30px;
                                    overflow: hidden;
                                    position: relative;
                                    @include mq(md) {
                                        max-width: none;
                                        margin-bottom: 4.065%;
                                    }
                                    &::before {
                                        content: "";
                                        display: block;
                                        padding-top: (0.623 * 100%);
                                    }
                                    > img {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        width: 100%;
                                    }
                                }
                                .txtBox {
                                    display: block;
                                    time {
                                        font-family: "Times New Roman", sans-serif;
                                        font-size: 1.7rem;
                                        font-weight: bold;
                                        line-height: 1;
                                        letter-spacing: 1.7px;
                                        padding-bottom: 12px;
                                        display: block;
                                        @include mq(md) {
                                            padding-bottom: 0.3em;
                                        }
                                    }
                                    h3 {
                                        font-size: 1.8rem;
                                        font-weight: bold;
                                        line-height: 1.56;
                                        letter-spacing: 0.9px;
                                        padding-bottom: 20px;
                                        @include mq(md) {
                                            padding-bottom: 0;
                                        }
                                    }
                                    p {
                                        font-size: 1.4rem;
                                        line-height: 1.75;
                                        letter-spacing: 1.4px;
                                        @include mq(md) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .btnBoxLayout {
                        .colorBox {
                            max-width: 487px;
                            margin: 0 auto;
                            @include mq(md) {
                                max-width: 704px;
                                width: 99.435%;
                            }
                            a {
                                padding: 26px 0;
                                img {
                                    max-width: 106px;
                                    @include mq(md) {
                                        max-width: none;
                                        width: 29.8022%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.contactLayout {
                background-color: #f2f0e8;
                padding-top: 60px;
                padding-bottom: 110px;
                @include mq(md) {
                    padding-top: 7.8125%;
                    padding-bottom: 14.3229%;
                }
                h2 {
                    padding-bottom: 70px;
                    @include mq(md) {
                        padding-bottom: 9.1145%;
                    }
                    img {
                        margin: 0 auto;
                        max-width: 474px;
                        @include mq(md) {
                            max-width: none;
                            width: 79.0364%;
                        }
                    }
                }
                p.txt {
                    font-size: 2rem;
                    line-height: 1.4;
                    letter-spacing: 2px;
                    text-align: center;
                    @include mq(md) {
                        padding: 0 15px;
                        font-size: 1.8rem;
                    }
                }
                .telBox {
                    display: flex;
                    margin: 0 auto;
                    max-width: 545px;
                    width: 100%;
                    align-items: center;
                    padding-top: 45px;
                    @include mq(md) {
                        padding-top: 5.859375%;
                        display: block;
                        text-align: center;
                    }
                    a {
                        display: block;
                        padding-right: 15px;
                        @include mq(md) {
                            padding-right: 0;
                            padding-bottom: 5.859375%;
                            img {
                                margin: 0 auto;
                            }
                        }
                    }
                    .timeBox {
                        background-color: #000;
                        color: #fff;
                        font-size: 1.4rem;
                        line-height: 1;
                        letter-spacing: 1.4px;
                        padding: 8px 15px;
                        @include mq(md) {
                            display: inline-block;
                            margin: 0 auto;
                        }
                    }
                }
                .contactFormColumn {
                    display: block;
                    width: 100%;
                    max-width: 1020px;
                    padding: 90px 20px 0;
                    margin: 0 auto;
                    @include mq(tab) {
                        padding: 11.71875% 30px 0;
                    }
                    @include mq(md) {
                        padding: 11.71875% 15px 0;
                    }
                    h3 {
                        background-image: url("/images/index/self_training_bg.png");
                        height: 73px;
                        font-family: $YuMin;
                        font-size: 30px;
                        font-weight: bold;
                        line-height: 1.4;
                        letter-spacing: 0.8px;
                        text-align: center;
                        color: #f9f9f9;
                        padding-top: 0.5em;
                        margin-bottom: 60px;
                        @include mq(md) {
                            font-size: 2.2rem;
                            height: auto;
                            padding: 15px 8px;
                            margin-bottom: 8.13%;
                        }
                    }

                    .formLayout {
                        width: 100%;
                        padding-top: 65px;
                        @include mq(md) {
                            padding-top: 8.8075%;
                        }
                        h4 {
                            font-family: $YuMin;
                            font-size: 2.6rem;
                            font-weight: bold;
                            line-height: 1;
                            letter-spacing: 2.6px;
                            text-align: center;
                            padding-bottom: 35px;
                        }
                        .infoLayoutColumn {
                            width: 100%;
                            background-color: #fff;
                            .infoBox {
                                width: 100%;
                                height: 315px;
                                margin: 0 auto;
                                overflow: hidden;
                                line-height: 1.75;
                                letter-spacing: 1.6px;
                                padding: 30px 0 35px 30px;
                                h5 {
                                    padding-bottom: 40px;
                                    @include mq(md) {
                                        padding-bottom: 20px;
                                    }
                                }
                                p.topTxt {
                                    display: block;
                                    padding-bottom: 40px;
                                }
                                ul.columnWrap {
                                    display: block;
                                    width: 100%;
                                    li {
                                        padding-bottom: 40px;
                                        &:last-child {
                                            padding-bottom: 0;
                                        }
                                        h6 {
                                            padding-bottom: 20px;
                                        }
                                        p.liTxt {}
                                        dl {
                                            display: block;
                                            width: 100%;
                                            dt {
                                                padding-bottom: 0.5em;
                                                &:first-of-type {
                                                    padding-top: 1em;
                                                }
                                            }
                                            dd {
                                                padding-bottom: 1em;
                                            }
                                            &:last-of-type {
                                                dt {
                                                    padding-top: 0;
                                                }
                                                dd {
                                                    padding-bottom: 0;
                                                }
                                            }
                                        }
                                        ul {
                                            display: block;
                                            padding-left: 2em;
                                            padding-bottom: 1em;
                                            @include mq(md) {
                                                padding-left: 1em;
                                            }
                                            li {
                                                position: relative;
                                                padding-bottom: 0;
                                                padding-left: 1.5em;
                                                &::before {
                                                    content: "\025cb";
                                                    position: absolute;
                                                    left: 0;
                                                    top: 0;
                                                    bottom: 0;
                                                    margin: auto;
                                                }
                                            }
                                        }
                                        .signature {
                                            h6 {
                                                padding-bottom: 0;
                                                font-size: 1.8rem;
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .agreementLayout {
                            display: block;
                            width: 100%;
                            text-align: center;
                            padding-top: 30px;
                            padding-bottom: 20px;
                            #check {
                                display: inline-block;
                                cursor: pointer;
                                @include mq(md) {
                                    display: block;
                                    text-align: left; //padding-left: 2em;
                                }
                                .checkbox01-input {
                                    display: none;
                                }
                                .checkbox01-parts {
                                    cursor: pointer;
                                    position: relative;
                                    padding-left: 3em;
                                    line-height: 1.75;
                                    letter-spacing: 1.6px;
                                    @include mq(md) {
                                        padding-left: 0;
                                        text-align: left;
                                    }
                                    &::before {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        top: -5px;
                                        left: 0;
                                        width: 30px;
                                        height: 30px;
                                        border: 1px solid #bbbbbb;
                                        border-radius: 4px;
                                        background-color: #fff;
                                        @include mq(md) {
                                            top: 2px;
                                            width: 15px;
                                            height: 15px;
                                            left: -28px;
                                        }
                                    }
                                }
                                .checkbox01-input:checked + .checkbox01-parts {
                                    &::after {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        top: -3px;
                                        left: 11px;
                                        width: 9px;
                                        height: 17px;
                                        transform: rotate(40deg);
                                        border-bottom: 3px solid #ad000b;
                                        border-right: 3px solid #ad000b;
                                        @include mq(md) {
                                            top: 1px;
                                            left: -22px;
                                            width: 4px;
                                            height: 10px;
                                        }
                                    }
                                }
                            }
                            p {
                                display: inline-block;
                                padding-left: 1em;
                            }
                        }
                        .gRecaptchaBox {
                            display: flex;
                            width: 100%;
                            padding-bottom: 30px;
                            flex-wrap: wrap;
                            p {
                                width: 100%;
                                display: block;
                                text-align: center;
                                font-size: 1.4rem;
                                padding-bottom: 8px;
                            }
                            .g-recaptcha {
                                display: block;
                                margin: 0 auto;
                            }
                            p.errorTxt {
                                width: 100%;
                                color: #ad000b;
                                line-height: 1;
                                padding-top: 0.8em;
                                font-weight: bold;
                                font-size: 1.6rem;
                            }
                        }
                    }
                    .contactBtnLayout {
                        display: block;
                        width: 100%;
                        position: relative;
                        &.active {
                            padding-bottom: 115px;
                            @include mq(md) {
                                padding-bottom: 0;
                            }
                        }
                        .jsBtnA {
                            .colorBox {
                                max-width: 487px;
                                margin: 0 auto;
                                a {
                                    padding: 26px 0;
                                    img {
                                        max-width: 216px;
                                        margin: 0 auto;
                                    }
                                    @include mq(md) {
                                        img {
                                            width: 80.2874%;
                                            margin: 0 auto;
                                        }
                                    }
                                }
                            }
                            &.active {
                                animation-name: fadeOut;
                                animation-duration: 0.8s;
                                z-index: 0;
                                animation-fill-mode: forwards;
                                display: none;
                            }
                            &.notActive {
                                animation-name: fadeIn;
                                animation-duration: 0.8s;
                                z-index: 99;
                                animation-fill-mode: forwards;
                                display: block;
                            }
                        }

                        .jsBtnB {
                            position: absolute;
                            top: 0;
                            display: none;
                            @include mq(md) {
                                position: relative;
                                margin: 0 auto;
                            }
                            &.backBox {
                                max-width: 487px;
                                left: 0;
                                width: 49%;
                                border: 2px solid #f2f0e8;
                                transition: 0.5s;
                                @include mq(md) {
                                    border: 1px solid #f21d1e;
                                    margin-bottom: 15px;
                                    width: 100%;
                                    padding: 20px 0;
                                }
                                &.uaHover {
                                    &:hover {
                                        border: 2px solid #f21d1e;
                                        transition: 0.5s;
                                    }
                                }
                                a {
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    @include mq(md) {
                                        display: block;
                                    }
                                    img {
                                        margin: 0 auto;
                                        width: 65.1546%;
                                    }
                                }
                            }
                            &.colorBox {
                                max-width: 487px;
                                right: 0;
                                width: 49%;
                                a {
                                    padding: 26px 0;
                                }
                                @include mq(md) {
                                    width: 100%;
                                    a {
                                        padding: 20px 0;
                                        img {
                                            width: 78.6447%;
                                            margin: 0 auto;
                                        }
                                    }
                                }
                            }
                            &.active {
                                animation-name: fadeIn;
                                animation-duration: 0.8s;
                                z-index: 0;
                                animation-fill-mode: forwards;
                                display: block;
                            }
                            &.notActive {
                                animation-name: fadeOut;
                                animation-duration: 0.8s;
                                z-index: 99;
                                animation-fill-mode: forwards;
                                display: none;
                            }
                        }
                    }
                    .testBtn {
                        display: block;
                        padding: 30px;
                        a {
                            display: block;
                            max-width: 300px;
                            padding: 15px;
                            margin: 0 auto;

                            color: #fff;
                            position: relative;
                            z-index: 3;
                            transition: 0.5s;
                            p {
                                position: relative;
                                z-index: 99;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background-image: linear-gradient( to bottom,
                                #f21d1e,
                                #ca0010 51%,
                                #ba000f 52%,
                                #bb000f 70%,
                                #a7000d 99%);
                                z-index: 2;
                                transition: 0.5s;
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background-image: linear-gradient( to bottom,
                                #f82a2b,
                                #e00218 51%,
                                #d30016 52%,
                                #d40016 70%,
                                #c50013 99%);
                                transition: 0.5s;
                                z-index: 1;
                            }
                            transition: 0.5s;
                            opacity: 1;
                            &.uaHover {
                                &:hover {
                                    transition: 0.5s;
                                    &::before {
                                        opacity: 0;
                                        transition: 0.5s;
                                    }
                                }
                            }
                        }
                    }
                }
                .thanksLayout {
                    width: 100%;
                    display: none;
                    padding: 100px 20px 0;
                    max-width: 1020px;
                    margin: 0 auto;
                    padding-top: 100px;
                    text-align: center;
                    .contactBox {
                        display: block;
                        width: 100%;
                        text-align: center;
                    }
                    h3 {
                        text-align: center;
                        font-size: 3rem;
                        img {
                            margin: 0 auto;
                            width: 75%;
                            @include mq(md) {
                                width: 100%;
                            }
                        }
                    }
                    h4 {
                        font-size: 1.8rem;
                    }
                    p {
                        font-size: 1.8rem;
                        line-height: 1.75;
                    }
                }
            }
            &.footerInfoLayout {
                display: block;
                background-image: url("/images/index/footer_info_bg.jpg");
                padding: 100px 0 80px 0;
                @include mq(md) {
                    padding: 32px 0 10.4166%;
                }
                .repeatBannerTitle {
                    display: none;
                    color: #fff;
                    text-align: center;
                    font-weight: bold;
                    font-size: 2.2rem;
                    margin-bottom: 1em;
                    @include mq(md) {
                        display: block;
                    }
                }
                ul.repeatBanner {
                    display: flex;
                    max-width: 1020px;
                    padding: 0 20px;
                    margin: 0 auto;
                    flex-wrap: wrap;
                    @include mq(tab) {
                        display: flex;
                        padding: 0 30px;
                    }
                    @include mq(md) {
                        display: block;
                        padding: 0 15px;
                    }
                    li {
                        width: 31.196%;
                        margin-right: 2.8571%;
                        padding-bottom: 45px;
                        @include mq(tab) {
                            width: 50%;
                            padding-bottom: 45px;
                            margin-right: 0;
                        }
                        @include mq(md) {
                            width: 80%;
                            padding-bottom: 35px;
                            margin: 0 auto;
                        }
                        &:nth-child(odd) {
                            @include mq(tab) {
                                padding-right: 10px;
                            }
                            @include mq(md) {
                                padding-right: 0;
                            }
                        }
                        &:nth-child(even) {
                            @include mq(tab) {
                                padding-left: 10px;
                            }
                            @include mq(md) {
                                padding-left: 0;
                            }
                        }
                        &:nth-child(3n) {
                            margin-right: 0;
                            @include mq(md) {
                                margin: 0 auto;
                            }
                        }
                        &:last-child {
                            padding-bottom: 0;
                            margin-right: 0;
                            @include mq(md) {
                                margin: 0 auto;
                            }
                        }
                        a {
                            display: block;
                            color: rgba(#fff, 0.7);
                            @include mq(md) {
                                width: 100%;
                            }
                            .thumb {
                                width: 100%;
                                img {
                                    @include mq(md) {
                                        width: auto;
                                        margin: 0 auto;
                                    }
                                }
                            }
                            .txt {
                                padding-top: 20px;
                                font-size: 1.2rem;
                                line-height: 1.7;
                                letter-spacing: 1.4px;
                                @include mq(md) {
                                    line-height: 1.4;
                                }
                            }
                        }
                    }
                }
                .snsLayout {
                    margin: 0 auto;
                    padding-top: 30px;
                    @include mq(md) {
                        padding-top: 10.4166%;
                    }
                    ul {
                        display: flex;
                        max-width: 300px;
                        margin: 0 auto;
                        width: 100%;
                        justify-content: center;
                        letter-spacing: -0.4em;
                        align-items: flex-start;
                        @include mq(md) {
                            margin: 0 auto;
                        }

                        li {
                            letter-spacing: normal;
                            margin-right: 5px;
                            width: auto;
                            @include mq(md) {
                                margin-bottom: 10px;
                                margin-right: 10px;
                            }

                            &:last-of-type {
                                margin-left: 5px;
                                padding-top: 0.3em;
                                @include mq(md) {
                                    padding-top: 0;
                                }
                            }
                            &.social_tw {
                                padding-top: 5px;
                                margin-right: 0;
                                @include mq(md) {
                                    padding-left: 0;
                                    padding-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        display: block;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}