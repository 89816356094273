@charset "utf-8";
/* ================================================================================
フッター
================================================================================ */

footer#footer {
    display: block;
    width: 100%;
    background-color: #000;
    padding: 60px 0 50px 0;
    position: relative;

    @include mq(md) {
        padding: 0;
    }

    .footerContentLayout {
        width: 100%;
        display: flex;
        max-width: 1020px;
        padding: 0 20px 90px;
        color: #fff;
        margin: 0 auto;
        @include mq(md) {
            flex-wrap: wrap;
            padding: 32px 0 0;
        }
        a {
            color: #fff;
        }
        .logoColumn {
            display: block;
            max-width: 255px;
            width: 26.0204%;
            @include mq(md) {
                width: 100%;
                max-width: none;
                margin-top: 40px;
                order: 2;
                background: #4f5158;
            }
            .fLogo {
                background-color: #fff;
                max-width: 193px;
                padding: 20px 15px;
                position: relative;
                margin-bottom: 20px;
                z-index: 10;
                @include mq(md) {
                    margin: 0 auto;
                    width: 50.390625%;
                    max-width: none;
                }
                img {
                    width: 152px;
                    margin: 0 auto;
                    @include mq(md) {
                        width: 78.5529%;
                    }
                }
            }
            .logoColumnSns {
                display: flex;
                align-items: center;
                @include mq(md) {
                    justify-content: center;
                    background: rgba($color: #000000, $alpha: 0.15);
                    margin: 16px 40px 0;
                    padding: 4px 16px;
                }
                > p {
                    font-family: $Helvetica;
                    font-weight: bold;
                    margin-right: 12px;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    color: #999;
                    @include mq(md) {
                        margin-right: 16px;
                    }
                }
            }
            ul {
                display: flex;
                @include mq(md) {
                    justify-content: center;
                    padding: 8.71875% 0 7.8125%;
                }
                li {
                    a {
                        display: block;
                    }
                    &:last-child {
                        padding-left: 15px;
                    }
                }
            }
        }
        .outLinkColumn {
            display: block;
            width: 26.5102%;
            @include mq(md) {
                width: 100%;
                order: 3;
                padding-top: 24px;
                background: #4f5158;
            }
            ul {
                display: flex;
                padding-bottom: 20px;
                @include mq(md) {
                    display: block;
                }
                li {
                    @include mq(md) {
                        width: 100%;
                        text-align: center;
                    }
                    a {
                        font-size: 1.6rem;
                        font-weight: bold;
                        line-height: 1.31;
                        letter-spacing: 1.6px;
                        vertical-align: middle;
                        &::before {
                            content: "";
                            width: 8px;
                            height: 0;
                            border-style: solid;
                            border-width: 4px 0 4px 4px;
                            border-color: transparent transparent transparent #f21d1e;
                            display: inline-block;
                        }
                    }
                }
            }
            .linkBox {
                display: block;
                @include mq(md) {
                    padding-bottom: 9.11458%;
                }
                a {
                    display: block;
                    img {
                        width: 94.8207%;
                        @include mq(md) {
                            width: 59.8958%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        .telColumn {
            display: block;
            max-width: 400px;
            width: 40.8163%;
            margin-left: auto;
            @include mq(md) {
                max-width: none;
                width: 100%;
                margin: 0 auto;
                order: 1;
            }
            .telBox {
                display: flex;
                align-items: flex-start;
                @include mq(md) {
                    flex-wrap: wrap;
                }
                a {
                    width: 70.5%;
                    @include mq(md) {
                        width: 100%;
                        padding-bottom: 10px;
                        img {
                            margin: 0 auto;
                        }
                    }
                }
                .timeBox {
                    display: block;
                    padding-left: 10px;
                    @include mq(md) {
                        padding: 0;
                        width: 100%;
                        text-align: center;
                    }
                    .txt {
                        font-size: 1.2rem;
                        letter-spacing: 0.6px;
                        width: 105px;
                        background-color: #fff;
                        line-height: 1;
                        padding: 2px 10px;
                        color: #000;
                        text-align: center;
                        margin: 0 auto;
                        margin-bottom: 5px;
                        @include mq(md) {
                            display: inline-block;
                        }
                    }
                    p.time {
                        font-size: 1.2rem;
                        letter-spacing: 0.6px;
                        @include mq(md) {
                            display: inline-block;
                        }
                    }
                }
            }
            .btnBoxLayout {
                padding-top: 15px;
                @include mq(md) {
                    padding-top: 16px;
                }
                .colorBox {
                    margin: 0 auto;
                    @include mq(md) {
                        max-width: 704px;
                        width: 91.6666%;
                    }
                    a {
                        padding: 26px 0;
                        img {
                            max-width: 239px;
                            @include mq(md) {
                                max-width: none;
                                width: 67.6136%;
                            }
                        }
                    }
                }
            }
        }
    }
    .smallLayout {
        text-align: center;
        display: block;
        width: 100%;
        color: #fff;
        ul {
            display: flex;
            padding-bottom: 20px;
            margin: 0 auto;
            max-width: 500px;
            justify-content: center;
            @include mq(md) {
                background: #4f5158;
            }
            li {
                @include mq(md) {
                    //width: 100%;
                    text-align: center;
                }
                a {
                    font-size: 1.4rem;
                    color: #fff;
                    line-height: 1.31;
                    letter-spacing: 1.6px;
                }
                &:last-child {
                    padding-left: 40px;
                }
            }
        }
        small {
            font-family: $Helvetica;
            display: block;
            font-size: 1.4rem;
            line-height: 1.5;
            letter-spacing: 1.4px;
            @include mq(md) {
                font-size: 1.1rem;
                padding: 16px 5px 8px;
            }
        }
        p {
            font-size: 1rem;
            line-height: 1.75;
            letter-spacing: 1.2px;
            padding: 0 5px 16px;
        }
    }
}
