@charset "utf-8";

/*================================================================================
blog 一覧ページ
================================================================================ */

article {
    &#blogArchive {
        section {
            &.postListLayout {
                .postListColumn {
                    width: 100%;
                    background-color: #fff;
                    padding: 75px 0 200px;
                    @include mq(md) {
                        padding: 8.6% 0 100px;
                    }
                    .postBlogList {
                        display: block;
                        max-width: 1020px;
                        padding: 0 20px;
                        background-color: #fff;
                        width: 100%;
                        margin: 0 auto;
                        @include mq(tab) {
                            padding: 0 30px;
                        }
                        @include mq(md) {
                            padding: 0 15px;
                        }
                        ul {
                            display: flex;
                            width: 100%;
                            flex-wrap: wrap;
                            li {
                                width: 29.5918%;
                                padding-bottom: 60px;
                                margin-right: 4.0816%;
                                @include mq(tab) {
                                    width: 29.5918%;
                                    margin-right: 4.0816%;
                                }
                                @include mq(md) {
                                    width: 100%;
                                    padding-bottom: 50px;
                                    margin-right: 0;
                                }
                                &:last-child {
                                    @include mq(md) {
                                        padding-bottom: 0;
                                    }
                                }
                                &:nth-child(3n) {
                                    margin-right: 0;
                                }
                                a {
                                    display: block;
                                    width: 100%;
                                    .postThumb {
                                        display: block;
                                        max-width: 295px;
                                        margin-bottom: 30px;
                                        overflow: hidden;
                                        position: relative;
                                        @include mq(md) {
                                            max-width: none;
                                            margin-bottom: 4.065%;
                                        }
                                        &::before {
                                            content: "";
                                            display: block;
                                            padding-top: (0.623 * 100%);
                                        }
                                        > img {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            right: 0;
                                            width: 100%;
                                        }
                                    }
                                    .postTxtColumn {
                                        display: block;
                                        width: 100%;

                                        time {
                                            font-family: "Times New Roman", serif;
                                            font-size: 1.5rem;
                                            font-weight: bold;
                                            line-height: 1;
                                            letter-spacing: 1.7px;
                                            display: block;
                                            padding-bottom: 8px;
                                            @include mq(md) {
                                                padding-bottom: 15px;
                                            }
                                        }
                                        h2 {
                                            font-size: 1.8rem;
                                            font-weight: bold;
                                            line-height: 1.56;
                                            letter-spacing: 0.9px;
                                            padding-bottom: 20px;
                                            @include mq(md) {
                                                padding-bottom: 10px;
                                            }
                                        }
                                        .txtBox {
                                            font-size: 1.4rem;
                                            line-height: 1.75;
                                            letter-spacing: 1.4px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /*================================================================================
blog シングルページ
================================================================================ */
    &#blogSingle {
        section {
            &.postSingleLayout {
                .postSingleColumn {
                    width: 100%;
                    background-color: #fff;
                    padding: 75px 0 200px;
                    @include mq(md) {
                        padding: 8.6% 0 100px;
                    }
                    .postSingleWrap {
                        display: block;
                        max-width: 1020px;
                        padding: 0 20px;
                        background-color: #fff;
                        width: 100%;
                        margin: 0 auto;
                        @include mq(tab) {
                            padding: 0 30px;
                        }
                        @include mq(md) {
                            padding: 0 15px;
                        }
                        .twoColumnLayout {
                            display: flex;
                            justify-content: space-between;
                            @include mq(md) {
                                flex-wrap: wrap;
                            }
                            .postMainColumn {
                                width: 66.9387%;
                                @include mq(md) {
                                    width: 100%;
                                }
                                .titBox {
                                    display: block;
                                    position: relative;
                                    padding: 10px 0 10px 25px;
                                    margin-bottom: 35px;
                                    @include mq(tab) {
                                        padding: 10px 0 10px 25px;
                                        margin-bottom: 35px;
                                    }
                                    @include mq(md) {
                                        padding: 8px 0 8px 15px;
                                        margin-bottom: 20px;
                                    }
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        width: 8px;
                                        height: 100%;
                                        background-image: linear-gradient(to bottom, #ba000f, #ed4300 80%);
                                        top: 0;
                                        left: 0;
                                        @include mq(tab) {
                                            width: 8px;
                                        }
                                        @include mq(md) {
                                            width: 4px;
                                        }
                                    }
                                    time {
                                        font-family: "Times New Roman", serif;
                                        font-size: 2.7rem;
                                        font-weight: bold;
                                        line-height: 1.04;
                                        display: block;
                                        padding-bottom: 20px;
                                        @include mq(tab) {
                                            font-size: 2.7rem;
                                            padding-bottom: 20px;
                                        }
                                        @include mq(md) {
                                            font-size: 2rem;
                                            padding-bottom: 10px;
                                        }
                                    }
                                    h1 {
                                        font-size: 3.6rem;
                                        font-weight: bold;
                                        line-height: 1.6;
                                        @include mq(tab) {
                                            font-size: 3.6rem;
                                        }
                                        @include mq(md) {
                                            font-size: 2.2rem;
                                        }
                                    }
                                }
                                .postTxt {
                                    display: block;
                                    width: 100%;
                                    line-height: 1.75;
                                    letter-spacing: 1.6px;

                                    strong {
                                        font-weight: bold;
                                    }

                                    img {
                                        width: auto;
                                        max-height: 600px;
                                    }
                                }
                                .snsLayout {
                                    margin: 0 auto;
                                    padding-top: 40px;
                                    padding-bottom: 50px;
                                    @include mq(md) {
                                        padding-top: 10.4166%;
                                    }
                                    ul {
                                        display: flex;
                                        margin: 0 auto;
                                        width: 100%;
                                        justify-content: center;
                                        letter-spacing: -0.4em;
                                        align-items: flex-start;
                                        @include mq(md) {
                                            margin: 0 auto;
                                        }

                                        li {
                                            letter-spacing: normal;
                                            margin-right: 0;
                                            @include mq(md) {
                                                margin-bottom: 10px;
                                                margin-right: 10px;
                                            }

                                            &:last-of-type {
                                                margin-left: 1em;
                                                padding-top: 0.3em;
                                                @include mq(md) {
                                                    padding-top: 0;
                                                }
                                            }

                                            &.social_fb {
                                                //vertical-align: top;
                                            }
                                            &.social_tw {
                                                padding-top: 5px;
                                                margin-right: 0;
                                                padding-left: 20px;
                                                @include mq(md) {
                                                    padding-left: 0;
                                                    padding-top: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                                .postAroundLayout {
                                    display: flex;
                                    width: 100%;
                                    background-color: #f5f5f5; //justify-content: center;
                                    align-items: center;
                                    padding: 15px 13px;
                                    @include mq(tab) {
                                        padding: 15px 13px;
                                    }
                                    @include mq(md) {
                                        padding: 15px 8px;
                                    }
                                    .prevBox {
                                        width: 40%;
                                        text-align: left;
                                        @include mq(tab) {
                                            width: 40%;
                                        }
                                        @include mq(md) {
                                            width: 35%;
                                        }
                                        a {
                                            padding-left: 2.5em;
                                            @include mq(md) {
                                                padding-left: 2.5em;
                                            }
                                            @include mq(md) {
                                                padding-left: 2em;
                                            }
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                width: 33px;
                                                height: 21px;
                                                top: -3px;
                                                left: 0;
                                                bottom: 0;
                                                margin: auto;
                                                background-size: contain;
                                                background-image: url("/images/blog/single_page_arrow.svg");
                                                background-repeat: no-repeat;
                                                @include mq(md) {
                                                    width: 33px;
                                                    height: 21px;
                                                }
                                                @include mq(md) {
                                                    width: 20px;
                                                    height: 14px;
                                                }
                                            }
                                        }
                                    }
                                    .backBlog {
                                        width: 20%;
                                        text-align: center;
                                        @include mq(tab) {
                                            width: 20%;
                                        }
                                        @include mq(md) {
                                            width: 30%;
                                        }
                                        a {
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                width: 23px;
                                                height: 18px;
                                                background-size: contain;
                                                background-image: url("/images/blog/back_blog_icon.svg");
                                                left: 0;
                                                top: -1px;
                                                background-repeat: no-repeat;
                                                @include mq(md) {
                                                    width: 23px;
                                                    height: 18px;
                                                }
                                                @include mq(md) {
                                                    width: 14px;
                                                    right: 0;
                                                    bottom: 0;
                                                    top: 3px;
                                                    left: -4em;
                                                    margin: auto;
                                                }
                                            }
                                        }
                                    }
                                    .nextBox {
                                        width: 40%;
                                        text-align: right;
                                        @include mq(tab) {
                                            width: 40%;
                                        }
                                        @include mq(md) {
                                            width: 35%;
                                        }
                                        a {
                                            padding-right: 2.5em;
                                            @include mq(md) {
                                                padding-right: 2.5em;
                                            }
                                            @include mq(md) {
                                                padding-right: 2em;
                                            }
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                width: 33px;
                                                height: 21px;
                                                top: -3px;
                                                right: 0;
                                                bottom: 0;
                                                margin: auto;
                                                background-size: contain;
                                                background-image: url("/images/blog/single_page_arrow.svg");
                                                background-repeat: no-repeat;
                                                transform: rotate(-180deg);
                                                @include mq(md) {
                                                    width: 33px;
                                                    height: 21px;
                                                }
                                                @include mq(md) {
                                                    width: 20px;
                                                    height: 14px;
                                                }
                                            }
                                        }
                                    }
                                    a {
                                        font-size: 1.8rem;
                                        display: block;
                                        font-weight: bold;
                                        line-height: 1;
                                        letter-spacing: 1.4px;
                                        position: relative;
                                        @include mq(tab) {
                                            font-size: 1.8rem;
                                        }
                                        @include mq(md) {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }
                            }
                            aside {
                                width: 28.06122%;
                                @include mq(md) {
                                    width: 100%;
                                    padding-top: 50px;
                                }
                                h5 {
                                    display: block;
                                    padding-bottom: 10px;
                                    border-bottom: 1px solid #bbbbbb;
                                    margin-bottom: 15px;
                                    font-family: $YuMin;
                                    font-size: 2rem;
                                    font-weight: bold;
                                    line-height: 1.4;
                                    letter-spacing: 0.8px;
                                    text-align: left;
                                    color: #000;
                                }
                                ul.relationList {
                                    display: block;
                                    width: 100%;
                                    li {
                                        display: block;
                                        width: 100%;
                                        border-bottom: 1px solid #f2f0e8;
                                        margin-bottom: 15px;
                                        &:first-child {
                                            a {
                                                padding-bottom: 15px;
                                            }
                                        }
                                        a {
                                            display: flex;
                                            width: 100%;
                                            .thumb {
                                                max-width: 90px;
                                                width: 100%;
                                                .dav {
                                                    width: 100%;
                                                }
                                            }
                                            .txtBox {
                                                padding-left: 15px;
                                                padding-bottom: 15px;
                                                time {
                                                    font-family: "Times New Roman";
                                                    font-size: 1.4rem;
                                                    line-height: 1;
                                                    letter-spacing: 1.1px;
                                                    text-align: left;
                                                    color: #d90011;
                                                    display: block;
                                                    padding-bottom: 10px;
                                                }
                                                p {
                                                    font-size: 1.3rem;
                                                    line-height: 1.62;
                                                    letter-spacing: 0.7px;
                                                    color: #333333;
                                                }
                                            }
                                        }
                                    }
                                }
                                p.backLink {
                                    display: block;
                                    a {
                                        font-family: $YuMin;
                                        font-weight: bold;
                                        line-height: 1.5;
                                        letter-spacing: 1.2px;
                                        text-align: right;
                                        color: #d90011;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
