@charset "utf-8";

/* ======================================================================

基本設定

====================================================================== */

* {
    box-sizing: border-box;
}

/* レスポンシブチェック
-------------------------------------------------------------*/

#responsibleCheck {
    width: 1px;
    height: 0; // 横幅768pxまで
    @include mq(md) {
        width: 2px;
    }
}

/* PC/SPで要素を出しわけ
-------------------------------------------------------------*/

.animate {
    visibility: hidden;
}

.pcOnly {

    // 横幅768pxまで
    @include mq(md) {
        display: none;
    }
}

.spOnly {
    display: none; // 横幅768pxまで
    @include mq(md) {
        display: block;
    }
}

html {
    font-size: $mainRemFontSize; //IEだけremの挙動が変なので
    @media all and (-ms-high-contrast: none) {
        font-size: $mainRemFontSizeIE;
    }
}

body {
    font-size: 1.6rem;
    color: $basicColor;
    font-weight: normal;
    @include mq(md) {
        font-size: 1.4rem;
    }
}

.fixedBody {
    position: fixed !important;
    width: 100%;
    height: 100%;
}

main {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 90px;
    @include mq(md) {
        padding-top: 55px;
    }
    article {
        display: block;
        width: 100%;
        section {
            display: block;
            width: 100%;
        }
    }
}

a {
    color: #333;
}

button {
    outline: none;
}

/* 見出し装飾
-------------------------------------------------------------*/

/* ページ送り
-------------------------------------------------------------*/

.paginationLayout {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    @include mq(tab) {
        padding: 30px 30px 0;
    }
    @include mq(md) {
        padding-top: 70px;
        padding: 70px 15px 0;
    }
    ul {
        align-items: center;
        display: flex;
        li {
            padding-right: 15px;

            &.active {
                a {
                    background-color: #000;
                    color: #fff;
                }
            }
            &:last-child {
                padding-right: 0;
            }

            a {
                padding: 13px 14px 10px;
                display: block;
                border-radius: 50px;
                font-size: 1.8rem;
                line-height: 1;
                font-weight: 600;
                background-color: #f5f5f5;
                @include mq(tab) {
                    padding: 13px 14px 10px;
                    font-size: 1.8rem;
                }
                @include mq(md) {
                    padding: 10px 11px 7px;
                    font-size: 1.4rem;
                }
            }
        }
    }

    .next,
    .prev {
        display: inline-block;
        overflow: hidden;

        > a {
            text-indent: -999px;
            height: 28px;
            width: 58px;
            padding: 0 8px;
        }
    }

    .next {
        padding-right: 20px;
    }

    .prev {
        padding-left: 20px;
        img {
            transform: rotate(-180deg);
        }
    }
}

/* 装飾ボタン
-------------------------------------------------------------*/

.btnBoxLayout {
    display: block;
    .colorBox {
        position: relative;
        cursor: pointer;
        &.r {
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: linear-gradient(to bottom, #f21d1e, #ca0010 51%, #ba000f 52%, #bb000f 70%, #a7000d 99%);
                z-index: 2;
                transition: .5s;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: linear-gradient(to bottom, #f82a2b, #e00218 51%, #d30016 52%, #d40016 70%, #c50013 99%);
                transition: .5s;
                z-index: 1;
            }
        }
        &.b {
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: linear-gradient(to bottom, #005197, #054f8e 51%, #004784 52%, #004178 76%, #003868 99%);
                z-index: 2;
                transition: .5s;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: linear-gradient(to bottom, #0064cb, #0561c7 51%, #0055c2 52%, #004cb0 76%, #00418e 99%);
                transition: .5s;
                z-index: 1;
            }
        }
        a {
            display: block;
            width: 100%;
            position: relative;
            z-index: 3;
            img {
                margin: 0 auto;
            }
            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 12px 13px;
                border-color: transparent transparent #fff;
                right: 5px;
                bottom: 5px;
            }
        }
        &.uaHover {
            &:hover {
                &::before {
                    opacity: 0;
                    transition: .5s;
                }
            }
        }
    }
}

/* パララックス効果
-------------------------------------------------------------*/

.pararaLayout {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 90px;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: cover;
    @include mq(md) {
        background-size: cover;
        top: 0;

    }
    &.blog {
        background-image: url('/images/blog/parara_blog.jpg');
        @include mq(md) {
            background-position: center right;
        }
    }
    &.about {
        background-image: url('/images/about/parara_about.jpg');
        @include mq(md) {
            background-position: center bottom;
        }
    }
    &.policy {
        background-image: url('/images/policy/parara_policy.jpg');
        
    }
    &.trainer {
        background-image: url('/images/trainer/parara_trainer.jpg');
    }
    &.error {
        background-image: url('/images/common/parara_404.jpg');
    }
}

.topKeyword {
    display: flex;
    height: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mq(md) {
        height: auto;
        padding: 12% 0 16%;
    }
    h1 {
        display: block;
        width: 100%;
        img {
            margin: 0 auto;
        }
    }
    .tag {
        img {
            margin: 0 auto;
        }
    }
}

/* パン屑リスト
-------------------------------------------------------------*/

.breadcrumb {
    padding: 0 0 30px;
    clear: both; // 横幅768pxまで
    @include mq(md) {
        padding: 0 20px;
    }

    ol {
        @include clearfix;
        padding: 0 20px 0;
        font-size: 1.2rem;
        word-break: break-all;
        max-width: 1020px;
        margin: 0 auto;
        @include mq(md) {
            padding: 10px 0;
            max-width: initial;
        }

        & > li {
            display: inline;
            line-height: 1.7;
            letter-spacing: 1.2px;
            a {
                color: #a17848;
                letter-spacing: 0.1px;
            }

            & + li:before {
                content: '>';
                padding: 0 7px 0 5px;
            }
        }
    }
}