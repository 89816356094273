@charset "UTF-8";
/*================================================================================
値の定義
================================================================================ */
/* ================================================================================
importファイル
================================================================================ */
/* btnBace使用例：@extend %btnBace;
-------------------------------------------------------------*/
/* ======================================================================

リセット

====================================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline; }

html {
  text-size-adjust: 100%; }

body {
  *font: x-small;
  color: #333333;
  font-size: 100%;
  font-family: "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500; }
  @media screen and (max-width: 768px) {
    body {
      font-size: 100%; } }

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

li {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent; }
  a:link {
    color: #000;
    outline: none; }
  a:visited {
    color: #000;
    outline: none; }
  a:hover {
    text-decoration: none;
    outline: none; }
  a:focus {
    text-decoration: none; }

ins {
  color: #000;
  background: #ff9;
  text-decoration: none; }

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help; }

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse; }
  table th,
  table td {
    border-collapse: collapse;
    border-spacing: 0; }

caption,
th {
  text-align: left; }

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999; }

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom; }

input,
select {
  vertical-align: middle; }

button,
select {
  text-transform: none; }

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: ''; }

select::-ms-expand {
  display: none; }

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial; }

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none; }

label {
  font-weight: normal; }

audio:not([controls]) {
  display: none;
  height: 0; }

input {
  padding: 0; }
  input::-ms-clear, input::-ms-reveal {
    visibility: hidden; }

[type='checkbox'],
[type='radio'] {
  padding: 0; }

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1; }

:-moz-placeholder {
  color: #ccc; }

::-moz-placeholder {
  color: #ccc;
  opacity: 1; }

:-ms-input-placeholder {
  color: #ccc; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

[aria-busy='true'] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit; }

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

[hidden][aria-hidden='false']:focus {
  clip: auto; }

strong {
  font-weight: bold; }

/* ================================================================================

印刷時の定義

================================================================================ */
＠media print section {
  display: block;
  page-break-before: always; }

＠media print pre,
＠media print code {
  page-break-after: always; }

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm; } }

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word; }

＠media print pre a[href]:after {
  content: ""; }

＠media print pre abbr[title]:after {
  content: ""; }

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: ''; }

＠media print .noPrint {
  display: none !important; }

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact; }
  ＠media print body .continer {
    padding-top: 0; }

/* ======================================================================

基本設定

====================================================================== */
* {
  box-sizing: border-box; }

/* レスポンシブチェック
-------------------------------------------------------------*/
#responsibleCheck {
  width: 1px;
  height: 0; }
  @media screen and (max-width: 768px) {
    #responsibleCheck {
      width: 2px; } }

/* PC/SPで要素を出しわけ
-------------------------------------------------------------*/
.animate {
  visibility: hidden; }

@media screen and (max-width: 768px) {
  .pcOnly {
    display: none; } }

.spOnly {
  display: none; }
  @media screen and (max-width: 768px) {
    .spOnly {
      display: block; } }

html {
  font-size: 62.5%; }
  @media all and (-ms-high-contrast: none) {
    html {
      font-size: 47.5%; } }

body {
  font-size: 1.6rem;
  color: #333333;
  font-weight: normal; }
  @media screen and (max-width: 768px) {
    body {
      font-size: 1.4rem; } }

.fixedBody {
  position: fixed !important;
  width: 100%;
  height: 100%; }

main {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 90px; }
  @media screen and (max-width: 768px) {
    main {
      padding-top: 55px; } }
  main article {
    display: block;
    width: 100%; }
    main article section {
      display: block;
      width: 100%; }

a {
  color: #333; }

button {
  outline: none; }

/* 見出し装飾
-------------------------------------------------------------*/
/* ページ送り
-------------------------------------------------------------*/
.paginationLayout {
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px; }
  @media screen and (min-width: 640px) and (max-width: 768px) {
    .paginationLayout {
      padding: 30px 30px 0; } }
  @media screen and (max-width: 768px) {
    .paginationLayout {
      padding-top: 70px;
      padding: 70px 15px 0; } }
  .paginationLayout ul {
    align-items: center;
    display: flex; }
    .paginationLayout ul li {
      padding-right: 15px; }
      .paginationLayout ul li.active a {
        background-color: #000;
        color: #fff; }
      .paginationLayout ul li:last-child {
        padding-right: 0; }
      .paginationLayout ul li a {
        padding: 13px 14px 10px;
        display: block;
        border-radius: 50px;
        font-size: 1.8rem;
        line-height: 1;
        font-weight: 600;
        background-color: #f5f5f5; }
        @media screen and (min-width: 640px) and (max-width: 768px) {
          .paginationLayout ul li a {
            padding: 13px 14px 10px;
            font-size: 1.8rem; } }
        @media screen and (max-width: 768px) {
          .paginationLayout ul li a {
            padding: 10px 11px 7px;
            font-size: 1.4rem; } }
  .paginationLayout .next,
  .paginationLayout .prev {
    display: inline-block;
    overflow: hidden; }
    .paginationLayout .next > a,
    .paginationLayout .prev > a {
      text-indent: -999px;
      height: 28px;
      width: 58px;
      padding: 0 8px; }
  .paginationLayout .next {
    padding-right: 20px; }
  .paginationLayout .prev {
    padding-left: 20px; }
    .paginationLayout .prev img {
      transform: rotate(-180deg); }

/* 装飾ボタン
-------------------------------------------------------------*/
.btnBoxLayout {
  display: block; }
  .btnBoxLayout .colorBox {
    position: relative;
    cursor: pointer; }
    .btnBoxLayout .colorBox.r::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, #f21d1e, #ca0010 51%, #ba000f 52%, #bb000f 70%, #a7000d 99%);
      z-index: 2;
      transition: .5s; }
    .btnBoxLayout .colorBox.r::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, #f82a2b, #e00218 51%, #d30016 52%, #d40016 70%, #c50013 99%);
      transition: .5s;
      z-index: 1; }
    .btnBoxLayout .colorBox.b::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, #005197, #054f8e 51%, #004784 52%, #004178 76%, #003868 99%);
      z-index: 2;
      transition: .5s; }
    .btnBoxLayout .colorBox.b::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, #0064cb, #0561c7 51%, #0055c2 52%, #004cb0 76%, #00418e 99%);
      transition: .5s;
      z-index: 1; }
    .btnBoxLayout .colorBox a {
      display: block;
      width: 100%;
      position: relative;
      z-index: 3; }
      .btnBoxLayout .colorBox a img {
        margin: 0 auto; }
      .btnBoxLayout .colorBox a::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 12px 13px;
        border-color: transparent transparent #fff;
        right: 5px;
        bottom: 5px; }
    .btnBoxLayout .colorBox.uaHover:hover::before {
      opacity: 0;
      transition: .5s; }

/* パララックス効果
-------------------------------------------------------------*/
.pararaLayout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover; }
  @media screen and (max-width: 768px) {
    .pararaLayout {
      background-size: cover;
      top: 0; } }
  .pararaLayout.blog {
    background-image: url("/images/blog/parara_blog.jpg"); }
    @media screen and (max-width: 768px) {
      .pararaLayout.blog {
        background-position: center right; } }
  .pararaLayout.about {
    background-image: url("/images/about/parara_about.jpg"); }
    @media screen and (max-width: 768px) {
      .pararaLayout.about {
        background-position: center bottom; } }
  .pararaLayout.policy {
    background-image: url("/images/policy/parara_policy.jpg"); }
  .pararaLayout.trainer {
    background-image: url("/images/trainer/parara_trainer.jpg"); }
  .pararaLayout.error {
    background-image: url("/images/common/parara_404.jpg"); }

.topKeyword {
  display: flex;
  height: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .topKeyword {
      height: auto;
      padding: 12% 0 16%; } }
  .topKeyword h1 {
    display: block;
    width: 100%; }
    .topKeyword h1 img {
      margin: 0 auto; }
  .topKeyword .tag img {
    margin: 0 auto; }

/* パン屑リスト
-------------------------------------------------------------*/
.breadcrumb {
  padding: 0 0 30px;
  clear: both; }
  @media screen and (max-width: 768px) {
    .breadcrumb {
      padding: 0 20px; } }
  .breadcrumb ol {
    padding: 0 20px 0;
    font-size: 1.2rem;
    word-break: break-all;
    max-width: 1020px;
    margin: 0 auto; }
    .breadcrumb ol:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (max-width: 768px) {
      .breadcrumb ol {
        padding: 10px 0;
        max-width: initial; } }
    .breadcrumb ol > li {
      display: inline;
      line-height: 1.7;
      letter-spacing: 1.2px; }
      .breadcrumb ol > li a {
        color: #a17848;
        letter-spacing: 0.1px; }
      .breadcrumb ol > li + li:before {
        content: '>';
        padding: 0 7px 0 5px; }

/* ================================================================================
ヘッダー
================================================================================ */
header#header {
  display: block;
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 0 25px;
  z-index: 999;
  position: fixed;
  top: 0; }
  header#header .headerColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 90px; }
    @media screen and (max-width: 768px) {
      header#header .headerColumn {
        height: 55px; } }
    header#header .headerColumn a {
      display: block; }
    header#header .headerColumn .logo {
      position: absolute;
      left: 25px;
      top: 0;
      padding: 24px 20px;
      height: 90px;
      max-width: 154px;
      background-color: #fff;
      z-index: 5; }
      @media screen and (max-width: 768px) {
        header#header .headerColumn .logo {
          width: 120px;
          padding: 11px 10px;
          height: 55px;
          left: 12px; } }
      header#header .headerColumn .logo img {
        width: 154px; }
        @media screen and (max-width: 768px) {
          header#header .headerColumn .logo img {
            width: 95px;
            margin: 0 auto; } }
    header#header .headerColumn .telBox {
      display: flex;
      align-items: flex-start;
      margin-right: 160px; }
      @media screen and (max-width: 768px) {
        header#header .headerColumn .telBox {
          display: none; } }
      header#header .headerColumn .telBox .timeBox {
        display: block;
        padding-left: 10px; }
        header#header .headerColumn .telBox .timeBox .txt {
          font-size: 1.2rem;
          letter-spacing: 0.6px;
          width: 105px;
          background-color: #fff;
          line-height: 1;
          padding: 2px 10px;
          color: #000;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 5px; }
        header#header .headerColumn .telBox .timeBox p.time {
          font-size: 1.2rem;
          letter-spacing: 0.6px; }
    header#header .headerColumn .spNavBtn {
      display: flex;
      color: #fff;
      position: absolute;
      right: 15px;
      width: 137px;
      height: 48px;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 1px solid #fff;
      align-items: center;
      justify-content: space-between;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        header#header .headerColumn .spNavBtn {
          border: 0;
          width: 60px; } }
      header#header .headerColumn .spNavBtn p {
        font-family: "Helvetica Neue", Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
        font-size: 1.8rem;
        letter-spacing: 3.6px;
        color: #ffffff;
        line-height: 1;
        padding-right: 16px;
        font-weight: 500; }
        @media screen and (max-width: 768px) {
          header#header .headerColumn .spNavBtn p {
            display: none; } }
      header#header .headerColumn .spNavBtn .menu-trigger {
        display: inline-block;
        transition: all 0.4s;
        box-sizing: border-box;
        position: relative;
        width: 25px;
        height: 19px;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto; }
        @media screen and (max-width: 768px) {
          header#header .headerColumn .spNavBtn .menu-trigger {
            width: 32px;
            height: 24px; } }
        header#header .headerColumn .spNavBtn .menu-trigger span {
          display: inline-block;
          transition: 0.4s;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #fff; }
          @media screen and (max-width: 768px) {
            header#header .headerColumn .spNavBtn .menu-trigger span {
              height: 2px; } }
          header#header .headerColumn .spNavBtn .menu-trigger span:nth-of-type(1) {
            top: 0;
            transform-origin: top right; }
          header#header .headerColumn .spNavBtn .menu-trigger span:nth-of-type(2) {
            top: calc(50% - 1px); }
          header#header .headerColumn .spNavBtn .menu-trigger span:nth-of-type(3) {
            bottom: 0;
            transform-origin: bottom right; }
      header#header .headerColumn .spNavBtn .menu-trigger.active span:nth-of-type(1) {
        transform: rotate(-45deg); }
      header#header .headerColumn .spNavBtn .menu-trigger.active span:nth-of-type(2) {
        opacity: 0;
        transform: translateX(80%); }
      header#header .headerColumn .spNavBtn .menu-trigger.active span:nth-of-type(3) {
        transform: rotate(45deg); }
  header#header #globalNav {
    display: none;
    position: absolute;
    top: 89px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000; }
    @media screen and (max-width: 768px) {
      header#header #globalNav {
        top: 55px; } }
    header#header #globalNav ul {
      width: 100%;
      height: 100%;
      max-height: 760px;
      padding: 35px 0 100px;
      overflow-y: auto;
      -ms-overflow-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-around; }
      @media screen and (max-width: 320px) {
        header#header #globalNav ul {
          padding: 24px 0 90px; } }
      header#header #globalNav ul::-webkit-scrollbar {
        display: none; }
      header#header #globalNav ul li {
        text-align: center;
        position: relative; }
        header#header #globalNav ul li.current::before {
          content: "";
          position: absolute;
          width: 52.734375%;
          max-width: 240px;
          padding-top: 5.46875%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          background-image: url("/images/common/menu_bg_current.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          z-index: 0; }
          @media screen and (max-width: 768px) {
            header#header #globalNav ul li.current::before {
              max-width: none;
              width: 52.734375%; } }
        header#header #globalNav ul li a {
          position: relative;
          z-index: 2;
          display: inline-block;
          color: #fff;
          font-size: 2.4rem;
          font-weight: bold;
          line-height: 1;
          min-height: 40px;
          padding: 0.6em 1em; }

/* ================================================================================
フッター
================================================================================ */
footer#footer {
  display: block;
  width: 100%;
  background-color: #000;
  padding: 60px 0 50px 0;
  position: relative; }
  @media screen and (max-width: 768px) {
    footer#footer {
      padding: 0; } }
  footer#footer .footerContentLayout {
    width: 100%;
    display: flex;
    max-width: 1020px;
    padding: 0 20px 90px;
    color: #fff;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      footer#footer .footerContentLayout {
        flex-wrap: wrap;
        padding: 32px 0 0; } }
    footer#footer .footerContentLayout a {
      color: #fff; }
    footer#footer .footerContentLayout .logoColumn {
      display: block;
      max-width: 255px;
      width: 26.0204%; }
      @media screen and (max-width: 768px) {
        footer#footer .footerContentLayout .logoColumn {
          width: 100%;
          max-width: none;
          margin-top: 40px;
          order: 2;
          background: #4f5158; } }
      footer#footer .footerContentLayout .logoColumn .fLogo {
        background-color: #fff;
        max-width: 193px;
        padding: 20px 15px;
        position: relative;
        margin-bottom: 20px;
        z-index: 10; }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .logoColumn .fLogo {
            margin: 0 auto;
            width: 50.390625%;
            max-width: none; } }
        footer#footer .footerContentLayout .logoColumn .fLogo img {
          width: 152px;
          margin: 0 auto; }
          @media screen and (max-width: 768px) {
            footer#footer .footerContentLayout .logoColumn .fLogo img {
              width: 78.5529%; } }
      footer#footer .footerContentLayout .logoColumn .logoColumnSns {
        display: flex;
        align-items: center; }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .logoColumn .logoColumnSns {
            justify-content: center;
            background: rgba(0, 0, 0, 0.15);
            margin: 16px 40px 0;
            padding: 4px 16px; } }
        footer#footer .footerContentLayout .logoColumn .logoColumnSns > p {
          font-family: "Helvetica Neue", Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
          font-weight: bold;
          margin-right: 12px;
          font-size: 1.4rem;
          text-transform: uppercase;
          color: #999; }
          @media screen and (max-width: 768px) {
            footer#footer .footerContentLayout .logoColumn .logoColumnSns > p {
              margin-right: 16px; } }
      footer#footer .footerContentLayout .logoColumn ul {
        display: flex; }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .logoColumn ul {
            justify-content: center;
            padding: 8.71875% 0 7.8125%; } }
        footer#footer .footerContentLayout .logoColumn ul li a {
          display: block; }
        footer#footer .footerContentLayout .logoColumn ul li:last-child {
          padding-left: 15px; }
    footer#footer .footerContentLayout .outLinkColumn {
      display: block;
      width: 26.5102%; }
      @media screen and (max-width: 768px) {
        footer#footer .footerContentLayout .outLinkColumn {
          width: 100%;
          order: 3;
          padding-top: 24px;
          background: #4f5158; } }
      footer#footer .footerContentLayout .outLinkColumn ul {
        display: flex;
        padding-bottom: 20px; }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .outLinkColumn ul {
            display: block; } }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .outLinkColumn ul li {
            width: 100%;
            text-align: center; } }
        footer#footer .footerContentLayout .outLinkColumn ul li a {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.31;
          letter-spacing: 1.6px;
          vertical-align: middle; }
          footer#footer .footerContentLayout .outLinkColumn ul li a::before {
            content: "";
            width: 8px;
            height: 0;
            border-style: solid;
            border-width: 4px 0 4px 4px;
            border-color: transparent transparent transparent #f21d1e;
            display: inline-block; }
      footer#footer .footerContentLayout .outLinkColumn .linkBox {
        display: block; }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .outLinkColumn .linkBox {
            padding-bottom: 9.11458%; } }
        footer#footer .footerContentLayout .outLinkColumn .linkBox a {
          display: block; }
          footer#footer .footerContentLayout .outLinkColumn .linkBox a img {
            width: 94.8207%; }
            @media screen and (max-width: 768px) {
              footer#footer .footerContentLayout .outLinkColumn .linkBox a img {
                width: 59.8958%;
                margin: 0 auto; } }
    footer#footer .footerContentLayout .telColumn {
      display: block;
      max-width: 400px;
      width: 40.8163%;
      margin-left: auto; }
      @media screen and (max-width: 768px) {
        footer#footer .footerContentLayout .telColumn {
          max-width: none;
          width: 100%;
          margin: 0 auto;
          order: 1; } }
      footer#footer .footerContentLayout .telColumn .telBox {
        display: flex;
        align-items: flex-start; }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .telColumn .telBox {
            flex-wrap: wrap; } }
        footer#footer .footerContentLayout .telColumn .telBox a {
          width: 70.5%; }
          @media screen and (max-width: 768px) {
            footer#footer .footerContentLayout .telColumn .telBox a {
              width: 100%;
              padding-bottom: 10px; }
              footer#footer .footerContentLayout .telColumn .telBox a img {
                margin: 0 auto; } }
        footer#footer .footerContentLayout .telColumn .telBox .timeBox {
          display: block;
          padding-left: 10px; }
          @media screen and (max-width: 768px) {
            footer#footer .footerContentLayout .telColumn .telBox .timeBox {
              padding: 0;
              width: 100%;
              text-align: center; } }
          footer#footer .footerContentLayout .telColumn .telBox .timeBox .txt {
            font-size: 1.2rem;
            letter-spacing: 0.6px;
            width: 105px;
            background-color: #fff;
            line-height: 1;
            padding: 2px 10px;
            color: #000;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 5px; }
            @media screen and (max-width: 768px) {
              footer#footer .footerContentLayout .telColumn .telBox .timeBox .txt {
                display: inline-block; } }
          footer#footer .footerContentLayout .telColumn .telBox .timeBox p.time {
            font-size: 1.2rem;
            letter-spacing: 0.6px; }
            @media screen and (max-width: 768px) {
              footer#footer .footerContentLayout .telColumn .telBox .timeBox p.time {
                display: inline-block; } }
      footer#footer .footerContentLayout .telColumn .btnBoxLayout {
        padding-top: 15px; }
        @media screen and (max-width: 768px) {
          footer#footer .footerContentLayout .telColumn .btnBoxLayout {
            padding-top: 16px; } }
        footer#footer .footerContentLayout .telColumn .btnBoxLayout .colorBox {
          margin: 0 auto; }
          @media screen and (max-width: 768px) {
            footer#footer .footerContentLayout .telColumn .btnBoxLayout .colorBox {
              max-width: 704px;
              width: 91.6666%; } }
          footer#footer .footerContentLayout .telColumn .btnBoxLayout .colorBox a {
            padding: 26px 0; }
            footer#footer .footerContentLayout .telColumn .btnBoxLayout .colorBox a img {
              max-width: 239px; }
              @media screen and (max-width: 768px) {
                footer#footer .footerContentLayout .telColumn .btnBoxLayout .colorBox a img {
                  max-width: none;
                  width: 67.6136%; } }
  footer#footer .smallLayout {
    text-align: center;
    display: block;
    width: 100%;
    color: #fff; }
    footer#footer .smallLayout ul {
      display: flex;
      padding-bottom: 20px;
      margin: 0 auto;
      max-width: 500px;
      justify-content: center; }
      @media screen and (max-width: 768px) {
        footer#footer .smallLayout ul {
          background: #4f5158; } }
      @media screen and (max-width: 768px) {
        footer#footer .smallLayout ul li {
          text-align: center; } }
      footer#footer .smallLayout ul li a {
        font-size: 1.4rem;
        color: #fff;
        line-height: 1.31;
        letter-spacing: 1.6px; }
      footer#footer .smallLayout ul li:last-child {
        padding-left: 40px; }
    footer#footer .smallLayout small {
      font-family: "Helvetica Neue", Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
      display: block;
      font-size: 1.4rem;
      line-height: 1.5;
      letter-spacing: 1.4px; }
      @media screen and (max-width: 768px) {
        footer#footer .smallLayout small {
          font-size: 1.1rem;
          padding: 16px 5px 8px; } }
    footer#footer .smallLayout p {
      font-size: 1rem;
      line-height: 1.75;
      letter-spacing: 1.2px;
      padding: 0 5px 16px; }

/* ================================================================================
トップページ
================================================================================ */
article#idx section.keyVisualLayout {
  position: relative;
  z-index: 0;
  margin: 0 auto;
  padding-top: 62.65625%; }
  @media screen and (max-width: 768px) {
    article#idx section.keyVisualLayout {
      padding-top: 142.4479%; } }
  article#idx section.keyVisualLayout::before {
    content: "";
    position: absolute;
    background-image: url("/images/index/key_visual.jpg");
    z-index: 2;
    background-size: cover;
    background-position: center top;
    left: 0;
    padding-top: 62.65625%;
    width: 100%;
    top: 0;
    background-repeat: no-repeat; }
    @media screen and (max-width: 768px) {
      article#idx section.keyVisualLayout::before {
        background-image: url("/images/index/sp_key_visual.jpg");
        padding-top: 142.4479%; } }
  article#idx section.keyVisualLayout .hTag {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    padding-left: 10.9375%;
    top: 3.4375%; }
    @media screen and (max-width: 768px) {
      article#idx section.keyVisualLayout .hTag {
        padding-left: 0; } }
    article#idx section.keyVisualLayout .hTag h1 {
      position: relative;
      z-index: 3;
      left: 0; }
      article#idx section.keyVisualLayout .hTag h1 img {
        width: 69.2982%;
        max-width: 810px; }
        @media screen and (max-width: 768px) {
          article#idx section.keyVisualLayout .hTag h1 img {
            width: 77.4739%; } }
  article#idx section.keyVisualLayout .bottomColumn {
    position: absolute;
    width: 100%;
    z-index: 3;
    margin: auto;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 1280px; }
    article#idx section.keyVisualLayout .bottomColumn h2 {
      overflow: hidden;
      position: relative; }
      article#idx section.keyVisualLayout .bottomColumn h2 img {
        position: relative;
        left: 3%;
        margin: 0 auto;
        width: 100%; }
        @media screen and (max-width: 768px) {
          article#idx section.keyVisualLayout .bottomColumn h2 img {
            left: 0; } }
    article#idx section.keyVisualLayout .bottomColumn .btnColumn {
      position: absolute;
      bottom: -3%;
      width: 100%; }
      @media screen and (max-width: 768px) {
        article#idx section.keyVisualLayout .bottomColumn .btnColumn {
          display: none; } }
      article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout {
        display: flex;
        width: 100%; }
        article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox {
          width: 50%;
          position: relative;
          cursor: pointer; }
          article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox .r {
            display: block;
            max-width: 487px;
            width: 76.09375%;
            margin-left: auto;
            position: relative; }
            article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox .r::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-image: linear-gradient(to bottom, #f21d1e, #ca0010 51%, #ba000f 52%, #bb000f 70%, #a7000d 99%);
              z-index: 2;
              transition: 0.5s;
              backface-visibility: hidden; }
            article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox .r::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-image: linear-gradient(to bottom, #f82a2b, #e00218 51%, #d30016 52%, #d40016 70%, #c50013 99%);
              transition: 0.5s;
              z-index: 1;
              backface-visibility: hidden; }
          article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox .b {
            display: block;
            width: 76.09375%;
            max-width: 487px;
            margin-right: auto;
            position: relative; }
            article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox .b::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-image: linear-gradient(to bottom, #005197, #054f8e 51%, #004784 52%, #004178 76%, #003868 99%);
              z-index: 2;
              transition: 0.5s;
              backface-visibility: hidden; }
            article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox .b::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-image: linear-gradient(to bottom, #0064cb, #0561c7 51%, #0055c2 52%, #004cb0 76%, #00418e 99%);
              transition: 0.5s;
              z-index: 1;
              backface-visibility: hidden; }
          article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox a {
            width: 100%;
            position: relative;
            display: block; }
            article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox a::after {
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 0 12px 13px;
              border-color: transparent transparent #fff;
              right: 5px;
              bottom: 5px;
              z-index: 10; }
            article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox a img {
              position: relative;
              z-index: 3;
              margin: 0 auto;
              backface-visibility: hidden; }
          article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox.uaHover:hover .r::before,
          article#idx section.keyVisualLayout .bottomColumn .btnColumn .btnLayout .btnBox.uaHover:hover .b::before {
            opacity: 0;
            transition: 0.5s;
            backface-visibility: hidden; }
  article#idx section.keyVisualLayout .spBtnLayout {
    display: none; }
    @media screen and (max-width: 768px) {
      article#idx section.keyVisualLayout .spBtnLayout {
        display: flex;
        width: 100%;
        position: absolute;
        padding-top: 17.1875%; } }
    article#idx section.keyVisualLayout .spBtnLayout a {
      width: 50%;
      position: absolute;
      top: 0; }
      article#idx section.keyVisualLayout .spBtnLayout a:first-child {
        left: 0; }
      article#idx section.keyVisualLayout .spBtnLayout a:last-child {
        right: 0; }

article#idx section.topReadLayout {
  padding-bottom: 100px;
  margin-top: -45px; }
  @media screen and (max-width: 768px) {
    article#idx section.topReadLayout {
      padding-bottom: 20.4427%;
      padding-top: 17.1875%;
      margin-top: 0; } }
  article#idx section.topReadLayout h2.top {
    display: block;
    padding-bottom: 60px; }
    article#idx section.topReadLayout h2.top img {
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        article#idx section.topReadLayout h2.top img {
          width: 91.796875%;
          max-width: 705px; } }
  article#idx section.topReadLayout .readColumnLayout {
    display: block;
    background-image: url("/images/index/readColumnLayout.png");
    background-size: contain;
    background-repeat: no-repeat; }
    @media screen and (max-width: 768px) {
      article#idx section.topReadLayout .readColumnLayout {
        background-image: url("/images/index/sp_readColumnLayout.jpg"); } }
    article#idx section.topReadLayout .readColumnLayout .detailColumnBox {
      display: table;
      width: 100%;
      min-height: 400px;
      margin: 0 auto;
      position: relative;
      align-items: center;
      justify-content: center;
      max-width: 1280px;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        article#idx section.topReadLayout .readColumnLayout .detailColumnBox {
          display: block;
          min-height: inherit; } }
      article#idx section.topReadLayout .readColumnLayout .detailColumnBox .detailColumnRow {
        display: table-row; }
        @media screen and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox .detailColumnRow {
            display: flex;
            width: 100%;
            flex-wrap: wrap; } }
      article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap {
        width: 50%;
        padding-left: 7.8125%;
        padding-right: 3.125%;
        padding-bottom: 5.8125%;
        display: table-cell;
        vertical-align: middle; }
        @media screen and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap {
            display: block;
            width: 100%;
            padding: 0;
            order: 2; } }
        article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox {
          max-width: 500px;
          margin-left: auto; }
          @media screen and (max-width: 768px) {
            article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox {
              max-width: none;
              padding: 8.4635% 0 13.671875%; } }
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox h3 {
            font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
            font-size: 4.2rem;
            font-weight: bold;
            line-height: 1.62;
            letter-spacing: -0.05em;
            padding-bottom: 10px; }
            @media screen and (max-width: 768px) {
              article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox h3 {
                text-align: center;
                font-size: 2.4rem; } }
            article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox h3 span {
              color: #d90011; }
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox p {
            font-size: 1.8rem;
            letter-spacing: 1px;
            margin-left: 1em;
            line-height: 2.33; }
            @media screen and (min-width: 640px) and (max-width: 768px) {
              article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox p {
                padding: 0 30px; } }
            @media screen and (max-width: 768px) {
              article#idx section.topReadLayout .readColumnLayout .detailColumnBox .txtBoxWrap .txtBox p {
                margin: 0;
                padding: 0 32px;
                font-size: 1.5rem;
                line-height: 1.8; } }
      article#idx section.topReadLayout .readColumnLayout .detailColumnBox .detailImg {
        width: 50%;
        display: table-cell;
        position: relative;
        overflow: hidden; }
        @media screen and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox .detailImg {
            display: block;
            width: 100%;
            padding-top: 68.4895%;
            order: 1; } }
        @media all and (-ms-high-contrast: none) {
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox .detailImg {
            background-image: url("/images/index/img_f.jpg");
            background-position: center center; } }
        article#idx section.topReadLayout .readColumnLayout .detailColumnBox .detailImg img {
          position: absolute;
          display: block;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto; }
          @media all and (-ms-high-contrast: none) {
            article#idx section.topReadLayout .readColumnLayout .detailColumnBox .detailImg img {
              opacity: 0; } }
      article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last {
        flex-direction: row-reverse; }
        article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap {
          padding: 25px 0 0 3.125%; }
          @media screen and (max-width: 768px) {
            article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap {
              padding: 0; } }
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap .txtBox {
            margin: 0 auto 0 0;
            max-width: none; }
            @media screen and (max-width: 768px) {
              article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap .txtBox {
                margin: 0;
                padding-bottom: 0; } }
            article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap .txtBox h3 {
              letter-spacing: -0.12em; }
              @media screen and (max-width: 768px) {
                article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap .txtBox h3 {
                  letter-spacing: -0.15em;
                  font-size: 2.4rem; } }
            article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap .txtBox p {
              padding-right: 26.6666%; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap .txtBox p {
                  padding: 0 30px; } }
              @media screen and (max-width: 768px) {
                article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .txtBoxWrap .txtBox p {
                  padding: 0 32px; } }
        @media all and (-ms-high-contrast: none) {
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .detailImg {
            background-image: url("/images/index/img_l.jpg"); } }
        @media all and (-ms-high-contrast: none) {
          article#idx section.topReadLayout .readColumnLayout .detailColumnBox.last .detailImg img {
            opacity: 0; } }
    article#idx section.topReadLayout .readColumnLayout .concept {
      width: 100%;
      max-width: 1020px;
      padding: 100px 20px 0;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        article#idx section.topReadLayout .readColumnLayout .concept {
          padding: 15.625% 0 0; } }
      article#idx section.topReadLayout .readColumnLayout .concept h2 {
        font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
        font-size: 4.2rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -0.05em;
        text-align: center;
        padding-bottom: 40px; }
        @media screen and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .concept h2 {
            font-size: 2.9rem;
            padding-bottom: 24px;
            line-height: 1.3; } }
        article#idx section.topReadLayout .readColumnLayout .concept h2 span {
          color: #d90011; }
      article#idx section.topReadLayout .readColumnLayout .concept .conceptPhoto {
        padding-bottom: 50px; }
        @media screen and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .concept .conceptPhoto {
            padding: 0 0 24px; } }
      article#idx section.topReadLayout .readColumnLayout .concept p.conceptTxt {
        font-size: 1.8rem;
        line-height: 2.33;
        letter-spacing: 0.5px; }
        @media screen and (min-width: 640px) and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .concept p.conceptTxt {
            padding: 0 30px; } }
        @media screen and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .concept p.conceptTxt {
            padding: 0 16px;
            font-size: 1.6rem;
            line-height: 1.8; } }
      article#idx section.topReadLayout .readColumnLayout .concept .detailBox {
        display: block;
        font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
        font-weight: bold;
        padding-top: 80px; }
        @media screen and (min-width: 640px) and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .concept .detailBox {
            padding: 16.0156% 30px 0; } }
        @media screen and (max-width: 768px) {
          article#idx section.topReadLayout .readColumnLayout .concept .detailBox {
            padding: 16.0156% 15px 0; } }
        article#idx section.topReadLayout .readColumnLayout .concept .detailBox img {
          margin: 0 auto;
          width: 60px; }
          @media screen and (max-width: 768px) {
            article#idx section.topReadLayout .readColumnLayout .concept .detailBox img {
              width: 30px; } }
        article#idx section.topReadLayout .readColumnLayout .concept .detailBox h4 {
          text-align: center;
          color: #d90011;
          font-size: 2.4rem; }
          @media screen and (max-width: 768px) {
            article#idx section.topReadLayout .readColumnLayout .concept .detailBox h4 {
              font-feature-settings: "palt";
              font-size: 1.5rem;
              line-height: 1.3;
              margin-bottom: 0.5em; } }
          article#idx section.topReadLayout .readColumnLayout .concept .detailBox h4 span {
            font-size: 3.6rem; }
            @media screen and (max-width: 768px) {
              article#idx section.topReadLayout .readColumnLayout .concept .detailBox h4 span {
                display: block; } }
            article#idx section.topReadLayout .readColumnLayout .concept .detailBox h4 span:last-child {
              font-size: 2.4rem;
              color: #000;
              display: inline-block; }
              @media screen and (max-width: 768px) {
                article#idx section.topReadLayout .readColumnLayout .concept .detailBox h4 span:last-child {
                  font-size: 1.9rem; } }
        article#idx section.topReadLayout .readColumnLayout .concept .detailBox p.topTxt {
          text-align: center;
          font-size: 2.4rem;
          padding-bottom: 55px; }
          @media screen and (max-width: 768px) {
            article#idx section.topReadLayout .readColumnLayout .concept .detailBox p.topTxt {
              padding-bottom: 24px;
              font-size: 1.9rem; } }
        article#idx section.topReadLayout .readColumnLayout .concept .detailBox p.addTxt {
          font-size: 1.8rem;
          line-height: 1.5;
          letter-spacing: 1px;
          max-width: 950px; }
          @media screen and (max-width: 768px) {
            article#idx section.topReadLayout .readColumnLayout .concept .detailBox p.addTxt {
              font-size: 1.5rem;
              line-height: 1.8; } }

article#idx section.fullSupportLayout {
  background-image: url("/images/index/support_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 20px 150px; }
  @media screen and (min-width: 640px) and (max-width: 768px) {
    article#idx section.fullSupportLayout {
      background-size: contain;
      background-color: #f2f0e8;
      padding: 0 30px 16.927%; } }
  @media screen and (max-width: 768px) {
    article#idx section.fullSupportLayout {
      background-size: contain;
      background-color: #f2f0e8;
      padding-bottom: 16.927%; } }
  article#idx section.fullSupportLayout h2 img,
  article#idx section.fullSupportLayout h3 img {
    margin: 0 auto; }
  article#idx section.fullSupportLayout h3 {
    margin-top: -4.5em;
    padding-bottom: 60px; }
    @media screen and (max-width: 768px) {
      article#idx section.fullSupportLayout h3 {
        padding-bottom: 10.8401%; } }
    article#idx section.fullSupportLayout h3 img {
      max-width: 388px; }
      @media screen and (max-width: 768px) {
        article#idx section.fullSupportLayout h3 img {
          max-width: none;
          width: 84.887%; } }
  article#idx section.fullSupportLayout .checkListColumn {
    display: block;
    background-image: url("/images/index/check_list_repeat.gif");
    background-repeat: repeat-y;
    max-width: 980px;
    margin: 0 auto;
    padding: 20px;
    position: relative; }
    @media screen and (max-width: 768px) {
      article#idx section.fullSupportLayout .checkListColumn {
        padding: 16px; } }
    @media screen and (max-width: 320px) {
      article#idx section.fullSupportLayout .checkListColumn {
        padding: 8px; } }
    article#idx section.fullSupportLayout .checkListColumn::after {
      content: "";
      position: absolute;
      width: 160px;
      height: 35px;
      background-image: url("/images/index/check_list_bottom.gif");
      bottom: -35px;
      margin: auto;
      right: 0;
      left: 0; }
      @media screen and (max-width: 768px) {
        article#idx section.fullSupportLayout .checkListColumn::after {
          background-size: contain;
          width: 130px;
          background-repeat: no-repeat; } }
  article#idx section.fullSupportLayout ul.checkList {
    width: 100%;
    background-color: #fff;
    padding: 5.102% 0;
    overflow: hidden;
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      article#idx section.fullSupportLayout ul.checkList {
        padding: 24px 10px; } }
    article#idx section.fullSupportLayout ul.checkList::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("/images/index/check_list_splash.png");
      background-repeat: no-repeat;
      background-position: center bottom -80px;
      right: -120px;
      bottom: 0; }
      @media screen and (max-width: 768px) {
        article#idx section.fullSupportLayout ul.checkList::after {
          right: -30%; } }
    article#idx section.fullSupportLayout ul.checkList li {
      max-width: 675px;
      margin: 0 auto 30px;
      font-size: 2.4rem;
      font-weight: bold;
      padding-left: 45px;
      position: relative;
      font-feature-settings: "halt"; }
      @media screen and (max-width: 768px) {
        article#idx section.fullSupportLayout ul.checkList li {
          font-size: 1.8rem;
          padding-left: 25px;
          margin: 0 auto 10px; } }
      @media screen and (max-width: 320px) {
        article#idx section.fullSupportLayout ul.checkList li {
          font-size: 1.5rem; } }
      article#idx section.fullSupportLayout ul.checkList li:last-child {
        margin: 0 auto; }
      article#idx section.fullSupportLayout ul.checkList li::before {
        content: "";
        position: absolute;
        width: 34px;
        height: 28px;
        background-image: url("/images/index/check_icon.png");
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto; }
        @media screen and (max-width: 768px) {
          article#idx section.fullSupportLayout ul.checkList li::before {
            width: 20;
            height: 13px;
            background-size: contain;
            background-repeat: no-repeat;
            top: 7px;
            bottom: inherit; } }

article#idx section.athkitFeatureLayout {
  position: relative;
  overflow: hidden;
  padding: 40px 20px 80px; }
  @media screen and (min-width: 640px) and (max-width: 768px) {
    article#idx section.athkitFeatureLayout {
      padding: 11.71875% 30px 18.8802%; } }
  @media screen and (max-width: 768px) {
    article#idx section.athkitFeatureLayout {
      padding: 11.71875% 15px 18.8802%; } }
  article#idx section.athkitFeatureLayout::before {
    content: "";
    position: absolute;
    width: 534px;
    height: 463px;
    top: 88px;
    left: -225px;
    z-index: 0;
    background-image: url("/images/index/feature_bg.png"); }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout::before {
        background-size: contain;
        width: 58.802%;
        top: 13.177%;
        left: -21%;
        height: auto;
        padding-top: 60.2864%;
        background-repeat: no-repeat; } }
  article#idx section.athkitFeatureLayout h2 {
    padding-bottom: 40px; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout h2 {
        padding-bottom: 4.607%; } }
    article#idx section.athkitFeatureLayout h2 img {
      margin: 0 auto;
      max-width: 238px; }
      @media screen and (max-width: 768px) {
        article#idx section.athkitFeatureLayout h2 img {
          max-width: none;
          width: 55.0847%; } }
  article#idx section.athkitFeatureLayout ul.featureList {
    position: relative;
    display: flex;
    max-width: 980px;
    margin: 0 auto;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout ul.featureList {
        flex-wrap: wrap; } }
    article#idx section.athkitFeatureLayout ul.featureList li {
      width: 20%; }
      @media screen and (max-width: 768px) {
        article#idx section.athkitFeatureLayout ul.featureList li {
          width: 33.3333%;
          padding-bottom: 15px; } }
      article#idx section.athkitFeatureLayout ul.featureList li img {
        max-width: 170px;
        margin: 0 auto;
        width: 86.7346%; }
        @media screen and (max-width: 768px) {
          article#idx section.athkitFeatureLayout ul.featureList li img {
            max-width: 206px;
            width: 84.0816%; } }
  article#idx section.athkitFeatureLayout p.attention {
    padding-top: 20px;
    padding-right: 20px;
    text-align: right;
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 100px; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout p.attention {
        padding: 5px 0 24.6612%;
        color: #767676; } }
  article#idx section.athkitFeatureLayout .requiredToolBox {
    display: block;
    background-image: url("/images/index/tools_bg.jpg");
    max-width: 980px;
    margin: 0 auto;
    background-repeat: no-repeat;
    position: relative; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout .requiredToolBox {
        background-size: cover; } }
  article#idx section.athkitFeatureLayout h3 {
    display: block;
    position: relative;
    top: -85px; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout h3 {
        top: -60px; } }
    article#idx section.athkitFeatureLayout h3 img {
      margin: 0 auto; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#idx section.athkitFeatureLayout h3 img {
          width: 64.7696%;
          max-width: 475px; } }
      @media screen and (max-width: 768px) {
        article#idx section.athkitFeatureLayout h3 img {
          width: 80%; } }
  article#idx section.athkitFeatureLayout .iconBox {
    position: relative;
    display: flex;
    max-width: 345px;
    margin: -85px auto 0;
    padding: 35px 0 50px 0;
    justify-content: space-between;
    align-items: flex-end; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout .iconBox {
        margin: -60px auto 0;
        max-width: none;
        padding: 3.9295% 0 4.7425%; } }
    article#idx section.athkitFeatureLayout .iconBox .icon {
      text-align: center; }
      @media screen and (max-width: 768px) {
        article#idx section.athkitFeatureLayout .iconBox .icon {
          width: 50%; }
          article#idx section.athkitFeatureLayout .iconBox .icon img {
            margin: 0 auto;
            width: 48.7804%; } }
      article#idx section.athkitFeatureLayout .iconBox .icon p {
        padding-top: 15px; }
        @media screen and (max-width: 768px) {
          article#idx section.athkitFeatureLayout .iconBox .icon p {
            font-size: 1.6rem; } }
  article#idx section.athkitFeatureLayout p.toolAttention {
    padding-top: 15px;
    font-size: 1.2rem;
    line-height: 2;
    letter-spacing: 1.2px;
    max-width: 980px;
    margin: 0 auto;
    text-align: right; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitFeatureLayout p.toolAttention {
        padding-top: 6.775%;
        line-height: 1.5;
        color: #767676; } }

article#idx section.courseFeature {
  background-color: #f2f0e8;
  padding-top: 40px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    article#idx section.courseFeature {
      padding-top: 7.8125%; } }
  @media screen and (max-width: 768px) {
    article#idx section.courseFeature h2 {
      padding-bottom: 3.90625%; } }
  article#idx section.courseFeature h2 img {
    margin: 0 auto;
    max-width: 235px; }
    @media screen and (max-width: 768px) {
      article#idx section.courseFeature h2 img {
        max-width: none;
        width: 42.8385%; } }
  article#idx section.courseFeature .courseFeatureColumn {
    margin-top: -50px;
    background-image: url("/images/index/course_feature_bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 100px 20px 100px; }
    @media screen and (min-width: 640px) and (max-width: 768px) {
      article#idx section.courseFeature .courseFeatureColumn {
        margin-top: 0;
        padding: 0 30px 11.71875%;
        background-image: none; } }
    @media screen and (max-width: 768px) {
      article#idx section.courseFeature .courseFeatureColumn {
        margin-top: 0;
        padding: 0 15px 11.71875%;
        background-image: none; } }
    article#idx section.courseFeature .courseFeatureColumn .courseHeader {
      max-width: 1060px;
      margin: 0 auto; }
      article#idx section.courseFeature .courseFeatureColumn .courseHeader img {
        margin: 0 auto; }
    article#idx section.courseFeature .courseFeatureColumn .courseLayout {
      display: block;
      width: 100%;
      padding: 30px 0 0;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 768px) {
        article#idx section.courseFeature .courseFeatureColumn .courseLayout {
          padding: 24px 0 0; } }
      article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn {
        max-width: 1060px;
        width: 100%;
        margin: 0 auto;
        background-repeat: repeat-y;
        position: relative;
        margin-bottom: 104px;
        display: flex;
        flex-wrap: wrap;
        background-size: 100%;
        background-image: url("/images/index/course_repeat_bg.png"); }
        @media screen and (max-width: 768px) {
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn {
            background-image: url("/images/index/sp_course_repeat_bg.gif");
            padding: 0 15px;
            background-size: contain; } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn::after {
          content: "";
          position: absolute;
          width: 154px;
          height: 34px;
          background-image: url("/images/index/course_repeat_bg_bottom.png");
          left: 0;
          right: 0;
          margin: auto;
          bottom: -34px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn::after {
              width: 77px;
              height: 17px;
              background-size: contain;
              bottom: -17px; } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseTit {
          display: block;
          width: 100%;
          position: relative;
          padding-bottom: 55px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseTit {
              padding-bottom: 32px;
              order: 1; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseTit img {
            margin: 0 auto;
            position: relative;
            z-index: 3; }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt {
          width: 50%;
          position: relative; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt {
              width: 100%;
              order: 3; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt dl {
            padding-left: 15.7647%;
            padding-right: 3.9215%;
            max-width: 490px; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt dl {
                max-width: none;
                padding-left: 0;
                padding-right: 0; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt dl dt {
              font-size: 1.8rem;
              font-weight: bold; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt dl dt {
                  margin-bottom: 0.3em; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt dl dd {
              font-size: 1.6rem;
              padding-bottom: 35px; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseDetailTxt dl dd {
                  padding-bottom: 24px;
                  font-size: 1.5rem; } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseImg {
          width: 50%;
          position: relative; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseImg {
              width: 100%;
              padding-bottom: 9.1807%;
              order: 2; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn .courseImg img {
            position: relative; }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn p.annotation {
          width: 100%;
          display: block;
          font-size: 1.2rem;
          line-height: 2;
          letter-spacing: 1.2px;
          padding-left: 8%;
          padding-right: 70px;
          position: relative; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn p.annotation {
              order: 4;
              padding-left: 0;
              padding-right: 0;
              line-height: 1.5;
              color: rgba(0, 0, 0, 0.6); } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 {
          margin: 0 auto 80px;
          padding-top: 60px;
          padding-bottom: 65px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 {
              padding-top: 32px;
              padding-bottom: 6.775%;
              margin-bottom: 14.2276%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 .courseTit img {
            width: 35.3773%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 .courseTit img {
                width: auto; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1::before {
            content: "";
            position: absolute;
            background-image: url("/images/index/course1_splash01.png");
            width: 30.5882%;
            padding-top: 23.6274%;
            top: -25px;
            z-index: 0;
            left: 19.1176%;
            background-size: contain;
            background-repeat: no-repeat; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1::before {
                left: -18%;
                width: 60.7046%;
                padding-top: 62.4661%;
                top: -35px; } }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 .courseDetailTxt {
              order: 3; } }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 .courseImg {
              order: 2; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 .courseImg::before {
            content: "";
            position: absolute;
            background-image: url("/images/index/course1_splash02.png");
            width: 425px;
            padding-top: 246px;
            top: -120px;
            z-index: 0;
            right: 22.3529%;
            background-size: contain;
            background-repeat: no-repeat; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course1 .courseImg::before {
                width: 80.1977%;
                padding-top: 62.2881%;
                top: -80px; } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 {
          margin: 0 auto 104px;
          padding-top: 60px;
          padding-bottom: 65px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 {
              padding-top: 17.6151%;
              margin-bottom: 14.2276%;
              padding-bottom: 6.775%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .courseTit img {
            width: 24.6226%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .courseTit img {
                width: 75.5649%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .courseDetailTxt {
            padding-right: 6.8823%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .courseDetailTxt {
                padding: 0; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .courseDetailTxt p {
              width: 91.3137%;
              margin-left: auto;
              line-height: 1.75;
              letter-spacing: 1.6px; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .courseDetailTxt p {
                  width: 100%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2::before {
            content: "";
            position: absolute;
            background-image: url("/images/index/course2_splash01.png");
            width: 40.5882%;
            padding-top: 29.8039%;
            top: -53px;
            z-index: 0;
            left: 27.2941%;
            background-size: contain;
            background-repeat: no-repeat; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2::before {
                width: 100%;
                padding-top: 73.3875%;
                left: 0;
                right: 0;
                margin: auto;
                top: 0; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List {
            display: block;
            width: 100%;
            padding-top: 40px; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List {
                order: 5;
                padding-top: 5.6497%; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout {
              display: block;
              max-width: 860px;
              background-color: rgba(255, 255, 255, 0.5);
              width: 81.132%;
              margin: 0 auto;
              padding: 40px 0 50px;
              margin-bottom: 40px; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout {
                  width: 100%;
                  padding: 11.2994% 0 8.4745%;
                  margin-bottom: 24px; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout h3 {
                font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
                font-size: 3.6rem;
                font-weight: bold;
                line-height: 1.17;
                letter-spacing: 1.8px;
                text-align: center;
                color: #d90011;
                padding-bottom: 30px; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout h3 {
                    padding-bottom: 8.4745%;
                    font-size: 2.4rem; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout h3 span {
                  font-size: 2.4rem;
                  color: #000; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout h3 span {
                      font-size: 1.8rem; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl {
                display: flex;
                flex-wrap: wrap;
                max-width: 415px;
                margin: 0 auto; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl {
                    max-width: none;
                    font-feature-settings: "halt"; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt,
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd {
                  width: 50%;
                  font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
                  margin-bottom: 1em; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt,
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd {
                      width: 100%;
                      text-align: left;
                      font-size: 2rem;
                      padding-left: 3.5em; } }
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt:last-of-type,
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd:last-of-type {
                    margin-bottom: 0; }
                    @media screen and (max-width: 768px) {
                      article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt:last-of-type,
                      article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd:last-of-type {
                        margin-bottom: 10px; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt {
                  font-size: 2.2rem;
                  font-weight: bold;
                  line-height: 1;
                  letter-spacing: 0.01em;
                  position: relative;
                  padding-left: 0.3em; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt {
                      padding: 0;
                      padding-left: 3.5em;
                      margin-bottom: 10px; } }
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt p {
                    display: inline-block;
                    position: relative; }
                    @media screen and (max-width: 768px) {
                      article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt p::before {
                        content: "";
                        position: absolute;
                        top: -4px;
                        bottom: 0;
                        left: -1.5em;
                        margin: auto;
                        width: 19px;
                        height: 1px;
                        background-color: #d90011; } }
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt::before {
                    content: "";
                    position: absolute;
                    top: -4px;
                    bottom: 0;
                    left: -30px;
                    margin: auto;
                    width: 19px;
                    height: 1px;
                    background-color: #d90011; }
                    @media screen and (max-width: 768px) {
                      article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dt::before {
                        display: none; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd {
                  font-size: 2.2rem;
                  font-weight: bold;
                  line-height: 1;
                  letter-spacing: 1.1px;
                  color: #d90011;
                  letter-spacing: -0.09em;
                  position: relative; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd {
                      margin-bottom: 17px;
                      padding-bottom: 17px; } }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd::after {
                      content: "";
                      position: absolute;
                      width: 50%;
                      height: 1px;
                      background-color: #e5e1d1;
                      bottom: 0;
                      left: 0;
                      right: 0;
                      margin: auto; } }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd:last-of-type {
                      padding: 0;
                      padding-left: 3.5em;
                      margin: 0; }
                      article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2List .listColumnLayout dl dd:last-of-type::after {
                        display: none; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2Txt {
            width: 100%;
            text-align: center; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2Txt {
                order: 6; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2Txt p {
              font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
              font-size: 2rem;
              font-weight: bold;
              line-height: 1.5; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.course2 .course2Txt p {
                  font-size: 1.7rem;
                  text-align: left; } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu {
          margin: 0 auto 104px;
          padding-top: 60px;
          padding-bottom: 65px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu {
              padding-top: 10.8401%;
              margin-bottom: 14.2276%;
              padding-bottom: 6.775%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .courseTit img {
            width: 20.4716%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .courseTit img {
                width: 62.9943%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout {
            display: flex;
            width: 100%;
            padding: 0 40px;
            margin-bottom: 50px;
            position: relative; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout {
                order: 2;
                padding: 0;
                margin-bottom: 0;
                flex-wrap: wrap; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout::before {
              content: "";
              position: absolute;
              width: 1px;
              height: 100%;
              top: 0;
              left: 0;
              right: 0;
              background-color: #999999;
              margin: auto; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout::before {
                  display: none; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox,
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox {
              display: block;
              width: 50%;
              text-align: center; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox,
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox {
                  width: 100%; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox h4,
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox h4 {
                padding-bottom: 15px;
                position: relative;
                height: 60px; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox h4,
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox h4 {
                    height: auto; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox h4::before,
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox h4::before {
                  content: "";
                  position: absolute;
                  z-index: 0;
                  left: 0;
                  right: 0;
                  top: 0;
                  margin: auto; }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox h4 img,
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox h4 img {
                  margin: 0 auto;
                  position: relative;
                  z-index: 2; }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox .thumbnail,
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox .thumbnail {
                width: 100%;
                margin-bottom: 40px;
                position: relative; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox .thumbnail,
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox .thumbnail {
                    margin-bottom: 5.6497%; }
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox .thumbnail img,
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox .thumbnail img {
                      margin: 0 auto; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox ul,
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox ul {
                text-align: left; }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox ul li,
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox ul li {
                  line-height: 1.75;
                  position: relative;
                  padding-left: 1.3em; }
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox ul li::before,
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox ul li::before {
                    position: absolute;
                    left: 0; }
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox ul li:first-child::before,
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox ul li:first-child::before {
                    content: "①"; }
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox ul li:nth-child(2)::before,
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox ul li:nth-child(2)::before {
                    content: "②"; }
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox ul li:last-child::before,
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox ul li:last-child::before {
                    content: "②"; }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox {
              padding-left: 6.1224%;
              padding-right: 4.0816%; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox {
                  padding: 0;
                  padding-bottom: 28.2485%; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox h4::before {
                width: 64.8717%;
                padding-top: 50.7692%;
                background-image: url("/images/index/custom_menu_tit_splash01.png");
                background-size: contain;
                background-repeat: no-repeat;
                top: -60px; }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox h4 img {
                width: 35.8974%; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .trainingBox h4 img {
                    width: 41.3841%; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox {
              padding-left: 4.0816%;
              padding-right: 6.1224%; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox {
                  padding: 0; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox h4::before {
                width: 125.3846%;
                padding-top: 72.5641%;
                background-image: url("/images/index/custom_menu_tit_splash02.png");
                background-size: contain;
                background-repeat: no-repeat;
                top: -110px;
                left: -70px; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox h4::before {
                    width: 100%;
                    padding-top: 92.5641%;
                    top: -50px;
                    left: -30px; } }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customColumnLayout .mealBox ul {
                  margin-bottom: 21.1864%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt {
            width: 81.132%;
            text-align: center;
            max-width: 860px;
            margin: 0 auto;
            border: 1px solid #999999;
            padding: 0 0 30px; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt {
                order: 3;
                width: 100%;
                text-align: left;
                padding-bottom: 18px; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt h5 {
              font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
              font-weight: bold;
              line-height: 1;
              letter-spacing: 0.7px;
              font-size: 2.4rem;
              margin-bottom: 10px;
              display: inline-block;
              background: #e5e1d1;
              position: relative;
              top: -0.6em;
              padding: 0 15px; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt h5 {
                  margin-bottom: 0;
                  font-size: 1.9rem;
                  line-height: 1.3;
                  text-align: center;
                  padding: 0 8px;
                  margin: 0 8px;
                  top: -1em; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt h5 span {
                font-size: 2.8rem;
                color: #d90011; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt h5 span {
                    font-size: 2.2rem; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt h5:after {
                content: ".";
                width: 0;
                font-size: 0;
                color: transparent;
                margin-right: 1px; }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt p {
              line-height: 1.88; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.custommenu .customTxt p {
                  padding: 0 20px; } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.intermediate {
          margin: 0 auto 104px;
          padding-top: 60px;
          padding-bottom: 45px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.intermediate {
              padding-top: 10.8401%;
              margin-bottom: 14.2276%;
              padding-bottom: 6.775%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.intermediate .courseTit {
            padding-bottom: 30px; }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.intermediate .courseTit img {
              width: 12.0754%; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.intermediate .courseTit img {
                  width: 38.1355%; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.intermediate p {
            width: 100%;
            text-align: center;
            line-height: 1.75;
            letter-spacing: 1.6px; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.intermediate p {
                order: 2;
                text-align: left; } }
        article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult {
          margin: 0 auto;
          padding-top: 60px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult {
              padding: 16.2601% 0 0; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult::after {
            display: none; }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .courseTit {
            padding-bottom: 30px; }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .courseTit img {
              width: 30.1886%; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .courseTit img {
                  width: 91.9491%; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .courseTit::before {
              position: absolute;
              background-image: url("/images/index/dataresult_splash.png");
              content: "";
              top: -140px;
              left: 0;
              right: 0;
              margin: 0 auto;
              width: 439px;
              height: 372px; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .courseTit::before {
                  left: -50px; } }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult p.topRead {
            width: 100%;
            text-align: center;
            line-height: 1.75;
            letter-spacing: 1.6px;
            position: relative;
            z-index: 3;
            padding-bottom: 40px; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult p.topRead {
                order: 2;
                padding: 0 15px 20px;
                text-align: left; } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult p.topRead span {
              font-size: 1.8rem;
              color: #ad000b;
              letter-spacing: 1.8px; }
          article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout {
            width: 92.4528%;
            display: block;
            background-image: url("/images/index/result_bg.png");
            max-width: 980px;
            margin: 0 auto;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            max-height: 407px;
            height: 100%;
            padding-top: 5.3773%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout {
                order: 3;
                width: 100%;
                min-height: inherit;
                padding-top: 202.981%;
                background-image: url("/images/index/sp_result_bg.jpg"); } }
            article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox {
              width: 50%;
              margin-left: auto; }
              @media screen and (max-width: 768px) {
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox {
                  width: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h5 {
                padding-bottom: 14px; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h5 {
                    position: absolute;
                    top: 6.4471%;
                    width: 100%;
                    padding-bottom: 0; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h5 img {
                  width: 75.8691%;
                  margin: 0 auto; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h5 img {
                      width: 88.8888%; } }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox p.resultTxtP {
                text-align: center;
                font-size: 2rem;
                line-height: 1.5;
                letter-spacing: 1.6px;
                font-weight: bold;
                padding-bottom: 10px; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox p.resultTxtP {
                    position: absolute;
                    width: 100%;
                    top: 65%; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox p.resultTxtP span {
                  font-weight: normal;
                  font-size: 1.6rem;
                  display: block; }
              article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h6 {
                padding-bottom: 6.1349%; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h6 {
                    position: absolute;
                    bottom: 4%;
                    width: 100%;
                    padding-bottom: 0; } }
                article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h6 img {
                  width: 61.5541%;
                  margin: 0 auto; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseFeature .courseFeatureColumn .courseLayout .courseRepeatColumn.dataResult .resultLayout .resultTxtBox h6 img {
                      width: 81.5718%; } }

article#idx section.trainerIntroductionLayout {
  background-image: url("/images/index/trainer_layout_bg.jpg");
  background-position: center top 462px;
  padding-top: 128px;
  background-repeat: no-repeat;
  padding-bottom: 130px; }
  @media screen and (max-width: 768px) {
    article#idx section.trainerIntroductionLayout {
      padding-top: 14.3229%;
      padding-bottom: 16.276%; } }
  article#idx section.trainerIntroductionLayout h2 {
    padding-bottom: 60px; }
    @media screen and (max-width: 768px) {
      article#idx section.trainerIntroductionLayout h2 {
        padding-bottom: 6.5104%; } }
    article#idx section.trainerIntroductionLayout h2 img {
      margin: 0 auto;
      max-width: 275px; }
      @media screen and (max-width: 768px) {
        article#idx section.trainerIntroductionLayout h2 img {
          max-width: none;
          width: 44.921875%; } }
  article#idx section.trainerIntroductionLayout .postFirstlayout {
    display: block;
    width: 100%;
    padding: 0 20px 120px;
    max-width: 1020px;
    margin: 0 auto; }
    @media screen and (min-width: 640px) and (max-width: 768px) {
      article#idx section.trainerIntroductionLayout .postFirstlayout {
        padding: 0 30px 11.0677%; } }
    @media screen and (max-width: 768px) {
      article#idx section.trainerIntroductionLayout .postFirstlayout {
        padding: 0 15px 11.0677%; } }
    article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn {
      display: block;
      width: 100%; }
      article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .thumbnail {
        background-color: #000;
        margin-bottom: 65px; }
        @media screen and (max-width: 768px) {
          article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .thumbnail {
            margin-bottom: 8.8075%; } }
        article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .thumbnail img {
          width: 44.3877%;
          margin: 0 auto; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .thumbnail img {
              width: 100%; } }
      article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn {
        display: block;
        max-width: 900px;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto; }
        @media screen and (max-width: 768px) {
          article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn {
            padding: 0 15px; } }
        article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postName {
          display: block;
          padding-bottom: 30px;
          font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postName {
              padding-bottom: 6.775%; } }
          article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postName h3 {
            font-size: 4.2rem;
            font-weight: bold;
            line-height: 1.62;
            letter-spacing: 1.1px;
            display: inline-block;
            padding-right: 20px; }
            @media screen and (max-width: 768px) {
              article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postName h3 {
                font-size: 2.4rem;
                padding-right: 12px; } }
          article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postName span {
            display: inline-block;
            font-size: 1.8rem;
            font-weight: bold;
            color: #d90011;
            letter-spacing: 0.5px;
            transform: rotate(-5deg);
            position: relative;
            top: -7px; }
            @media screen and (max-width: 768px) {
              article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postName span {
                font-size: 1.5rem; } }
        article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postEditor {
          font-size: 1.8rem;
          line-height: 1.5;
          padding-bottom: 40px; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postEditor {
              font-size: 1.5rem; } }
          article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postEditor strong {
            font-weight: bold; }
        article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification {
          display: block;
          width: 100%;
          border: solid 1px #999999;
          padding-bottom: 20px; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification {
              max-width: 604px;
              margin: 0 auto;
              padding-bottom: 12px; } }
          article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification h4 {
            font-size: 1.8rem;
            font-weight: bold;
            line-height: 1;
            letter-spacing: 1.8px;
            display: inline-block;
            position: relative;
            padding: 0 1em;
            top: -0.8em;
            background-color: #fff;
            left: 1em; }
            @media screen and (max-width: 768px) {
              article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification h4 {
                font-size: 1.6rem;
                left: 50%;
                transform: translateX(-50%); } }
          article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification p {
            padding-left: 2.2em;
            padding-right: 10px;
            font-size: 1.6rem;
            line-height: 1.75;
            letter-spacing: 1.6px; }
            @media screen and (max-width: 768px) {
              article#idx section.trainerIntroductionLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification p {
                padding: 0 10px;
                font-size: 1.3rem;
                letter-spacing: 0; } }
  article#idx section.trainerIntroductionLayout .postTrainerList {
    display: block;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    margin: 0 auto; }
    @media screen and (min-width: 640px) and (max-width: 768px) {
      article#idx section.trainerIntroductionLayout .postTrainerList {
        padding: 0 30px; } }
    @media screen and (max-width: 768px) {
      article#idx section.trainerIntroductionLayout .postTrainerList {
        padding: 0 15px; } }
    article#idx section.trainerIntroductionLayout .postTrainerList ul {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 768px) {
        article#idx section.trainerIntroductionLayout .postTrainerList ul {
          display: none; } }
      article#idx section.trainerIntroductionLayout .postTrainerList ul li {
        width: 50%;
        margin-bottom: 100px; }
        @media screen and (max-width: 768px) {
          article#idx section.trainerIntroductionLayout .postTrainerList ul li {
            width: 100%;
            display: block;
            margin-bottom: 11.0677%; } }
        @media screen and (max-width: 768px) {
          article#idx section.trainerIntroductionLayout .postTrainerList ul li:nth-child(n + 5) {
            display: none; } }
        article#idx section.trainerIntroductionLayout .postTrainerList ul li:nth-child(odd) .postColumn {
          padding-right: 10.4166%; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postTrainerList ul li:nth-child(odd) .postColumn {
              padding: 0 15px; } }
        article#idx section.trainerIntroductionLayout .postTrainerList ul li:nth-child(even) .postColumn {
          padding-left: 10.4166%; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postTrainerList ul li:nth-child(even) .postColumn {
              padding: 0 15px; } }
        article#idx section.trainerIntroductionLayout .postTrainerList ul li:last-child {
          margin-bottom: 0; }
        article#idx section.trainerIntroductionLayout .postTrainerList ul li:nth-last-child(2) {
          margin-bottom: 0; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postTrainerList ul li:nth-last-child(2) {
              margin-bottom: 11.0677%; } }
        article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn {
          max-width: 480px; }
          @media screen and (max-width: 768px) {
            article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn {
              max-width: none;
              margin: 0 auto;
              padding: 0 15px; } }
          article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .thumbnail {
            max-width: 280px;
            margin: 0 auto;
            padding-bottom: 40px; }
            @media screen and (max-width: 768px) {
              article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .thumbnail {
                max-width: 560px;
                padding-bottom: 15.5367%; }
                article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .thumbnail img {
                  width: 100%; } }
          article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postName {
            font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
            padding-bottom: 25px; }
            @media screen and (max-width: 768px) {
              article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postName {
                padding-bottom: 8.4745%; } }
            article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postName h3 {
              font-size: 42px;
              font-weight: bold;
              line-height: 1.3;
              letter-spacing: 1.1px;
              display: inline-block;
              padding-right: 20px; }
              @media screen and (max-width: 768px) {
                article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postName h3 {
                  font-size: 3rem;
                  padding-right: 10px; } }
            article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postName span {
              display: inline-block;
              font-size: 1.8rem;
              font-weight: bold;
              color: #d90011;
              letter-spacing: 0.5px;
              transform: rotate(-5deg);
              position: relative;
              top: -7px;
              padding-top: 5px; }
              @media screen and (max-width: 768px) {
                article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postName span {
                  font-size: 1.6rem; } }
          article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postEditor {
            font-size: 1.8rem;
            line-height: 1.5;
            padding-bottom: 40px; }
            article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postEditor strong {
              font-weight: bold; }
          article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postQualification {
            display: block;
            width: 100%;
            border: solid 1px #999999;
            padding-bottom: 20px; }
            article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postQualification h4 {
              font-size: 1.8rem;
              font-weight: bold;
              line-height: 1;
              letter-spacing: 1.8px;
              display: inline-block;
              position: relative;
              padding: 0 1em;
              top: -0.8em;
              background-color: #fff;
              left: 1em; }
            article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postQualification p {
              padding-left: 2.2em;
              padding-right: 10px;
              font-size: 1.6rem;
              line-height: 1.75;
              letter-spacing: 1.6px; }
              @media screen and (max-width: 768px) {
                article#idx section.trainerIntroductionLayout .postTrainerList ul li .postColumn .postQualification p {
                  padding: 0 10px; } }
    article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout {
      padding-top: 85px;
      position: relative; }
      @media screen and (max-width: 768px) {
        article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout {
          padding-top: 20px; } }
      article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout.spMode {
        display: none; }
        @media screen and (max-width: 768px) {
          article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout.spMode {
            display: block; } }
      article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout .colorBox {
        max-width: 487px;
        margin: 0 auto; }
        @media screen and (max-width: 768px) {
          article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout .colorBox {
            max-width: 704px;
            width: 99.435%; } }
        article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout .colorBox a {
          padding: 26px 0; }
          article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout .colorBox a img {
            max-width: 106px; }
            @media screen and (max-width: 768px) {
              article#idx section.trainerIntroductionLayout .postTrainerList .btnBoxLayout .colorBox a img {
                max-width: none;
                width: 29.8022%; } }

article#idx section.courseIntroduction {
  width: 100%;
  background-color: #f2f0e8;
  background-image: url("/images/index/course_introduction_bg.png");
  background-position: center top 112px;
  background-repeat: no-repeat;
  padding: 85px 0 100px 0; }
  @media screen and (min-width: 640px) and (max-width: 768px) {
    article#idx section.courseIntroduction {
      padding: 7.03125% 30px 0; } }
  @media screen and (max-width: 768px) {
    article#idx section.courseIntroduction {
      padding: 7.03125% 15px 0; } }
  article#idx section.courseIntroduction .courselayout {
    width: 100%;
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      article#idx section.courseIntroduction .courselayout {
        padding: 0; } }
    article#idx section.courseIntroduction .courselayout h2 {
      padding-bottom: 60px; }
      @media screen and (max-width: 768px) {
        article#idx section.courseIntroduction .courselayout h2 {
          padding-bottom: 7.317%; } }
      article#idx section.courseIntroduction .courselayout h2 img {
        margin: 0 auto;
        max-width: 193px; }
        @media screen and (max-width: 768px) {
          article#idx section.courseIntroduction .courselayout h2 img {
            max-width: none;
            width: 41.9491%; } }
    article#idx section.courseIntroduction .courselayout h3.self {
      display: block;
      width: 100%;
      background-image: url("/images/index/self_training_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      height: 140px;
      font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 1.6px;
      text-align: center;
      color: #fff;
      padding-top: 40px;
      margin-bottom: 35px; }
      @media screen and (max-width: 768px) {
        article#idx section.courseIntroduction .courselayout h3.self {
          font-size: 2.2rem;
          height: 80px;
          padding-top: 1em;
          margin-bottom: 5.42%; } }
    article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout {
      display: block;
      padding-bottom: 60px; }
      @media screen and (max-width: 768px) {
        article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout {
          padding-bottom: 10.1864%; } }
      article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .courseVisual {
        padding-bottom: 3.57142%; }
      article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox {
        margin: auto;
        position: relative; }
        article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox:before {
          content: "";
          position: absolute;
          width: 3px;
          height: 108px;
          background-image: linear-gradient(to top, #ba000f, #ed4300 80%);
          top: 0;
          left: 0;
          right: 0;
          margin: auto; }
          @media screen and (max-width: 768px) {
            article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox:before {
              display: none; } }
        article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox {
          position: relative;
          z-index: 1;
          display: flex; }
          @media screen and (max-width: 768px) {
            article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox {
              display: block; } }
          article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price {
            width: 50%;
            padding-top: 15px;
            font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
            font-size: 3rem;
            font-weight: bold;
            line-height: 1;
            letter-spacing: 0.9px;
            color: #b4000e;
            padding: 0 0 0 15.3061%;
            position: relative; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price {
                width: 100%;
                padding: 0 0 0.5em;
                margin-bottom: 0.5em; }
                article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price:before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  background-image: linear-gradient(to top, #ba000f, #ed4300 80%);
                  bottom: 0; } }
            article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h4 {
              font-size: 3.6rem;
              font-weight: bold;
              line-height: 1.17;
              letter-spacing: 0.9px;
              color: #000000;
              width: 100%;
              text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px, white -1px -1px 10px;
              position: relative;
              z-index: 1; }
              @media screen and (max-width: 768px) {
                article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h4 {
                  font-size: 2.8rem; } }
              article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h4::before {
                position: absolute;
                content: "";
                padding-top: 91.4705%;
                width: 100%;
                top: -100px;
                left: -90px;
                background-image: url("/images/index/ttl_splash_gold.png");
                background-repeat: no-repeat;
                z-index: -1; }
                @media screen and (min-width: 640px) and (max-width: 768px) {
                  article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h4::before {
                    width: 43.9265%;
                    padding-top: 34.4632%;
                    left: -50px;
                    top: -120px;
                    background-size: cover; } }
                @media screen and (max-width: 768px) {
                  article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h4::before {
                    width: 43.9265%;
                    padding-top: 34.4632%;
                    left: -40px;
                    top: -50px;
                    background-size: cover; } }
            article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h5 {
              font-size: 3rem;
              font-weight: bold;
              line-height: 2.27;
              letter-spacing: 0.8px;
              color: #b4000e;
              display: inline-block;
              text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px, white -1px -1px 10px;
              position: relative;
              z-index: 2; }
              article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h5 span {
                font-size: 1.8rem;
                letter-spacing: 0.5px; }
              @media screen and (max-width: 768px) {
                article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h5 {
                  font-size: 2.6rem;
                  line-height: 1.7; }
                  article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price h5 span {
                    font-size: 1.6rem; } }
            article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price p {
              display: inline-block;
              position: relative;
              z-index: 2; }
              article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price p span {
                font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
                text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px, white -1px -1px 10px;
                font-size: 1.4rem;
                font-weight: bold;
                letter-spacing: 0.4px;
                color: #b4000e; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .price p span {
                    font-size: 1.2rem; } }
          article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .txt {
            width: 50%;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.75;
            letter-spacing: 0.8px;
            text-align: left;
            color: #000000;
            padding: 0 0 0 3.5714%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .txt {
                width: 100%;
                padding: 0; } }
            article#idx section.courseIntroduction .courselayout .selfKeyVisuallayout .detailBox .txtBox .txt .detailTxt {
              display: block;
              font-weight: normal;
              font-size: 1.4rem;
              line-height: 1.75;
              letter-spacing: .7px;
              color: #000;
              padding-top: 1em; }
    article#idx section.courseIntroduction .courselayout .planRepeatLayout {
      display: block;
      width: 100%;
      padding-bottom: 70px; }
      @media screen and (max-width: 768px) {
        article#idx section.courseIntroduction .courselayout .planRepeatLayout {
          padding-bottom: 0; } }
      article#idx section.courseIntroduction .courselayout .planRepeatLayout.last {
        padding-bottom: 95px; }
        @media screen and (max-width: 768px) {
          article#idx section.courseIntroduction .courselayout .planRepeatLayout.last {
            padding-bottom: 0; } }
        @media screen and (max-width: 768px) {
          article#idx section.courseIntroduction .courselayout .planRepeatLayout.last h3 {
            font-size: 1.7rem; } }
      article#idx section.courseIntroduction .courselayout .planRepeatLayout h3 {
        font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
        text-align: center;
        color: #0f1523;
        background-image: url("/images/index/course_plan_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        height: 140px;
        padding-top: 40px;
        margin-bottom: 50px; }
        @media screen and (max-width: 768px) {
          article#idx section.courseIntroduction .courselayout .planRepeatLayout h3 {
            font-size: 1.7rem;
            padding-top: 0.8em;
            height: 56px;
            letter-spacing: 1px;
            margin-bottom: 9.485%; } }
      article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout {
        display: flex;
        width: 100%; }
        @media screen and (max-width: 768px) {
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout {
            flex-wrap: wrap;
            padding-bottom: 13.5501%; } }
        article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox {
          width: 50%; }
          @media screen and (max-width: 768px) {
            article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox {
              width: 100%;
              margin-bottom: 25px; } }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox:first-of-type {
            padding-right: 5.6122%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox:first-of-type {
                padding-right: 0; } }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox:last-of-type {
            padding-left: 5.6122%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox:last-of-type {
                padding-left: 0;
                margin-bottom: 0; } }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox h4 {
            font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
            font-size: 3rem;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: 0.8px;
            text-align: center;
            color: #000;
            text-shadow: 0px 0px 6px #ffffff;
            padding-bottom: 20px; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox h4 {
                font-size: 2.3rem; } }
            article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox h4.adjustment {
              padding-top: 1em; }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox .courseTxt {
            background-color: #e5e1d1;
            text-align: center;
            padding: 15px;
            font-size: 1.8rem;
            font-weight: bold;
            line-height: 1.67; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox .courseTxt {
                font-size: 1.5rem;
                padding: 10px; } }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout .courseBox p.minTxt {
            padding-top: 20px;
            line-height: 1.75;
            letter-spacing: 1.6px; }
        article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout.last {
          flex-wrap: wrap; }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout.last .courseBox:first-of-type {
            padding-right: 5.6122%;
            padding-bottom: 50px; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout.last .courseBox:first-of-type {
                padding: 0;
                padding-bottom: 6.0975%;
                margin-bottom: 0; } }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout.last .courseBox:nth-last-child(2) {
            padding-left: 5.6122%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout.last .courseBox:nth-last-child(2) {
                padding: 0;
                padding-bottom: 6.0975%;
                margin-bottom: 0; } }
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout.last .courseBox:last-of-type {
            padding-right: 5.6122%;
            padding-left: 0; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .planRepeatLayout .twoColumnLayout.last .courseBox:last-of-type {
                padding: 0;
                margin-bottom: 0; } }
      article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout {
        text-align: center; }
        @media screen and (max-width: 768px) {
          article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout {
            padding-bottom: 13.5501%; } }
        article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout h4 {
          font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
          font-size: 3rem;
          font-weight: bold;
          line-height: 1.4;
          letter-spacing: 0.8px;
          text-align: center;
          color: #000;
          text-shadow: 0px 0px 6px #ffffff;
          padding-bottom: 20px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout h4 {
              font-size: 2.3rem; } }
        article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout .txtBox {
          background-color: #e5e1d1;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.8px;
          text-align: center;
          display: inline-block;
          padding: 15px;
          margin-bottom: 20px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout .txtBox {
              font-size: 1.5rem; } }
        article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout p.mintxt {
          line-height: 1.75;
          letter-spacing: 1.6px; }
          @media screen and (max-width: 768px) {
            article#idx section.courseIntroduction .courselayout .planRepeatLayout .singleColumnLayout p.mintxt {
              text-align: left; } }
    article#idx section.courseIntroduction .courselayout .personalTrainingLayout {
      display: block;
      padding-top: 55px; }
      @media screen and (max-width: 768px) {
        article#idx section.courseIntroduction .courselayout .personalTrainingLayout {
          padding-top: 25px; } }
      article#idx section.courseIntroduction .courselayout .personalTrainingLayout h3 {
        display: block;
        width: 100%;
        background-image: url("/images/index/self_training_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        height: 140px;
        font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.6px;
        text-align: center;
        color: #fff;
        padding-top: 40px;
        margin-bottom: 40px; }
        @media screen and (max-width: 768px) {
          article#idx section.courseIntroduction .courselayout .personalTrainingLayout h3 {
            font-size: 2rem;
            margin-bottom: 8.8075%;
            height: 70px;
            padding-top: 0.3em; } }
      article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout {
        display: flex;
        position: relative;
        flex-wrap: wrap; }
        article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout {
          width: 100%; }
          article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding-bottom: 10.204%; }
            @media screen and (max-width: 768px) {
              article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn {
                display: block;
                padding-bottom: 8.204%; } }
            article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn:nth-child(odd) {
              flex-direction: row; }
            article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn:nth-child(even) {
              flex-direction: row-reverse; }
            article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn:last-of-type {
              padding-bottom: 0; }
              @media screen and (max-width: 768px) {
                article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn:last-of-type {
                  padding-bottom: 14.6341%; } }
            article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .thumbnail {
              display: block;
              width: 57.653%; }
              @media screen and (max-width: 768px) {
                article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .thumbnail {
                  width: 100%;
                  padding-bottom: 3.57142%; } }
            article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn {
              display: block;
              width: 39.7959%; }
              @media screen and (max-width: 768px) {
                article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn {
                  width: 100%; } }
              article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn h4 {
                font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
                font-size: 2.8rem;
                font-weight: bold;
                line-height: 1.3;
                letter-spacing: 0.7px;
                color: #000000;
                padding-bottom: 0.3em;
                text-shadow: white 1px 1px 10px, white -1px 1px 10px, white 1px -1px 10px, white -1px -1px 10px; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn h4 {
                    font-size: 2.8rem;
                    padding-bottom: 0.5em; } }
              article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price {
                display: block;
                width: 100%;
                font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
                display: inline-block;
                color: #b4000e;
                padding-bottom: 1.2em; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price {
                    padding-bottom: 0; } }
                article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price h5 {
                  font-size: 2.4rem;
                  font-weight: bold;
                  letter-spacing: 0.6px;
                  display: inline-block; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price h5 {
                      font-size: 2.6rem;
                      line-height: 1; } }
                  article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price h5 span {
                    font-size: 18px;
                    letter-spacing: 0.5px; }
                    @media screen and (max-width: 768px) {
                      article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price h5 span {
                        font-size: 1.6rem; } }
                article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price span {
                  font-size: 1.4rem;
                  font-weight: bold;
                  letter-spacing: 0.4px; }
                  @media screen and (max-width: 768px) {
                    article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .price span {
                      font-size: 1.2rem; } }
              article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn hr {
                width: 100%;
                display: block;
                height: 3px;
                background-image: linear-gradient(to right, #ba000f, #ed4300 80%);
                margin: 0 auto; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn hr {
                    width: 100%;
                    height: 2px;
                    margin: 1.1em 0; } }
              article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .courseList {
                display: block;
                width: 100%;
                padding: 2.1em 0 1.3em;
                font-size: 1.6rem;
                line-height: 1.75;
                letter-spacing: 0.8px;
                color: #000000;
                font-weight: bold; }
                @media screen and (max-width: 768px) {
                  article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .courseList {
                    padding: 0 0 5%; } }
              article#idx section.courseIntroduction .courselayout .personalTrainingLayout .personalColumnLayout .courseLayout .repeatCourseColumn .courseTextColumn .txt {
                font-size: 1.4rem;
                line-height: 1.75;
                letter-spacing: 0.7px;
                color: #000000; }

article#idx section.resultAchievementLayout {
  background-color: #fff;
  background-image: url("/images/index/result_achievement_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  padding-top: 155px;
  padding-bottom: 195px; }
  @media screen and (max-width: 768px) {
    article#idx section.resultAchievementLayout {
      padding-top: 16.927%;
      padding-bottom: 0;
      background-image: url("/images/index/sp_result_achievement_bg.gif"); } }
  article#idx section.resultAchievementLayout h2 {
    padding-bottom: 60px; }
    article#idx section.resultAchievementLayout h2 img {
      margin: 0 auto;
      max-width: 403px; }
      @media screen and (max-width: 768px) {
        article#idx section.resultAchievementLayout h2 img {
          max-width: none;
          width: 80.46875%; } }
  article#idx section.resultAchievementLayout .resultAchievementColumn {
    max-width: 1065px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      article#idx section.resultAchievementLayout .resultAchievementColumn {
        padding: 0 0 14.7395%; } }
    article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout {
      display: flex;
      flex-wrap: wrap; }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout:first-of-type {
        margin-bottom: 20px; }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .topThumb {
        width: 100%;
        display: block;
        position: relative;
        margin-bottom: 1px; }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .topThumb img {
          position: relative; }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt {
        width: 100%;
        display: block;
        padding-left: 4.3902%;
        background-image: url("/images/index/voice_bg.gif");
        background-size: contain; }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt {
            padding: 0 3.9902% 0 4.2902%;
            background-image: none; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn {
          display: flex;
          padding: 30px 30px 35px 0;
          align-items: center; }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn {
              background-image: url("/images/index/voice_bg.gif");
              background-position: center center;
              flex-wrap: wrap;
              padding: 9.1145% 15px 6.5104%; } }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .baImg {
            width: 40.8163%; }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .baImg {
                width: 100%;
                padding-bottom: 7.8125%; } }
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .baImg img {
              margin-left: auto; }
              @media screen and (max-width: 768px) {
                article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .baImg img {
                  margin: 0 auto; } }
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .baImg img.voice01 {
                width: 71.81%; }
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .baImg img.voice02 {
                width: 81.0089%; }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .txt {
            width: 59.1837%; }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .txt {
                width: 100%; } }
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .txt .colBox {
              margin-left: auto;
              max-width: 490px;
              width: 87.1886%;
              background-color: #bcbcbc;
              padding: 30px 25px; }
              @media screen and (max-width: 768px) {
                article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .txt .colBox {
                  max-width: none;
                  width: 100%;
                  padding: 15px; } }
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatLayout .resultVoiceTxt .voiceTxtColumn .txt .colBox p {
                color: #000;
                line-height: 1.75;
                letter-spacing: 1.6px; }
    article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal {
      display: block;
      padding-top: 40px;
      margin-bottom: 80px; }
      @media screen and (max-width: 768px) {
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal {
          padding-right: 3.9902%;
          margin-bottom: 15.4166%; } }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn {
        width: 100%;
        display: flex;
        background-image: url("/images/index/voice_bg.gif");
        background-size: contain;
        align-items: center;
        padding-bottom: 30px; }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn {
            flex-wrap: wrap; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .topThumb {
          width: 45.6585%;
          margin-top: -33px; }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .topThumb {
              width: 100%; } }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .topThumb img {
              margin: 0 auto; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .txtBox {
          width: 54.3415%;
          padding-left: 2.9268%; }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .txtBox {
              width: 100%;
              padding-left: 4.2902%; } }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .txtBox img {
            margin-bottom: 30px; }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .txtBox img {
                margin: 10% auto;
                max-width: 80%; } }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .txtBox .colBox {
            margin-right: auto;
            max-width: 490px;
            width: 92.9791%;
            background-color: #bcbcbc;
            padding: 30px 25px; }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .txtBox .colBox {
                width: 87.7604%;
                max-width: none;
                margin: 0 auto; } }
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceRepeatoriginal .originalColumn .txtBox .colBox p {
              color: #000;
              line-height: 1.75;
              letter-spacing: 1.6px; }
    article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout {
      display: flex;
      padding-left: 4.3902%;
      margin-bottom: 50px; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout {
          flex-wrap: wrap;
          padding: 0 30px;
          margin-bottom: 10.4166%; } }
      @media screen and (max-width: 768px) {
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout {
          flex-wrap: wrap;
          padding: 0 15px;
          margin-bottom: 15.4166%; } }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout.replacement {
        flex-direction: row-reverse;
        justify-content: space-between; }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout:last-of-type {
        margin-bottom: 80px; }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout:last-of-type {
            margin-bottom: 15.4166%; } }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock {
        padding-top: 25px;
        text-align: center;
        color: #0f1523; }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock {
            padding-top: 0;
            padding-bottom: 50px; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock.top {
          width: 32.9591%; }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock.top {
              width: 100%; } }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock.center {
            width: 100%;
            text-align: center; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock h3 {
          font-size: 2.2rem;
          font-weight: bold;
          line-height: 1.27;
          letter-spacing: 2.2px;
          padding-bottom: 10px; }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock p.mintxt {
          line-height: 1.75;
          letter-spacing: 1.6px;
          padding-bottom: 10px; }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoBlock img {
            margin: 0 auto;
            width: 42.5141%;
            min-width: 210px; } }
      article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt {
        width: 67.0409%; }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt {
            width: 100%; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt.center .txtBox {
          margin: 0 auto 0 0; }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt.center .txtBox::before {
            top: 0;
            bottom: 0;
            right: -35px;
            left: inherit;
            margin: auto;
            transform: rotateY(180deg); }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt.center .txtBox::before {
                top: -29px;
                bottom: inherit;
                left: 50px;
                transform: rotate(90deg);
                right: inherit; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox {
          background-image: url("/images/index/voice_result_bg.jpg");
          border-radius: 12px;
          max-width: 622px;
          width: 94.6727%;
          margin-left: auto;
          padding: 15px 20px;
          position: relative; }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox {
              max-width: none;
              width: 100%; } }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox::before {
            content: "";
            position: absolute;
            width: 35px;
            height: 23px;
            background-image: url("/images/index/voice_result_bg_arrow.gif");
            left: -35px;
            bottom: 75px;
            background-size: contain;
            background-repeat: no-repeat; }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox::before {
                left: 50px;
                top: -29px;
                bottom: 0;
                transform: rotate(90deg); } }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox.last::before {
            bottom: 200px; }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox h4 {
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.5;
            padding-bottom: 5px; }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox h4 {
                font-size: 2rem; } }
            article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox h4 span {
              font-weight: normal; }
          article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox p {
            line-height: 1.75;
            letter-spacing: 1.6px; }
            @media screen and (max-width: 768px) {
              article#idx section.resultAchievementLayout .resultAchievementColumn .voiceTxtOnlyLayout .voiceInfoTxt .txtBox p {
                font-size: 1.4rem; } }
    article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList {
      display: flex;
      width: 100%;
      padding-left: 4.3902%; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList {
          flex-wrap: nowrap;
          padding-left: 0; } }
      @media screen and (max-width: 768px) {
        article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList {
          flex-wrap: wrap;
          padding-left: 0; } }
      article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li {
        width: 33.33333%;
        text-align: center;
        color: #0f1523;
        position: relative; }
        @media screen and (min-width: 640px) and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li {
            padding: 0 30px;
            margin-bottom: 4.5572%; } }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li {
            width: 100%;
            padding: 0 15px;
            margin-bottom: 15.5572%; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li:nth-child(2)::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 50%;
          top: 0;
          bottom: 0;
          left: -15px;
          margin: auto;
          background-color: #999999; }
          @media screen and (min-width: 640px) and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li:nth-child(2)::before {
              display: block; } }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li:nth-child(2)::before {
              display: none; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li:nth-child(2)::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 50%;
          top: 0;
          bottom: 0;
          right: 15px;
          margin: auto;
          background-color: #999999; }
          @media screen and (min-width: 640px) and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li:nth-child(2)::after {
              display: block; } }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li:nth-child(2)::after {
              display: none; } }
        @media screen and (max-width: 768px) {
          article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li:last-child {
            margin-bottom: 0; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li h3 {
          font-size: 2.2rem;
          font-weight: bold;
          line-height: 1.27;
          letter-spacing: 2.2px;
          padding-bottom: 10px; }
          @media screen and (min-width: 640px) and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li h3 {
              font-size: 2rem; } }
        article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li p.mintxt {
          line-height: 1.75;
          letter-spacing: 1.6px;
          padding-bottom: 10px; }
        article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li img {
          margin: 0 auto;
          width: 92.3312%; }
          @media screen and (min-width: 640px) and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li img {
              width: 92.3312%;
              min-width: inherit; } }
          @media screen and (max-width: 768px) {
            article#idx section.resultAchievementLayout .resultAchievementColumn ul.voiceList li img {
              width: 42.5141%;
              min-width: 210px; } }

article#idx section.qaLayout {
  background-color: #f2f0e8;
  padding-top: 120px;
  background-image: url("/images/index/qa_Layout_bg.png");
  background-repeat: no-repeat;
  padding-bottom: 95px; }
  @media screen and (max-width: 768px) {
    article#idx section.qaLayout {
      padding-top: 14.7395%;
      padding-bottom: 12.3697%; } }
  article#idx section.qaLayout h2 {
    padding-bottom: 60px; }
    @media screen and (max-width: 768px) {
      article#idx section.qaLayout h2 {
        padding-bottom: 6.5104%; } }
    article#idx section.qaLayout h2 img {
      margin: 0 auto;
      max-width: 227px; }
      @media screen and (max-width: 768px) {
        article#idx section.qaLayout h2 img {
          max-width: none;
          width: 48.177%; } }
  article#idx section.qaLayout .qaColumnLayout {
    display: block;
    max-width: 1020px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto; }
    @media screen and (min-width: 640px) and (max-width: 768px) {
      article#idx section.qaLayout .qaColumnLayout {
        padding: 0 30px; } }
    @media screen and (max-width: 768px) {
      article#idx section.qaLayout .qaColumnLayout {
        padding: 0 15px; } }
    article#idx section.qaLayout .qaColumnLayout h3 {
      display: block;
      width: 100%;
      background-image: url("/images/index/self_training_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      height: 140px;
      font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 1.6px;
      text-align: center;
      color: #fff;
      padding-top: 40px; }
      @media screen and (max-width: 768px) {
        article#idx section.qaLayout .qaColumnLayout h3 {
          font-size: 2rem;
          padding-top: 0.5em;
          height: 50px; } }
    article#idx section.qaLayout .qaColumnLayout .qaBlock.f {
      padding-top: 80px;
      padding-bottom: 40px; }
      @media screen and (max-width: 768px) {
        article#idx section.qaLayout .qaColumnLayout .qaBlock.f {
          padding-top: 16px; } }
    article#idx section.qaLayout .qaColumnLayout .qaBlock.s {
      padding-top: 24px;
      padding-bottom: 40px; }
    article#idx section.qaLayout .qaColumnLayout .qaBlock.l {
      padding-top: 24px; }
    article#idx section.qaLayout .qaColumnLayout dl {
      background-color: #e5e1d1;
      padding: 25px 15px 15px;
      margin-bottom: 8px; }
      @media screen and (max-width: 768px) {
        article#idx section.qaLayout .qaColumnLayout dl {
          padding: 20px 10px 10px; } }
      article#idx section.qaLayout .qaColumnLayout dl dt {
        background-image: url("/images/index/qa_icon_q.svg");
        background-repeat: no-repeat;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.27;
        letter-spacing: 2.2px;
        background-size: 32px 47px;
        padding: 0.5em 0 0.5em 3em;
        margin-bottom: 15px;
        background-position: center left 0.6em; }
        @media screen and (max-width: 768px) {
          article#idx section.qaLayout .qaColumnLayout dl dt {
            font-size: 1.7rem;
            background-size: 24px 22px;
            padding: 0 0 0 36px;
            background-position: top left 0.2em; } }
      article#idx section.qaLayout .qaColumnLayout dl dd {
        background-color: #f4f2eb;
        background-image: url("/images/index/qa_icon_a.svg");
        background-repeat: no-repeat;
        background-position: left 0.9em top 1.1em;
        background-size: 32px 37px;
        padding: 30px 40px 20px 4.2em;
        line-height: 1.75;
        letter-spacing: 1.6px; }
        @media screen and (max-width: 768px) {
          article#idx section.qaLayout .qaColumnLayout dl dd {
            font-size: 2rem;
            background-size: 15px 19px;
            padding: 1.4em 0.5em 1em 36px;
            background-position: left 0.6em top 1.3em;
            font-size: 1.4rem; } }
        article#idx section.qaLayout .qaColumnLayout dl dd .point {
          font-size: 2.2rem;
          line-height: 1.27;
          letter-spacing: 2.2px;
          padding-bottom: 0.5em; }
          @media screen and (max-width: 768px) {
            article#idx section.qaLayout .qaColumnLayout dl dd .point {
              font-size: 1.8rem; } }

article#idx section.athkitBlogLayout {
  width: 100%;
  background-repeat: no-repeat;
  background-image: url("/images/index/blog_bg.jpg");
  background-size: cover;
  background-position: top right;
  padding: 88px 0 0 0; }
  @media screen and (max-width: 768px) {
    article#idx section.athkitBlogLayout {
      padding: 11.4583% 0 10.4166% 0; } }
  article#idx section.athkitBlogLayout h2 {
    padding-bottom: 70px; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitBlogLayout h2 {
        padding-bottom: 9.1145%; } }
    article#idx section.athkitBlogLayout h2 img {
      margin: 0 auto; }
  article#idx section.athkitBlogLayout .postListLayout {
    display: block;
    width: 100%;
    max-width: 1020px;
    padding: 0 20px 95px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      article#idx section.athkitBlogLayout .postListLayout {
        padding: 0 15px; } }
    article#idx section.athkitBlogLayout .postListLayout ul {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 80px; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#idx section.athkitBlogLayout .postListLayout ul {
          flex-wrap: nowrap;
          padding-bottom: 5.8401%; } }
      @media screen and (max-width: 768px) {
        article#idx section.athkitBlogLayout .postListLayout ul {
          flex-wrap: wrap;
          padding-bottom: 5.8401%; } }
      article#idx section.athkitBlogLayout .postListLayout ul li {
        width: 29.5918%; }
        @media screen and (min-width: 640px) and (max-width: 768px) {
          article#idx section.athkitBlogLayout .postListLayout ul li {
            width: 29.5918%; } }
        @media screen and (max-width: 768px) {
          article#idx section.athkitBlogLayout .postListLayout ul li {
            width: 100%;
            margin-bottom: 10%; } }
        @media screen and (max-width: 768px) {
          article#idx section.athkitBlogLayout .postListLayout ul li:last-of-type {
            margin-bottom: 0%; } }
        article#idx section.athkitBlogLayout .postListLayout ul li a {
          display: block;
          width: 100%;
          color: #fff; }
          @media screen and (max-width: 768px) {
            article#idx section.athkitBlogLayout .postListLayout ul li a {
              width: 80%;
              margin: 0 auto; } }
          article#idx section.athkitBlogLayout .postListLayout ul li a .postThumb {
            display: block;
            max-width: 295px;
            margin-bottom: 30px;
            overflow: hidden;
            position: relative; }
            @media screen and (max-width: 768px) {
              article#idx section.athkitBlogLayout .postListLayout ul li a .postThumb {
                max-width: none;
                margin-bottom: 4.065%; } }
            article#idx section.athkitBlogLayout .postListLayout ul li a .postThumb::before {
              content: "";
              display: block;
              padding-top: 62.3%; }
            article#idx section.athkitBlogLayout .postListLayout ul li a .postThumb > img {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%; }
          article#idx section.athkitBlogLayout .postListLayout ul li a .txtBox {
            display: block; }
            article#idx section.athkitBlogLayout .postListLayout ul li a .txtBox time {
              font-family: "Times New Roman", sans-serif;
              font-size: 1.7rem;
              font-weight: bold;
              line-height: 1;
              letter-spacing: 1.7px;
              padding-bottom: 12px;
              display: block; }
              @media screen and (max-width: 768px) {
                article#idx section.athkitBlogLayout .postListLayout ul li a .txtBox time {
                  padding-bottom: 0.3em; } }
            article#idx section.athkitBlogLayout .postListLayout ul li a .txtBox h3 {
              font-size: 1.8rem;
              font-weight: bold;
              line-height: 1.56;
              letter-spacing: 0.9px;
              padding-bottom: 20px; }
              @media screen and (max-width: 768px) {
                article#idx section.athkitBlogLayout .postListLayout ul li a .txtBox h3 {
                  padding-bottom: 0; } }
            article#idx section.athkitBlogLayout .postListLayout ul li a .txtBox p {
              font-size: 1.4rem;
              line-height: 1.75;
              letter-spacing: 1.4px; }
              @media screen and (max-width: 768px) {
                article#idx section.athkitBlogLayout .postListLayout ul li a .txtBox p {
                  display: none; } }
    article#idx section.athkitBlogLayout .postListLayout .btnBoxLayout .colorBox {
      max-width: 487px;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        article#idx section.athkitBlogLayout .postListLayout .btnBoxLayout .colorBox {
          max-width: 704px;
          width: 99.435%; } }
      article#idx section.athkitBlogLayout .postListLayout .btnBoxLayout .colorBox a {
        padding: 26px 0; }
        article#idx section.athkitBlogLayout .postListLayout .btnBoxLayout .colorBox a img {
          max-width: 106px; }
          @media screen and (max-width: 768px) {
            article#idx section.athkitBlogLayout .postListLayout .btnBoxLayout .colorBox a img {
              max-width: none;
              width: 29.8022%; } }

article#idx section.contactLayout {
  background-color: #f2f0e8;
  padding-top: 60px;
  padding-bottom: 110px; }
  @media screen and (max-width: 768px) {
    article#idx section.contactLayout {
      padding-top: 7.8125%;
      padding-bottom: 14.3229%; } }
  article#idx section.contactLayout h2 {
    padding-bottom: 70px; }
    @media screen and (max-width: 768px) {
      article#idx section.contactLayout h2 {
        padding-bottom: 9.1145%; } }
    article#idx section.contactLayout h2 img {
      margin: 0 auto;
      max-width: 474px; }
      @media screen and (max-width: 768px) {
        article#idx section.contactLayout h2 img {
          max-width: none;
          width: 79.0364%; } }
  article#idx section.contactLayout p.txt {
    font-size: 2rem;
    line-height: 1.4;
    letter-spacing: 2px;
    text-align: center; }
    @media screen and (max-width: 768px) {
      article#idx section.contactLayout p.txt {
        padding: 0 15px;
        font-size: 1.8rem; } }
  article#idx section.contactLayout .telBox {
    display: flex;
    margin: 0 auto;
    max-width: 545px;
    width: 100%;
    align-items: center;
    padding-top: 45px; }
    @media screen and (max-width: 768px) {
      article#idx section.contactLayout .telBox {
        padding-top: 5.859375%;
        display: block;
        text-align: center; } }
    article#idx section.contactLayout .telBox a {
      display: block;
      padding-right: 15px; }
      @media screen and (max-width: 768px) {
        article#idx section.contactLayout .telBox a {
          padding-right: 0;
          padding-bottom: 5.859375%; }
          article#idx section.contactLayout .telBox a img {
            margin: 0 auto; } }
    article#idx section.contactLayout .telBox .timeBox {
      background-color: #000;
      color: #fff;
      font-size: 1.4rem;
      line-height: 1;
      letter-spacing: 1.4px;
      padding: 8px 15px; }
      @media screen and (max-width: 768px) {
        article#idx section.contactLayout .telBox .timeBox {
          display: inline-block;
          margin: 0 auto; } }
  article#idx section.contactLayout .contactFormColumn {
    display: block;
    width: 100%;
    max-width: 1020px;
    padding: 90px 20px 0;
    margin: 0 auto; }
    @media screen and (min-width: 640px) and (max-width: 768px) {
      article#idx section.contactLayout .contactFormColumn {
        padding: 11.71875% 30px 0; } }
    @media screen and (max-width: 768px) {
      article#idx section.contactLayout .contactFormColumn {
        padding: 11.71875% 15px 0; } }
    article#idx section.contactLayout .contactFormColumn h3 {
      background-image: url("/images/index/self_training_bg.png");
      height: 73px;
      font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 0.8px;
      text-align: center;
      color: #f9f9f9;
      padding-top: 0.5em;
      margin-bottom: 60px; }
      @media screen and (max-width: 768px) {
        article#idx section.contactLayout .contactFormColumn h3 {
          font-size: 2.2rem;
          height: auto;
          padding: 15px 8px;
          margin-bottom: 8.13%; } }
    article#idx section.contactLayout .contactFormColumn .formLayout {
      width: 100%;
      padding-top: 65px; }
      @media screen and (max-width: 768px) {
        article#idx section.contactLayout .contactFormColumn .formLayout {
          padding-top: 8.8075%; } }
      article#idx section.contactLayout .contactFormColumn .formLayout h4 {
        font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 2.6px;
        text-align: center;
        padding-bottom: 35px; }
      article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn {
        width: 100%;
        background-color: #fff; }
        article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox {
          width: 100%;
          height: 315px;
          margin: 0 auto;
          overflow: hidden;
          line-height: 1.75;
          letter-spacing: 1.6px;
          padding: 30px 0 35px 30px; }
          article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox h5 {
            padding-bottom: 40px; }
            @media screen and (max-width: 768px) {
              article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox h5 {
                padding-bottom: 20px; } }
          article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox p.topTxt {
            display: block;
            padding-bottom: 40px; }
          article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap {
            display: block;
            width: 100%; }
            article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li {
              padding-bottom: 40px; }
              article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li:last-child {
                padding-bottom: 0; }
              article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li h6 {
                padding-bottom: 20px; }
              article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li dl {
                display: block;
                width: 100%; }
                article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li dl dt {
                  padding-bottom: 0.5em; }
                  article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li dl dt:first-of-type {
                    padding-top: 1em; }
                article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li dl dd {
                  padding-bottom: 1em; }
                article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li dl:last-of-type dt {
                  padding-top: 0; }
                article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li dl:last-of-type dd {
                  padding-bottom: 0; }
              article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li ul {
                display: block;
                padding-left: 2em;
                padding-bottom: 1em; }
                @media screen and (max-width: 768px) {
                  article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li ul {
                    padding-left: 1em; } }
                article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li ul li {
                  position: relative;
                  padding-bottom: 0;
                  padding-left: 1.5em; }
                  article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li ul li::before {
                    content: "\025cb";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto; }
              article#idx section.contactLayout .contactFormColumn .formLayout .infoLayoutColumn .infoBox ul.columnWrap li .signature h6 {
                padding-bottom: 0;
                font-size: 1.8rem;
                font-weight: bold; }
      article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout {
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px; }
        article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check {
          display: inline-block;
          cursor: pointer; }
          @media screen and (max-width: 768px) {
            article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check {
              display: block;
              text-align: left; } }
          article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check .checkbox01-input {
            display: none; }
          article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check .checkbox01-parts {
            cursor: pointer;
            position: relative;
            padding-left: 3em;
            line-height: 1.75;
            letter-spacing: 1.6px; }
            @media screen and (max-width: 768px) {
              article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check .checkbox01-parts {
                padding-left: 0;
                text-align: left; } }
            article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check .checkbox01-parts::before {
              content: "";
              display: block;
              position: absolute;
              top: -5px;
              left: 0;
              width: 30px;
              height: 30px;
              border: 1px solid #bbbbbb;
              border-radius: 4px;
              background-color: #fff; }
              @media screen and (max-width: 768px) {
                article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check .checkbox01-parts::before {
                  top: 2px;
                  width: 15px;
                  height: 15px;
                  left: -28px; } }
          article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check .checkbox01-input:checked + .checkbox01-parts::after {
            content: "";
            display: block;
            position: absolute;
            top: -3px;
            left: 11px;
            width: 9px;
            height: 17px;
            transform: rotate(40deg);
            border-bottom: 3px solid #ad000b;
            border-right: 3px solid #ad000b; }
            @media screen and (max-width: 768px) {
              article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout #check .checkbox01-input:checked + .checkbox01-parts::after {
                top: 1px;
                left: -22px;
                width: 4px;
                height: 10px; } }
        article#idx section.contactLayout .contactFormColumn .formLayout .agreementLayout p {
          display: inline-block;
          padding-left: 1em; }
      article#idx section.contactLayout .contactFormColumn .formLayout .gRecaptchaBox {
        display: flex;
        width: 100%;
        padding-bottom: 30px;
        flex-wrap: wrap; }
        article#idx section.contactLayout .contactFormColumn .formLayout .gRecaptchaBox p {
          width: 100%;
          display: block;
          text-align: center;
          font-size: 1.4rem;
          padding-bottom: 8px; }
        article#idx section.contactLayout .contactFormColumn .formLayout .gRecaptchaBox .g-recaptcha {
          display: block;
          margin: 0 auto; }
        article#idx section.contactLayout .contactFormColumn .formLayout .gRecaptchaBox p.errorTxt {
          width: 100%;
          color: #ad000b;
          line-height: 1;
          padding-top: 0.8em;
          font-weight: bold;
          font-size: 1.6rem; }
    article#idx section.contactLayout .contactFormColumn .contactBtnLayout {
      display: block;
      width: 100%;
      position: relative; }
      article#idx section.contactLayout .contactFormColumn .contactBtnLayout.active {
        padding-bottom: 115px; }
        @media screen and (max-width: 768px) {
          article#idx section.contactLayout .contactFormColumn .contactBtnLayout.active {
            padding-bottom: 0; } }
      article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnA .colorBox {
        max-width: 487px;
        margin: 0 auto; }
        article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnA .colorBox a {
          padding: 26px 0; }
          article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnA .colorBox a img {
            max-width: 216px;
            margin: 0 auto; }
          @media screen and (max-width: 768px) {
            article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnA .colorBox a img {
              width: 80.2874%;
              margin: 0 auto; } }
      article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnA.active {
        animation-name: fadeOut;
        animation-duration: 0.8s;
        z-index: 0;
        animation-fill-mode: forwards;
        display: none; }
      article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnA.notActive {
        animation-name: fadeIn;
        animation-duration: 0.8s;
        z-index: 99;
        animation-fill-mode: forwards;
        display: block; }
      article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB {
        position: absolute;
        top: 0;
        display: none; }
        @media screen and (max-width: 768px) {
          article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB {
            position: relative;
            margin: 0 auto; } }
        article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.backBox {
          max-width: 487px;
          left: 0;
          width: 49%;
          border: 2px solid #f2f0e8;
          transition: 0.5s; }
          @media screen and (max-width: 768px) {
            article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.backBox {
              border: 1px solid #f21d1e;
              margin-bottom: 15px;
              width: 100%;
              padding: 20px 0; } }
          article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.backBox.uaHover:hover {
            border: 2px solid #f21d1e;
            transition: 0.5s; }
          article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.backBox a {
            display: flex;
            width: 100%;
            align-items: center; }
            @media screen and (max-width: 768px) {
              article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.backBox a {
                display: block; } }
            article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.backBox a img {
              margin: 0 auto;
              width: 65.1546%; }
        article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.colorBox {
          max-width: 487px;
          right: 0;
          width: 49%; }
          article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.colorBox a {
            padding: 26px 0; }
          @media screen and (max-width: 768px) {
            article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.colorBox {
              width: 100%; }
              article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.colorBox a {
                padding: 20px 0; }
                article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.colorBox a img {
                  width: 78.6447%;
                  margin: 0 auto; } }
        article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.active {
          animation-name: fadeIn;
          animation-duration: 0.8s;
          z-index: 0;
          animation-fill-mode: forwards;
          display: block; }
        article#idx section.contactLayout .contactFormColumn .contactBtnLayout .jsBtnB.notActive {
          animation-name: fadeOut;
          animation-duration: 0.8s;
          z-index: 99;
          animation-fill-mode: forwards;
          display: none; }
    article#idx section.contactLayout .contactFormColumn .testBtn {
      display: block;
      padding: 30px; }
      article#idx section.contactLayout .contactFormColumn .testBtn a {
        display: block;
        max-width: 300px;
        padding: 15px;
        margin: 0 auto;
        color: #fff;
        position: relative;
        z-index: 3;
        transition: 0.5s;
        transition: 0.5s;
        opacity: 1; }
        article#idx section.contactLayout .contactFormColumn .testBtn a p {
          position: relative;
          z-index: 99; }
        article#idx section.contactLayout .contactFormColumn .testBtn a::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-image: linear-gradient(to bottom, #f21d1e, #ca0010 51%, #ba000f 52%, #bb000f 70%, #a7000d 99%);
          z-index: 2;
          transition: 0.5s; }
        article#idx section.contactLayout .contactFormColumn .testBtn a::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-image: linear-gradient(to bottom, #f82a2b, #e00218 51%, #d30016 52%, #d40016 70%, #c50013 99%);
          transition: 0.5s;
          z-index: 1; }
        article#idx section.contactLayout .contactFormColumn .testBtn a.uaHover:hover {
          transition: 0.5s; }
          article#idx section.contactLayout .contactFormColumn .testBtn a.uaHover:hover::before {
            opacity: 0;
            transition: 0.5s; }
  article#idx section.contactLayout .thanksLayout {
    width: 100%;
    display: none;
    padding: 100px 20px 0;
    max-width: 1020px;
    margin: 0 auto;
    padding-top: 100px;
    text-align: center; }
    article#idx section.contactLayout .thanksLayout .contactBox {
      display: block;
      width: 100%;
      text-align: center; }
    article#idx section.contactLayout .thanksLayout h3 {
      text-align: center;
      font-size: 3rem; }
      article#idx section.contactLayout .thanksLayout h3 img {
        margin: 0 auto;
        width: 75%; }
        @media screen and (max-width: 768px) {
          article#idx section.contactLayout .thanksLayout h3 img {
            width: 100%; } }
    article#idx section.contactLayout .thanksLayout h4 {
      font-size: 1.8rem; }
    article#idx section.contactLayout .thanksLayout p {
      font-size: 1.8rem;
      line-height: 1.75; }

article#idx section.footerInfoLayout {
  display: block;
  background-image: url("/images/index/footer_info_bg.jpg");
  padding: 100px 0 80px 0; }
  @media screen and (max-width: 768px) {
    article#idx section.footerInfoLayout {
      padding: 32px 0 10.4166%; } }
  article#idx section.footerInfoLayout .repeatBannerTitle {
    display: none;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 2.2rem;
    margin-bottom: 1em; }
    @media screen and (max-width: 768px) {
      article#idx section.footerInfoLayout .repeatBannerTitle {
        display: block; } }
  article#idx section.footerInfoLayout ul.repeatBanner {
    display: flex;
    max-width: 1020px;
    padding: 0 20px;
    margin: 0 auto;
    flex-wrap: wrap; }
    @media screen and (min-width: 640px) and (max-width: 768px) {
      article#idx section.footerInfoLayout ul.repeatBanner {
        display: flex;
        padding: 0 30px; } }
    @media screen and (max-width: 768px) {
      article#idx section.footerInfoLayout ul.repeatBanner {
        display: block;
        padding: 0 15px; } }
    article#idx section.footerInfoLayout ul.repeatBanner li {
      width: 31.196%;
      margin-right: 2.8571%;
      padding-bottom: 45px; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#idx section.footerInfoLayout ul.repeatBanner li {
          width: 50%;
          padding-bottom: 45px;
          margin-right: 0; } }
      @media screen and (max-width: 768px) {
        article#idx section.footerInfoLayout ul.repeatBanner li {
          width: 80%;
          padding-bottom: 35px;
          margin: 0 auto; } }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#idx section.footerInfoLayout ul.repeatBanner li:nth-child(odd) {
          padding-right: 10px; } }
      @media screen and (max-width: 768px) {
        article#idx section.footerInfoLayout ul.repeatBanner li:nth-child(odd) {
          padding-right: 0; } }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#idx section.footerInfoLayout ul.repeatBanner li:nth-child(even) {
          padding-left: 10px; } }
      @media screen and (max-width: 768px) {
        article#idx section.footerInfoLayout ul.repeatBanner li:nth-child(even) {
          padding-left: 0; } }
      article#idx section.footerInfoLayout ul.repeatBanner li:nth-child(3n) {
        margin-right: 0; }
        @media screen and (max-width: 768px) {
          article#idx section.footerInfoLayout ul.repeatBanner li:nth-child(3n) {
            margin: 0 auto; } }
      article#idx section.footerInfoLayout ul.repeatBanner li:last-child {
        padding-bottom: 0;
        margin-right: 0; }
        @media screen and (max-width: 768px) {
          article#idx section.footerInfoLayout ul.repeatBanner li:last-child {
            margin: 0 auto; } }
      article#idx section.footerInfoLayout ul.repeatBanner li a {
        display: block;
        color: rgba(255, 255, 255, 0.7); }
        @media screen and (max-width: 768px) {
          article#idx section.footerInfoLayout ul.repeatBanner li a {
            width: 100%; } }
        article#idx section.footerInfoLayout ul.repeatBanner li a .thumb {
          width: 100%; }
          @media screen and (max-width: 768px) {
            article#idx section.footerInfoLayout ul.repeatBanner li a .thumb img {
              width: auto;
              margin: 0 auto; } }
        article#idx section.footerInfoLayout ul.repeatBanner li a .txt {
          padding-top: 20px;
          font-size: 1.2rem;
          line-height: 1.7;
          letter-spacing: 1.4px; }
          @media screen and (max-width: 768px) {
            article#idx section.footerInfoLayout ul.repeatBanner li a .txt {
              line-height: 1.4; } }
  article#idx section.footerInfoLayout .snsLayout {
    margin: 0 auto;
    padding-top: 30px; }
    @media screen and (max-width: 768px) {
      article#idx section.footerInfoLayout .snsLayout {
        padding-top: 10.4166%; } }
    article#idx section.footerInfoLayout .snsLayout ul {
      display: flex;
      max-width: 300px;
      margin: 0 auto;
      width: 100%;
      justify-content: center;
      letter-spacing: -0.4em;
      align-items: flex-start; }
      @media screen and (max-width: 768px) {
        article#idx section.footerInfoLayout .snsLayout ul {
          margin: 0 auto; } }
      article#idx section.footerInfoLayout .snsLayout ul li {
        letter-spacing: normal;
        margin-right: 5px;
        width: auto; }
        @media screen and (max-width: 768px) {
          article#idx section.footerInfoLayout .snsLayout ul li {
            margin-bottom: 10px;
            margin-right: 10px; } }
        article#idx section.footerInfoLayout .snsLayout ul li:last-of-type {
          margin-left: 5px;
          padding-top: 0.3em; }
          @media screen and (max-width: 768px) {
            article#idx section.footerInfoLayout .snsLayout ul li:last-of-type {
              padding-top: 0; } }
        article#idx section.footerInfoLayout .snsLayout ul li.social_tw {
          padding-top: 5px;
          margin-right: 0; }
          @media screen and (max-width: 768px) {
            article#idx section.footerInfoLayout .snsLayout ul li.social_tw {
              padding-left: 0;
              padding-top: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: block; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    display: none; } }

/*================================================================================
お問い合わせフォーム
================================================================================ */
@media screen and (max-width: 768px) {
  section.contactLayout .contactLayout table.tableColumn {
    display: block; } }

@media screen and (max-width: 768px) {
  section.contactLayout .contactLayout table.tableColumn tbody {
    display: block; } }

section.contactLayout .contactLayout table.tableColumn tbody tr {
  width: 100%;
  border-bottom: 1px solid #e5e1d1; }
  section.contactLayout .contactLayout table.tableColumn tbody tr:last-child {
    border-bottom: 0; }
  @media screen and (max-width: 768px) {
    section.contactLayout .contactLayout table.tableColumn tbody tr {
      display: block; } }
  section.contactLayout .contactLayout table.tableColumn tbody tr th {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1.8px;
    text-align: left;
    padding: 20px 0;
    padding-left: 4.0816%;
    width: 33.2653%; }
    @media screen and (max-width: 768px) {
      section.contactLayout .contactLayout table.tableColumn tbody tr th {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
        font-size: 1.6rem; } }
    section.contactLayout .contactLayout table.tableColumn tbody tr th span {
      letter-spacing: 1.8px;
      padding-top: 5px;
      text-align: left;
      font-weight: normal;
      font-size: 1.4rem;
      color: #999999;
      display: inline-block; }
    section.contactLayout .contactLayout table.tableColumn tbody tr th:last-of-type {
      vertical-align: text-top; }
  section.contactLayout .contactLayout table.tableColumn tbody tr td {
    width: 66.7347%;
    padding: 12px 0; }
    @media screen and (max-width: 768px) {
      section.contactLayout .contactLayout table.tableColumn tbody tr td {
        display: block;
        width: 100%; } }
    section.contactLayout .contactLayout table.tableColumn tbody tr td .txtcount {
      display: block;
      padding-top: 8px;
      text-align: right;
      font-size: 1.5rem;
      line-height: 1;
      letter-spacing: 1.5px;
      color: #333333;
      max-width: 630px; }
      @media screen and (max-width: 768px) {
        section.contactLayout .contactLayout table.tableColumn tbody tr td .txtcount {
          max-width: none; } }
    section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-input {
      display: none; }
    section.contactLayout .contactLayout table.tableColumn tbody tr td label {
      display: inline-block;
      margin-right: 6px;
      cursor: pointer; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        section.contactLayout .contactLayout table.tableColumn tbody tr td label {
          display: inline-block; } }
      @media screen and (max-width: 768px) {
        section.contactLayout .contactLayout table.tableColumn tbody tr td label {
          display: block; } }
    section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-parts {
      padding: 8px 15px 8px 45px;
      position: relative;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 1.6px;
      color: #ad000b;
      background-color: #fff;
      border: 1px solid #ad000b; }
      @media screen and (max-width: 768px) {
        section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-parts {
          display: block;
          margin-bottom: 5px;
          border-radius: 4px;
          padding: 16px 16px 16px 45px; } }
      section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-parts::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 15px;
        height: 15px;
        border-radius: 4px; }
      section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-parts::after {
        content: "";
        display: block;
        position: absolute;
        top: 8px;
        left: 18px;
        width: 7px;
        height: 14px;
        transform: rotate(40deg);
        border-bottom: 3px solid #bbb;
        border-right: 3px solid #bbb; }
        @media screen and (max-width: 768px) {
          section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-parts::after {
            top: calc(50% - 12px); } }
    section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-input:checked + .checkbox01-parts {
      color: #fff;
      background-color: #ad000b; }
      section.contactLayout .contactLayout table.tableColumn tbody tr td .checkbox01-input:checked + .checkbox01-parts::after {
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff; }
    section.contactLayout .contactLayout table.tableColumn tbody tr td input[type="text"],
    section.contactLayout .contactLayout table.tableColumn tbody tr td input[type="email"],
    section.contactLayout .contactLayout table.tableColumn tbody tr td input[type="tel"] {
      width: 400px;
      box-sizing: border-box;
      border: 1px solid #bbbbbb;
      height: 55px;
      padding: 3px 3px 3px 10px;
      border-radius: 4px;
      font-size: 1.6rem; }
      @media screen and (max-width: 768px) {
        section.contactLayout .contactLayout table.tableColumn tbody tr td input[type="text"],
        section.contactLayout .contactLayout table.tableColumn tbody tr td input[type="email"],
        section.contactLayout .contactLayout table.tableColumn tbody tr td input[type="tel"] {
          width: 100%; } }
    section.contactLayout .contactLayout table.tableColumn tbody tr td .selectBox {
      position: relative;
      display: inline-block;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        section.contactLayout .contactLayout table.tableColumn tbody tr td .selectBox {
          width: 100%;
          font-size: 1.6rem; } }
      section.contactLayout .contactLayout table.tableColumn tbody tr td .selectBox:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: 16px;
        top: -10px;
        bottom: 0;
        margin: auto;
        border-style: solid;
        border-width: 9px 8px 0 8px;
        border-color: #fff transparent transparent transparent;
        z-index: 2; }
      section.contactLayout .contactLayout table.tableColumn tbody tr td .selectBox::after {
        content: "";
        position: absolute;
        width: 50px;
        height: 55px;
        background: #999999;
        right: 0;
        top: 0;
        z-index: 1;
        border-radius: 0 4px 4px 0; }
      section.contactLayout .contactLayout table.tableColumn tbody tr td .selectBox select {
        cursor: pointer;
        width: 445px;
        height: 55px;
        padding: 3px 3px 3px 10px;
        border-radius: 4px;
        appearance: none;
        background-color: #fff; }
        @media screen and (max-width: 768px) {
          section.contactLayout .contactLayout table.tableColumn tbody tr td .selectBox select {
            width: 100%; } }
    section.contactLayout .contactLayout table.tableColumn tbody tr td textarea {
      display: block;
      width: 100%;
      max-width: 630px;
      box-sizing: border-box;
      border: 1px solid #bbbbbb;
      padding: 8px 3px 8px 10px;
      border-radius: 4px;
      font-size: 1.6rem; }
      @media screen and (max-width: 768px) {
        section.contactLayout .contactLayout table.tableColumn tbody tr td textarea {
          max-width: none; } }
    section.contactLayout .contactLayout table.tableColumn tbody tr td p.errorTxt {
      color: #ad000b;
      line-height: 1;
      padding-top: 0.8em;
      font-weight: bold;
      font-size: 1.6rem; }

/*================================================================================
about 一覧ページ
================================================================================ */
article#about section.aboutInfoLayout {
  display: block; }
  @media screen and (max-width: 768px) {
    article#about section.aboutInfoLayout .topKeyword h1 img {
      width: 45.5729%; } }
  article#about section.aboutInfoLayout .aboutInfoColumn {
    background-color: #fff;
    width: 100%; }
    article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo {
      display: block;
      width: 100%;
      max-width: 1020px;
      padding: 75px 20px 200px;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo {
          padding: 8.6% 15px 100px; } }
      @media screen and (max-width: 768px) {
        article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table {
          display: block; } }
      @media screen and (max-width: 768px) {
        article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody {
          display: block; } }
      article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr {
        width: 100%;
        border-bottom: 1px solid #e5e1d1; }
        article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr:last-child {
          border-bottom: 0; }
        @media screen and (max-width: 768px) {
          article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr {
            display: block; } }
        article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr th {
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1.8px;
          text-align: left;
          padding: 20px 0;
          padding-left: 4.0816%;
          width: 33.2653%; }
          @media screen and (max-width: 768px) {
            article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr th {
              display: block;
              width: 100%;
              padding-left: 0;
              padding-bottom: 0;
              font-size: 1.6rem; } }
          article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr th span {
            letter-spacing: 1.8px;
            padding-top: 5px;
            text-align: left;
            color: #999999;
            display: inline-block; }
          article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr th:last-of-type {
            vertical-align: text-top; }
        article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr td {
          width: 66.7347%;
          padding: 12px 0; }
          @media screen and (max-width: 768px) {
            article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr td {
              display: block;
              width: 100%; } }
          article#about section.aboutInfoLayout .aboutInfoColumn .aboutInfo table tbody tr td .txtcount {
            display: block;
            padding-top: 8px;
            text-align: right;
            font-size: 1.5rem;
            line-height: 1;
            letter-spacing: 1.5px;
            color: #333333; }

/*================================================================================
プライバシーポリシー
================================================================================ */
article#policy section.policyLayout {
  display: block; }
  article#policy section.policyLayout .topKeyword h1 {
    width: 100%; }
    @media screen and (max-width: 768px) {
      article#policy section.policyLayout .topKeyword h1 img {
        width: 45.5729%; } }
  article#policy section.policyLayout .policyColumn {
    background-color: #fff; }
    article#policy section.policyLayout .policyColumn .policyBlock {
      display: block;
      width: 100%;
      max-width: 1020px;
      padding: 75px 20px 200px;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        article#policy section.policyLayout .policyColumn .policyBlock {
          padding: 8.6% 15px 100px; } }
      article#policy section.policyLayout .policyColumn .policyBlock p.topTxt {
        padding-bottom: 40px; }
      article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap {
        display: block;
        width: 100%; }
        article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li {
          padding-bottom: 40px; }
          article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li:last-child {
            padding-bottom: 0; }
          article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li h6 {
            padding-bottom: 20px;
            font-weight: bold;
            font-size: 1.8rem; }
          article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li dl {
            display: block;
            width: 100%; }
            article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li dl dt {
              padding-bottom: .5em; }
              article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li dl dt:first-of-type {
                padding-top: 1em; }
            article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li dl dd {
              padding-bottom: 1em; }
            article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li dl:last-of-type dt {
              padding-top: 0; }
            article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li dl:last-of-type dd {
              padding-bottom: 0; }
          article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li ul {
            display: block;
            padding-left: 2em;
            padding-bottom: 1em; }
            article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li ul li {
              position: relative;
              padding-bottom: 0;
              padding-left: 1.5em; }
              article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li ul li::before {
                content: '\025cb';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto; }
          article#policy section.policyLayout .policyColumn .policyBlock ul.columnWrap li .signature h6 {
            padding-bottom: 0;
            font-size: 1.8rem;
            font-weight: bold; }

/*================================================================================
トレーナー
================================================================================ */
#trainer section.trainerInfoLayout .trainerInfoColumn {
  background-color: #fff;
  width: 100%; }
  #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav {
    padding: 32px 20px 40px;
    background: #f2f0e8; }
    @media screen and (max-width: 768px) {
      #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav {
        padding: 8.6% 15px; } }
    #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItems {
      display: flex;
      flex-wrap: wrap;
      max-width: 1020px;
      margin: 0 auto; }
      #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItems > li {
        flex: 0 1 25%;
        max-width: 25%;
        margin-bottom: 8px;
        padding-right: 8px; }
        @media screen and (max-width: 768px) {
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItems > li {
            flex: 0 1 50%;
            max-width: 50%; } }
        @media screen and (max-width: 320px) {
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItems > li {
            flex: 0 1 100%;
            max-width: 100%; } }
    #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavThumb {
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin-right: 16px; }
    #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItem {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      background: #ffffff;
      padding: 4px;
      border-radius: 3px;
      position: relative; }
      #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItem::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 8px 8px;
        border-color: transparent transparent #ffd9dc transparent;
        position: absolute;
        bottom: 4px;
        right: 4px; }
      #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItem:hover::before, #trainer section.trainerInfoLayout .trainerInfoColumn .trainerNav .trainerNavItem:focus::before {
        border-color: transparent transparent #d90011 transparent; }
  #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout {
    display: block;
    width: 100%;
    max-width: 1020px;
    padding: 75px 20px 200px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout {
        padding: 8.6% 15px 100px; } }
    #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout {
      display: block;
      width: 100%;
      padding-bottom: 120px; }
      @media screen and (max-width: 768px) {
        #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout {
          padding: 0 0 60px; } }
      #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn {
        display: block;
        width: 100%; }
        #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .thumbnail {
          background-color: #000;
          margin-bottom: 65px; }
          @media screen and (max-width: 768px) {
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .thumbnail {
              margin-bottom: 8.8075%; } }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .thumbnail img {
            width: 44.3877%;
            margin: 0 auto; }
            @media screen and (max-width: 768px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .thumbnail img {
                width: 60%; } }
        #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn {
          display: block;
          max-width: 900px;
          width: 100%;
          padding: 0 20px;
          margin: 0 auto; }
          @media screen and (max-width: 768px) {
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn {
              padding: 0 15px; } }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postName {
            display: block;
            padding-bottom: 30px;
            font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif; }
            @media screen and (max-width: 768px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postName {
                padding-bottom: 6.775%; } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postName h3 {
              font-size: 4.2rem;
              font-weight: bold;
              line-height: 1.62;
              letter-spacing: 1.1px;
              display: inline-block;
              padding-right: 20px; }
              @media screen and (max-width: 768px) {
                #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postName h3 {
                  font-size: 2.4rem;
                  padding-right: 12px; } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postName span {
              display: inline-block;
              font-size: 1.8rem;
              font-weight: bold;
              color: #d90011;
              letter-spacing: 0.5px;
              transform: rotate(-5deg);
              position: relative;
              top: -7px; }
              @media screen and (max-width: 768px) {
                #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postName span {
                  font-size: 1.5rem; } }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postEditor {
            font-size: 1.8rem;
            line-height: 1.5;
            padding-bottom: 40px; }
            @media screen and (max-width: 768px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postEditor {
                font-size: 1.5rem; } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postEditor strong {
              font-weight: bold; }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification {
            display: block;
            width: 100%;
            border: solid 1px #999999;
            padding-bottom: 20px; }
            @media screen and (max-width: 768px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification {
                max-width: 604px;
                margin: 0 auto;
                padding-bottom: 12px; } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification h4 {
              font-size: 1.8rem;
              font-weight: bold;
              line-height: 1;
              letter-spacing: 1.8px;
              display: inline-block;
              position: relative;
              padding: 0 1em;
              top: -0.8em;
              background-color: #fff;
              left: 1em; }
              @media screen and (max-width: 768px) {
                #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification h4 {
                  font-size: 1.6rem;
                  left: 50%;
                  transform: translateX(-50%); } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification p {
              padding-left: 2.2em;
              padding-right: 1em;
              font-size: 1.6rem;
              line-height: 1.75;
              letter-spacing: 1.6px; }
              @media screen and (max-width: 768px) {
                #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postFirstlayout .postTopColumn .postTxtColumn .postQualification p {
                  padding: 0 10px;
                  font-size: 1.3rem;
                  letter-spacing: 0; } }
    #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList {
      display: block;
      width: 100%; }
      #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul {
        display: flex;
        flex-wrap: wrap; }
        @media screen and (max-width: 768px) {
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul {
            padding-bottom: 6.3559%; } }
        #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li {
          width: 50%;
          margin-bottom: 100px; }
          @media screen and (max-width: 425px) {
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li {
              width: 100%;
              margin-bottom: 60px; } }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li:nth-child(odd) .postColumn {
            padding-right: 10.4166%; }
            @media screen and (max-width: 425px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li:nth-child(odd) .postColumn {
                padding: 0; } }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li:nth-child(even) .postColumn {
            padding-left: 10.4166%; }
            @media screen and (max-width: 425px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li:nth-child(even) .postColumn {
                padding: 0; } }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li:last-child {
            margin-bottom: 0; }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li:nth-last-child(2) {
            margin-bottom: 0; }
            @media screen and (max-width: 425px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li:nth-last-child(2) {
                margin-bottom: 11.0677%; } }
          #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn {
            max-width: 480px; }
            @media screen and (max-width: 768px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn {
                max-width: none;
                margin: 0 auto;
                padding: 0 15px; } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .thumbnail {
              max-width: 280px;
              margin: 0 auto;
              padding-bottom: 40px; }
              @media screen and (max-width: 768px) {
                #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .thumbnail {
                  max-width: 560px;
                  padding-bottom: 24px;
                  width: 60%; }
                  #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .thumbnail img {
                    width: 100%; } }
            @media screen and (max-width: 768px) {
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postTxtColumn {
                padding: 0 15px; } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postName {
              font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
              padding-bottom: 25px; }
              @media screen and (max-width: 768px) {
                #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postName {
                  padding-bottom: 8.4745%; } }
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postName h3 {
                font-size: 42px;
                font-weight: bold;
                line-height: 1.3;
                letter-spacing: 1.1px;
                display: inline-block;
                padding-right: 20px; }
                @media screen and (max-width: 768px) {
                  #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postName h3 {
                    font-size: 2.4rem;
                    padding-right: 12px; } }
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postName span {
                display: inline-block;
                font-size: 1.8rem;
                font-weight: bold;
                color: #d90011;
                letter-spacing: 0.5px;
                transform: rotate(-5deg);
                position: relative;
                top: -7px;
                padding-top: 5px; }
                @media screen and (max-width: 768px) {
                  #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postName span {
                    font-size: 1.5rem; } }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postEditor {
              font-size: 1.8rem;
              line-height: 1.5;
              padding-bottom: 40px; }
              @media screen and (max-width: 768px) {
                #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postEditor {
                  font-size: 1.5rem;
                  padding-bottom: 24px; } }
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postEditor strong {
                font-weight: bold; }
            #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postQualification {
              display: block;
              width: 100%;
              border: solid 1px #999999;
              padding-bottom: 20px; }
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postQualification h4 {
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1;
                letter-spacing: 1.8px;
                display: inline-block;
                position: relative;
                padding: 0 1em;
                top: -0.8em;
                background-color: #fff;
                left: 1em; }
                @media screen and (max-width: 768px) {
                  #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postQualification h4 {
                    font-size: 1.6rem;
                    left: 50%;
                    transform: translateX(-50%); } }
              #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postQualification p {
                padding-left: 2.2em;
                padding-right: 1em;
                font-size: 1.6rem;
                line-height: 1.75;
                letter-spacing: 1.6px; }
                @media screen and (max-width: 768px) {
                  #trainer section.trainerInfoLayout .trainerInfoColumn .trainerListLayout .postTrainerList ul li .postColumn .postQualification p {
                    padding: 0 10px;
                    font-size: 1.3rem;
                    letter-spacing: 0; } }

/*================================================================================
blog 一覧ページ
================================================================================ */
article {
  /*================================================================================
blog シングルページ
================================================================================ */ }
  article#blogArchive section.postListLayout .postListColumn {
    width: 100%;
    background-color: #fff;
    padding: 75px 0 200px; }
    @media screen and (max-width: 768px) {
      article#blogArchive section.postListLayout .postListColumn {
        padding: 8.6% 0 100px; } }
    article#blogArchive section.postListLayout .postListColumn .postBlogList {
      display: block;
      max-width: 1020px;
      padding: 0 20px;
      background-color: #fff;
      width: 100%;
      margin: 0 auto; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#blogArchive section.postListLayout .postListColumn .postBlogList {
          padding: 0 30px; } }
      @media screen and (max-width: 768px) {
        article#blogArchive section.postListLayout .postListColumn .postBlogList {
          padding: 0 15px; } }
      article#blogArchive section.postListLayout .postListColumn .postBlogList ul {
        display: flex;
        width: 100%;
        flex-wrap: wrap; }
        article#blogArchive section.postListLayout .postListColumn .postBlogList ul li {
          width: 29.5918%;
          padding-bottom: 60px;
          margin-right: 4.0816%; }
          @media screen and (min-width: 640px) and (max-width: 768px) {
            article#blogArchive section.postListLayout .postListColumn .postBlogList ul li {
              width: 29.5918%;
              margin-right: 4.0816%; } }
          @media screen and (max-width: 768px) {
            article#blogArchive section.postListLayout .postListColumn .postBlogList ul li {
              width: 100%;
              padding-bottom: 50px;
              margin-right: 0; } }
          @media screen and (max-width: 768px) {
            article#blogArchive section.postListLayout .postListColumn .postBlogList ul li:last-child {
              padding-bottom: 0; } }
          article#blogArchive section.postListLayout .postListColumn .postBlogList ul li:nth-child(3n) {
            margin-right: 0; }
          article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a {
            display: block;
            width: 100%; }
            article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postThumb {
              display: block;
              max-width: 295px;
              margin-bottom: 30px;
              overflow: hidden;
              position: relative; }
              @media screen and (max-width: 768px) {
                article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postThumb {
                  max-width: none;
                  margin-bottom: 4.065%; } }
              article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postThumb::before {
                content: "";
                display: block;
                padding-top: 62.3%; }
              article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postThumb > img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%; }
            article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postTxtColumn {
              display: block;
              width: 100%; }
              article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postTxtColumn time {
                font-family: "Times New Roman", serif;
                font-size: 1.5rem;
                font-weight: bold;
                line-height: 1;
                letter-spacing: 1.7px;
                display: block;
                padding-bottom: 8px; }
                @media screen and (max-width: 768px) {
                  article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postTxtColumn time {
                    padding-bottom: 15px; } }
              article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postTxtColumn h2 {
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.56;
                letter-spacing: 0.9px;
                padding-bottom: 20px; }
                @media screen and (max-width: 768px) {
                  article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postTxtColumn h2 {
                    padding-bottom: 10px; } }
              article#blogArchive section.postListLayout .postListColumn .postBlogList ul li a .postTxtColumn .txtBox {
                font-size: 1.4rem;
                line-height: 1.75;
                letter-spacing: 1.4px; }
  article#blogSingle section.postSingleLayout .postSingleColumn {
    width: 100%;
    background-color: #fff;
    padding: 75px 0 200px; }
    @media screen and (max-width: 768px) {
      article#blogSingle section.postSingleLayout .postSingleColumn {
        padding: 8.6% 0 100px; } }
    article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap {
      display: block;
      max-width: 1020px;
      padding: 0 20px;
      background-color: #fff;
      width: 100%;
      margin: 0 auto; }
      @media screen and (min-width: 640px) and (max-width: 768px) {
        article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap {
          padding: 0 30px; } }
      @media screen and (max-width: 768px) {
        article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap {
          padding: 0 15px; } }
      article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout {
        display: flex;
        justify-content: space-between; }
        @media screen and (max-width: 768px) {
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout {
            flex-wrap: wrap; } }
        article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn {
          width: 66.9387%; }
          @media screen and (max-width: 768px) {
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn {
              width: 100%; } }
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox {
            display: block;
            position: relative;
            padding: 10px 0 10px 25px;
            margin-bottom: 35px; }
            @media screen and (min-width: 640px) and (max-width: 768px) {
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox {
                padding: 10px 0 10px 25px;
                margin-bottom: 35px; } }
            @media screen and (max-width: 768px) {
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox {
                padding: 8px 0 8px 15px;
                margin-bottom: 20px; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox::before {
              content: "";
              position: absolute;
              width: 8px;
              height: 100%;
              background-image: linear-gradient(to bottom, #ba000f, #ed4300 80%);
              top: 0;
              left: 0; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox::before {
                  width: 8px; } }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox::before {
                  width: 4px; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox time {
              font-family: "Times New Roman", serif;
              font-size: 2.7rem;
              font-weight: bold;
              line-height: 1.04;
              display: block;
              padding-bottom: 20px; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox time {
                  font-size: 2.7rem;
                  padding-bottom: 20px; } }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox time {
                  font-size: 2rem;
                  padding-bottom: 10px; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox h1 {
              font-size: 3.6rem;
              font-weight: bold;
              line-height: 1.6; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox h1 {
                  font-size: 3.6rem; } }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .titBox h1 {
                  font-size: 2.2rem; } }
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postTxt {
            display: block;
            width: 100%;
            line-height: 1.75;
            letter-spacing: 1.6px; }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postTxt strong {
              font-weight: bold; }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postTxt img {
              width: auto;
              max-height: 600px; }
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout {
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 50px; }
            @media screen and (max-width: 768px) {
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout {
                padding-top: 10.4166%; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul {
              display: flex;
              margin: 0 auto;
              width: 100%;
              justify-content: center;
              letter-spacing: -0.4em;
              align-items: flex-start; }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul {
                  margin: 0 auto; } }
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul li {
                letter-spacing: normal;
                margin-right: 0; }
                @media screen and (max-width: 768px) {
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul li {
                    margin-bottom: 10px;
                    margin-right: 10px; } }
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul li:last-of-type {
                  margin-left: 1em;
                  padding-top: 0.3em; }
                  @media screen and (max-width: 768px) {
                    article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul li:last-of-type {
                      padding-top: 0; } }
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul li.social_tw {
                  padding-top: 5px;
                  margin-right: 0;
                  padding-left: 20px; }
                  @media screen and (max-width: 768px) {
                    article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .snsLayout ul li.social_tw {
                      padding-left: 0;
                      padding-top: 0; } }
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout {
            display: flex;
            width: 100%;
            background-color: #f5f5f5;
            align-items: center;
            padding: 15px 13px; }
            @media screen and (min-width: 640px) and (max-width: 768px) {
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout {
                padding: 15px 13px; } }
            @media screen and (max-width: 768px) {
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout {
                padding: 15px 8px; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox {
              width: 40%;
              text-align: left; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox {
                  width: 40%; } }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox {
                  width: 35%; } }
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox a {
                padding-left: 2.5em; }
                @media screen and (max-width: 768px) {
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox a {
                    padding-left: 2.5em; } }
                @media screen and (max-width: 768px) {
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox a {
                    padding-left: 2em; } }
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox a::before {
                  content: "";
                  position: absolute;
                  width: 33px;
                  height: 21px;
                  top: -3px;
                  left: 0;
                  bottom: 0;
                  margin: auto;
                  background-size: contain;
                  background-image: url("/images/blog/single_page_arrow.svg");
                  background-repeat: no-repeat; }
                  @media screen and (max-width: 768px) {
                    article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox a::before {
                      width: 33px;
                      height: 21px; } }
                  @media screen and (max-width: 768px) {
                    article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .prevBox a::before {
                      width: 20px;
                      height: 14px; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .backBlog {
              width: 20%;
              text-align: center; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .backBlog {
                  width: 20%; } }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .backBlog {
                  width: 30%; } }
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .backBlog a::before {
                content: "";
                position: absolute;
                width: 23px;
                height: 18px;
                background-size: contain;
                background-image: url("/images/blog/back_blog_icon.svg");
                left: 0;
                top: -1px;
                background-repeat: no-repeat; }
                @media screen and (max-width: 768px) {
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .backBlog a::before {
                    width: 23px;
                    height: 18px; } }
                @media screen and (max-width: 768px) {
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .backBlog a::before {
                    width: 14px;
                    right: 0;
                    bottom: 0;
                    top: 3px;
                    left: -4em;
                    margin: auto; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox {
              width: 40%;
              text-align: right; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox {
                  width: 40%; } }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox {
                  width: 35%; } }
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox a {
                padding-right: 2.5em; }
                @media screen and (max-width: 768px) {
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox a {
                    padding-right: 2.5em; } }
                @media screen and (max-width: 768px) {
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox a {
                    padding-right: 2em; } }
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox a::before {
                  content: "";
                  position: absolute;
                  width: 33px;
                  height: 21px;
                  top: -3px;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  background-size: contain;
                  background-image: url("/images/blog/single_page_arrow.svg");
                  background-repeat: no-repeat;
                  transform: rotate(-180deg); }
                  @media screen and (max-width: 768px) {
                    article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox a::before {
                      width: 33px;
                      height: 21px; } }
                  @media screen and (max-width: 768px) {
                    article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout .nextBox a::before {
                      width: 20px;
                      height: 14px; } }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout a {
              font-size: 1.8rem;
              display: block;
              font-weight: bold;
              line-height: 1;
              letter-spacing: 1.4px;
              position: relative; }
              @media screen and (min-width: 640px) and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout a {
                  font-size: 1.8rem; } }
              @media screen and (max-width: 768px) {
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout .postMainColumn .postAroundLayout a {
                  font-size: 1.4rem; } }
        article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside {
          width: 28.06122%; }
          @media screen and (max-width: 768px) {
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside {
              width: 100%;
              padding-top: 50px; } }
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside h5 {
            display: block;
            padding-bottom: 10px;
            border-bottom: 1px solid #bbbbbb;
            margin-bottom: 15px;
            font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: 0.8px;
            text-align: left;
            color: #000; }
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList {
            display: block;
            width: 100%; }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li {
              display: block;
              width: 100%;
              border-bottom: 1px solid #f2f0e8;
              margin-bottom: 15px; }
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li:first-child a {
                padding-bottom: 15px; }
              article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li a {
                display: flex;
                width: 100%; }
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li a .thumb {
                  max-width: 90px;
                  width: 100%; }
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li a .thumb .dav {
                    width: 100%; }
                article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li a .txtBox {
                  padding-left: 15px;
                  padding-bottom: 15px; }
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li a .txtBox time {
                    font-family: "Times New Roman";
                    font-size: 1.4rem;
                    line-height: 1;
                    letter-spacing: 1.1px;
                    text-align: left;
                    color: #d90011;
                    display: block;
                    padding-bottom: 10px; }
                  article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside ul.relationList li a .txtBox p {
                    font-size: 1.3rem;
                    line-height: 1.62;
                    letter-spacing: 0.7px;
                    color: #333333; }
          article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside p.backLink {
            display: block; }
            article#blogSingle section.postSingleLayout .postSingleColumn .postSingleWrap .twoColumnLayout aside p.backLink a {
              font-family: YuMincho, "HG明朝E", "游明朝", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", serif;
              font-weight: bold;
              line-height: 1.5;
              letter-spacing: 1.2px;
              text-align: right;
              color: #d90011;
              display: block; }

/*================================================================================
about 一覧ページ
================================================================================ */
article#error section.errorLayout {
  display: block; }
  @media screen and (max-width: 768px) {
    article#error section.errorLayout .topKeyword h1 img {
      width: 45.5729%; } }
  article#error section.errorLayout .errorColumn {
    background-color: #fff;
    width: 100%; }
    article#error section.errorLayout .errorColumn .errorBlock {
      display: block;
      width: 100%;
      max-width: 1020px;
      padding: 75px 20px 200px;
      margin: 0 auto;
      text-align: center; }
      @media screen and (max-width: 768px) {
        article#error section.errorLayout .errorColumn .errorBlock {
          padding: 8.6% 15px 100px; } }
      article#error section.errorLayout .errorColumn .errorBlock h2 {
        font-size: 3rem;
        font-weight: bold;
        padding-bottom: 40px; }
        @media screen and (max-width: 768px) {
          article#error section.errorLayout .errorColumn .errorBlock h2 {
            font-size: 2.4rem; } }
      article#error section.errorLayout .errorColumn .errorBlock p {
        font-size: 1.8rem;
        line-height: 1.7;
        padding-bottom: 40px; }
        @media screen and (max-width: 768px) {
          article#error section.errorLayout .errorColumn .errorBlock p {
            font-size: 1.6rem;
            text-align: left; } }
      article#error section.errorLayout .errorColumn .errorBlock .backBox {
        display: block;
        max-width: 480px;
        margin: 0 auto;
        border: 2px solid #fff;
        transition: .5s; }
        article#error section.errorLayout .errorColumn .errorBlock .backBox a {
          padding: 20px 0;
          display: block;
          position: relative; }
          article#error section.errorLayout .errorColumn .errorBlock .backBox a img {
            margin: 0 auto;
            max-width: 128px; }
            @media screen and (max-width: 768px) {
              article#error section.errorLayout .errorColumn .errorBlock .backBox a img {
                max-width: none;
                width: 47.0588%; } }
          article#error section.errorLayout .errorColumn .errorBlock .backBox a::before {
            content: '';
            position: absolute;
            left: -12em;
            right: 0;
            bottom: 0;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 11.5px 11px 11.5px 0;
            border-color: transparent #d90012 transparent transparent;
            margin: auto; }
            @media screen and (max-width: 768px) {
              article#error section.errorLayout .errorColumn .errorBlock .backBox a::before {
                left: -65%;
                border-width: 7.5px 7px 7.5px 0; } }
        article#error section.errorLayout .errorColumn .errorBlock .backBox.uaHover:hover {
          border: 2px solid #d90011;
          transition: .5s; }

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }
  .mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
    -ms-touch-action: auto;
    touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }
  .mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0;
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 9px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    /* the dragger element */
    position: relative;
    width: 9px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 4px;
    margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }
  .mCSB_container_wrapper > .mCSB_container {
    padding-right: 30px;
    padding-bottom: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
  left: 20px; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

/* RTL direction/left-side scrollbar */
/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible horizontal scrollbar */
/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight {
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
----------------------------------------
6.1 THEMES 
----------------------------------------
*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: #808080;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: #bbbbbb;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: #bbbbbb;
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: #bbbbbb;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
    sprites locations 
    light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
    dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
    */ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
    sprites locations
    light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
    dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
    */ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
    sprites locations 
    light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
    dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
    */ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
    sprites locations 
    light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
    dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
    */ }

.mCSB_scrollTools .mCSB_buttonUp:hover, .mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active, .mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* theme "dark-thin" */
/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools, .mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical, .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
