@charset "utf-8";

/*================================================================================
お問い合わせフォーム
================================================================================ */
section.contactLayout {
    .contactLayout {
        table.tableColumn {
            @include mq(md) {
                display: block;
            }
            tbody {
                @include mq(md) {
                    display: block;
                }
                tr {
                    width: 100%;
                    border-bottom: 1px solid #e5e1d1;
                    &:last-child {
                        border-bottom: 0;
                    }
                    @include mq(md) {
                        display: block;
                    }
                    th {
                        font-size: 1.8rem;
                        font-weight: bold;
                        line-height: 1;
                        letter-spacing: 1.8px;
                        text-align: left;
                        padding: 20px 0;
                        padding-left: 4.0816%;
                        width: 33.2653%;
                        @include mq(md) {
                            display: block;
                            width: 100%;
                            padding-left: 0;
                            padding-bottom: 0;
                            font-size: 1.6rem;
                        }
                        span {
                            letter-spacing: 1.8px;
                            padding-top: 5px;
                            text-align: left;
                            font-weight: normal;
                            font-size: 1.4rem;
                            color: #999999;
                            display: inline-block;
                        }
                        &:last-of-type {
                            vertical-align: text-top;
                        }
                    }
                    td {
                        width: 66.7347%;
                        padding: 12px 0;
                        @include mq(md) {
                            display: block;
                            width: 100%;
                        }
                        .txtcount {
                            display: block;
                            padding-top: 8px;
                            text-align: right;
                            font-size: 1.5rem;
                            line-height: 1;
                            letter-spacing: 1.5px;
                            color: #333333;
                            max-width: 630px;
                            @include mq(md) {
                                max-width: none;
                            }
                        }
                        .checkbox01-input {
                            display: none;
                        }
                        label {
                            display: inline-block;
                            margin-right: 6px;
                            cursor: pointer;
                            @include mq(tab) {
                                display: inline-block;
                            }
                            @include mq(md) {
                                display: block;
                            }
                        }
                        .checkbox01-parts {
                            padding: 8px 15px 8px 45px;
                            position: relative;
                            font-weight: bold;
                            line-height: 1;
                            letter-spacing: 1.6px;
                            color: #ad000b;
                            background-color: #fff;
                            border: 1px solid #ad000b;
                            @include mq(md) {
                                display: block;
                                margin-bottom: 5px;
                                border-radius: 4px;
                                padding: 16px 16px 16px 45px;
                            }
                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                margin: auto;
                                width: 15px;
                                height: 15px;
                                border-radius: 4px;
                            }
                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 8px;
                                left: 18px;
                                width: 7px;
                                height: 14px;
                                transform: rotate(40deg);
                                border-bottom: 3px solid #bbb;
                                border-right: 3px solid #bbb;
                                @include mq(md) {
                                    top: calc(50% - 12px);
                                }
                            }
                        }
                        .checkbox01-input:checked + .checkbox01-parts {
                            color: #fff;
                            background-color: #ad000b;
                            &::after {
                                border-bottom: 3px solid #fff;
                                border-right: 3px solid #fff;
                            }
                        }
                        input[type="text"],
                        input[type="email"],
                        input[type="tel"] {
                            width: 400px;
                            box-sizing: border-box;
                            border: 1px solid #bbbbbb;
                            height: 55px;
                            padding: 3px 3px 3px 10px;
                            border-radius: 4px;
                            font-size: 1.6rem;
                            @include mq(md) {
                                width: 100%;
                            }
                        }
                        .selectBox {
                            position: relative;
                            display: inline-block;
                            cursor: pointer;
                            @include mq(md) {
                                width: 100%;
                                font-size: 1.6rem;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 0;
                                right: 16px;
                                top: -10px;
                                bottom: 0;
                                margin: auto;
                                border-style: solid;
                                border-width: 9px 8px 0 8px;
                                border-color: #fff transparent transparent transparent;
                                z-index: 2;
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                width: 50px;
                                height: 55px;
                                background: #999999;
                                right: 0;
                                top: 0;
                                z-index: 1;
                                border-radius: 0 4px 4px 0;
                            }
                            select {
                                cursor: pointer;
                                width: 445px;
                                height: 55px;
                                padding: 3px 3px 3px 10px;
                                border-radius: 4px;
                                appearance: none;
                                background-color: #fff;
                                @include mq(md) {
                                    width: 100%;
                                }
                            }
                        }
                        textarea {
                            display: block;
                            width: 100%;
                            max-width: 630px;
                            box-sizing: border-box;
                            border: 1px solid #bbbbbb;
                            padding: 8px 3px 8px 10px;
                            border-radius: 4px;
                            font-size: 1.6rem;
                            @include mq(md) {
                                max-width: none;
                            }
                        }
                        p.errorTxt {
                            color: #ad000b;
                            line-height: 1;
                            padding-top: 0.8em;
                            font-weight: bold;
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}
