@charset "utf-8";

// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

// placeholderの色変更
// 使用例：@include placeholder-color(#999);
@mixin placeholder-color($color) {
    &:-moz-placeholder {
        color:$color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
}

// clearfix
// 使用例：@include clearfix;
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// タイポグラフィ
// 使用例：@include typography;
@mixin typography {
    line-height: 1.6;
    font-size: $font13px;
    letter-spacing: .05em;

    // 横幅768pxまで
    @include mq(md) {
        line-height: 1.4;
    }
}

// 基本幅
// 使用例：@include innerWidth;
@mixin innerWidth {
    @include clearfix;
    max-width: $basicWidth;
    margin: 0 auto;

    // 横幅768pxまで
    @include mq(md) {
        max-width: initial;
    }
}

// 上下左右真ん中
// 使用例：@include allCenter;
@mixin allCenter($width,$height) {
    content: '';
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: $width;
    height: $height;
    background-size: $width $height;
}

// 三点リーダー
// 使用例：@include line-clamp(3);
@mixin line-clamp($size) {
    display: box;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $size;
    -webkit-box-orient: vertical;
}

// 円形の対応
// 使用例：@include circle(50);
@mixin circle($size) {
    width: $size + px;
    text-align: center;
    @include middle-line($size);
    @include border-radius-100(100%);
}

// 左右が丸い長方形
// 使用例：@include rounded(50);
@mixin rounded($height, $display: inline-block) {
    $space: round($height / 2) + px;
    display: $display;
    padding: 0 $space;
    @include border-radius($height);
    @include middle-line($height);
}

// 天地の中央に配置
// 使用例：@include vertical-align;
@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// gradient（グラデーション）
// 使用例： div {@include gradient(#ff00ff, #ff00cc, vertical);}
@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == vertical {
        // vertical
        background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
    } @else if $orientation == horizontal {
        // horizontal
        background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
        background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
        background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
        background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    } @else {
        // radial
        background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
}

// ghost-button（ゴーストボタン）
// 使用例： div {@include ghost-button(12px, #ffffff, 5px, #34dec6, 4px, #000000);}
@mixin ghost-button($font-size, $font-color, $border-size, $border-color, $padding, $hover-color) {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: $font-size;
    color: $font-color;
    border: $border-size solid $border-color;
    padding: $padding;

    &:hover {
        background: $border-color;
        color: $hover-color;
    }
}

// position: absolute の絶対値を px → % へ変換して指定
// left値
@mixin posiLeftPercent($parentPxWidth, $pxLeft){
    left: floor((($pxLeft / $parentPxWidth) * 10000%)) / 100;
}
// top値
@mixin posiTopPercent($parentPxHeight, $pxTop){
    top: floor((($pxTop / $parentPxHeight) * 10000%)) / 100;
}
// right値
@mixin posiRightPercent($parentPxWidth, $pxRight){
    right: floor((($pxRight / $parentPxWidth) * 10000%)) / 100;
}
// bottom値
@mixin posiBtmPercent($parentPxHeight, $pxBottom){
    bottom: floor((($pxBottom / $parentPxHeight) * 10000%)) / 100;
}

// 横幅を px → % へ変換して指定
@mixin widthPercent( $parentPxWidth, $pxWidth ){
    width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

// 高さを px → % へ変換して指定
@mixin heightPercent($parentPxHeight, $pxHeight){
    height: floor((($pxHeight / $parentPxHeight) * 10000%)) / 100;
}

// margin を px → % へ変換して指定
@mixin marginPercent($parentPxWidth, $mTop, $mRight, $mBottom, $mLeft){
    margin : floor((($mTop / $parentPxWidth) * 10000%)) / 100 floor((($mRight / $parentPxWidth) * 10000%)) / 100 floor((($mBottom / $parentPxWidth) * 10000%)) / 100 floor((($mLeft / $parentPxWidth) * 10000%)) / 100;
}

// padding を px → % へ変換して指定
@mixin paddingPercent($parentPxWidth, $pTop, $pRight, $pBottom, $pLeft){
    padding : floor((($pTop / $parentPxWidth) * 10000%)) / 100 floor((($pRight / $parentPxWidth) * 10000%)) / 100 floor((($pBottom / $parentPxWidth) * 10000%)) / 100 floor((($pLeft / $parentPxWidth) * 10000%)) / 100;
}
