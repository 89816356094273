@charset "utf-8";

/*================================================================================
プライバシーポリシー
================================================================================ */

article {
    &#policy {
        section {
            &.policyLayout {
                display: block;
                .topKeyword {
                    h1 {
                        width: 100%;
                        img {
                            @include mq(md) {
                                width: 45.5729%;
                            }
                        }
                    }
                }
                .policyColumn {
                    background-color: #fff;
                    .policyBlock {
                        display: block;
                        width: 100%;
                        max-width: 1020px;
                        padding: 75px 20px 200px;
                        margin: 0 auto;
                        @include mq(md) {
                            padding: 8.6% 15px 100px;
                        }
                        p.topTxt {
                            padding-bottom: 40px;
                        }
                        ul.columnWrap {
                            display: block;
                            width: 100%;
                            li {
                                padding-bottom: 40px;
                                &:last-child {
                                    padding-bottom: 0;
                                }
                                h6 {
                                    padding-bottom: 20px;
                                    font-weight: bold;
                                    font-size: 1.8rem;
                                }
                                p.liTxt {}
                                dl {
                                    display: block;
                                    width: 100%;
                                    dt {
                                        padding-bottom: .5em;
                                        &:first-of-type {
                                            padding-top: 1em;
                                        }
                                    }
                                    dd {
                                        padding-bottom: 1em;
                                    }
                                    &:last-of-type {
                                        dt {
                                            padding-top: 0;
                                        }
                                        dd {
                                            padding-bottom: 0;
                                        }
                                    }
                                }
                                ul {
                                    display: block;
                                    padding-left: 2em;
                                    padding-bottom: 1em;
                                    li {
                                        position: relative;
                                        padding-bottom: 0;
                                        padding-left: 1.5em;
                                        &::before {
                                            content: '\025cb';
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                        }
                                    }
                                }
                                .signature {
                                    h6 {
                                        padding-bottom: 0;
                                        font-size: 1.8rem;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}